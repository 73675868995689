/*
  TITLE
*/

.products {
  padding: 1em;
}

@media (min-width: 62em) {
  .products {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.titleCon {
  display: flex;
  margin-top: 2.0em;
  margin-left: 2.1em;
  margin-bottom: 1.5em;
}

.titleText {

  font-size: 2.4rem !important;
  font-weight: 600 !important;
  color:#535353;
  font: Poppins;
}

@media (min-width: 62em) {
  .titleText {
    font-size: 2.4rem !important;
    font-weight: 600 !important;
    color:#535353;
    font: Poppins;
    margin-left: 1em;
  }
}

/*
  FILTERS
*/

.filtersCon {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 62em) {
  .filtersCon {
    justify-content: flex-end;
  }
}

.filtersBtn {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

@media (min-width: 62em) {
  .filtersBtn {
    display: none !important;
  }
}

.filtersOptCon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filterSearchIcon {
  padding: 0em 1em;
  font-size: 2.2rem;
  color: #656565
}

@media (min-width: 62em) {
  .filterSearchIcon {
    display: none !important;
  }
}

.filterSearchInputCon {
  display: none;
  width: 16em;
  margin-right: 1em;
  height: 100%;
}

@media (min-width: 62em) {
  .filterSearchInputCon {
    display: flex;
    align-items: flex-end;
  }
}

.filterSearchInput {
  height: 100% !important;
}

.filterSelect {
  max-width: 40vw !important
}

@media (min-width: 62em) {
  .filterSelect {
    width: 16em !important
  }
}


/*
  FILTER
*/

.filterMenuResultsCon {
  display: flex;
  margin-top: 2em !important;
  text-align: center;
}

/*
  FILTER MENU
*/

.filterMenuCon {
  display: none;
  width: 30%;
  text-align: left;
  padding-right: 2em;
  border-right: 1px solid #9A9A9A;
  margin-right: 4em;
}

@media (min-width: 62em) {
  .filterMenuCon {
    display: flex;
    flex-direction: column;
  }
}

.filterMenuHeader {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.filterMenuHeader.filterMenuHeaderFilterBy {
  margin-top: 1.5em;
  margin-bottom: 0em;
}

.filterMenuSubHeader,
.filterMenuFilterBySubHeader {
  font-size: 1.6rem !important;
  font-weight: 500;
  color: #656565 !important;
  margin-bottom: 0.7em;
}

.filterMenuFilterBySubHeader {
  margin-bottom: 0em;
  margin-top: 0.7em;
}

.filterMenuSubHeaderItems,
.filterMenuFilterByItems {
  margin-left: 1.5em;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem !important;
  cursor: pointer;
}

.filterMenuFilterByItems {
  color: #9A9A9A !important;
  margin-left: 0em !important;
  font-weight: 400 !important;
}


.filterMenuColorIcon {
  font-size: 1.6rem;
  margin-right: 0.5em;
  border: 1px solid #9A9A9A;
  border-radius: 50%;
  cursor: pointer;
}


/*
  RESULTS
*/

.filterResultsTextCon {
  flex: 1;
  margin-bottom: 1em !important;
  text-align: left;
}

.filterResultsText {
  font-size: 1.2rem !important;
  font: Poppins;

}

.filterResultCardCon {
  text-align: center;
}


.filterResultCard {
  display: inline-block;
  width: calc(90% - 0.5em) !important;
  margin-left: 1.5em;
  box-shadow: 0 4px 8px 0 #00000019;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .filterResultCard {
    width: calc(21% - 1em) !important;
    margin-left: 5em;
  }
}

.filterResultCard:nth-child(2n) {
  margin-left: 1.5em;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .filterResultCard:nth-child(2n) {
    margin-left: 3.5em;
    margin-bottom: 2em;
  }

  .filterResultCard {
    margin-left: 3.5em;
  }
}

/* .filterResultCard:nth-child(3n) {
  margin-left: 1em;
} */

.filterResultPName {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
}

@media (min-width: 43em) {
  .filterResultPName {
    font-size: 1.3rem !important;
    font:Poppins
  }
}

.filterResultImg {
  height: 25em !important;
}

@media (min-width: 62em) {
  .filterResultImg {
    height: 20em !important;
  }
}

/*
  MORE
*/

.moreTextCon {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

@media (min-width: 62em) {
  .moreTextCon {
    display: none;
  }
}

button.moreTextBtn {
  color: #656565;
  border: none !important;
  box-shadow: none;
}
