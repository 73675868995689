@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* 
  CART COUNT BTN
*/

.styles_cartCountCon__2lpyU {
  display: flex;
}

button.styles_cartCountBtn__3PkTu {
  border: 1px solid #343433;
}

.styles_cartCountSmall__31g6l .styles_cartCountIcon__qFFbU {
  font-size: 1.4rem !important;
}

.styles_cartCountSmall__31g6l button.styles_cartCountBtn__3PkTu {
  padding: 1em !important;
}

.styles_cartCountInput__yvt7Z{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-left: 0.2em !important;
  margin-right: 0.2em !important;
  width: 6.6em !important;
  min-height: 100% !important;
  border: 1px solid #343433 !important;
  height: 3em !important;
  
}

.styles_cartCountText___aOJc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem !important;
  font-weight: 600;
  border: 1px solid #343433;
  width: 6.6em;
  min-height: 100%;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.styles_cartCountTextHidden__367Vo {
  padding: 1em;
}

.styles_cartCountSmall__31g6l .styles_cartCountText___aOJc {
  width: 4.6em;
}

/* 
  LINK BTN
*/

.styles_linkBtn__XBGkU {
  display: inline-block;
}

.styles_linkBtn__XBGkU:hover, .styles_linkBtn__XBGkU:hover *  {
  /* color: var(--color-text-in-secondary) !important */
}

.styles_linkBtn__XBGkU button {
  width: 100%;
}

/* 
  PRIMARY
*/

button.styles_primaryBtn__3y8rj {
  height: 4em;
  padding-left: 3em;
  padding-right: 3em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0em;
}

button.styles_primaryBtnIconOnly__36Grp {
  height: auto;
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

@media (min-width: 62em) {
  button.styles_primaryBtn__3y8rj  {
    height: 3em;
  }
}

button.styles_primaryBtn__3y8rj * {
  font-weight: 400;
  font-size: 1.4rem
}

.styles_primaryBtnIconOnly__36Grp * {
  font-size: 2.2rem !important;
}

.styles_primaryBtnIconRight__3WImX {
  padding-left: 0.5em;
  display: flex !important;
  justify-content: center;
  align-items: center;
}


/*
  SECONDARY BTN
*/

button.styles_secondaryBtn__10UC6 {
  border-radius: 8px !important;
}

/* 
  SHOP MORE
*/

.styles_carouselOptionsContainer__3vToL {
  position: relative; 
  display: flex;
  padding-left: 1em;
}

@media (min-width: 43em) {
  .styles_carouselOptionsContainer__3vToL {
    position: absolute; 
    bottom: 0; 
    right: 0;
  }
}

.styles_carouselCategory__KQqSu {
  width: 8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 1em;
  padding-right: 1em;
  background-color: var(--color-background);
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  border: 1px solid var(--color-separator-light);
  cursor: pointer;
}

@media (min-width: 62em) {
  .styles_carouselCategory__KQqSu {
    font-size: 1.2rem;
    width: 10em;
  }
}

.styles_carouselCategoryIcon__6eJ0a {
  font-size: 3.0rem;
  margin-bottom: 0.1em !important;
}

.styles_carouselCtaBtnLink__1ttfj {
  flex: 1 1;
}

.styles_carouselCtaBtn__2YMWu {
  flex: 1 1;
  height: 5em !important;
  padding: 1em !important;
}

button.styles_carouselCtaBtn__2YMWu *  {
  font-size: 0.9rem !important;
}

@media (min-width: 62em) {
  .styles_carouselCtaBtn__2YMWu {
    width: 12em;
    height: 8em !important;
  }

  button.styles_carouselCtaBtn__2YMWu *  {
    font-size: 1.2rem !important;
  }
}

.styles_carouselCtaBtn__2YMWu * {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

.styles_carouselMoreIcon__3KgcU {
  font-size: 1.6rem !important;
}

@media (min-width: 62em) {
  .styles_carouselCategoryIcon__6eJ0a {
    font-size: 4.0rem;
  }

  .styles_carouselMoreIcon__3KgcU {
    font-size: 1.9rem !important;
  }
}

/* apple sign in button styling */
.styles_signinButton__191SF {
  width: 210px !important;
  height: 40px;
  background-color: black;
  border: 1px solid #ccc;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
}

.styles_inputWithError__3o5YJ {
  display: flex;
  flex-direction: column;
  height: 3em !important;
}

.styles_errorText__2DqxI{
  color:red;
  font-size: 1.2rem !important;
  display: inline-block;
  white-space: nowrap !important;
  margin-top: 2em;
  width: 6.6em;
  z-index: 1;
}


/* 
  LINK
*/

.styles_link__273Gn {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  color:  inherit
}

.styles_link__273Gn:hover, .styles_link__273Gn:hover *  {
  color: var(--color-primary) !important
}

/*
  PARAGRAPH
*/

.styles_paragraph__2fpD5 {
  font-size: 1.6rem;
}

/* 
  TEXT
*/

.styles_text__2AdlR {
  font-size: 1.6rem;
}

.styles_unverifiedCon__HPCkx {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

button.styles_unverifiedBtn__2ENvR {
  margin-top: 1em !important;
}


/*
  CAROUSEL
*/

.styles_carouselSubText__sUHAZ {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .styles_carouselSubText__sUHAZ {
    font-size: 1.6rem !important;
  }
}

.styles_playBtnCon__3TILf {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.styles_playBtnText__10Xdk {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.styles_rectangle__22vPS {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
    .styles_rectangle__22vPS {
        width: 24;
    }
}
.styles_iconContainer__5P8Uw {
    display: flex;
    padding-right: 10px !important;
}
@media (min-width:62em){
  .styles_iconContainer__5P8Uw{
    position: absolute;
    top: 3em;
  }
}
button.styles_playBtnText__10Xdk * {
    font-weight: 500 !important;
}

/*
  WELCOME
*/

.styles_welcomeContainer__lxu2Y {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center
}

@media (min-width: 82em) {
  .styles_welcomeContainer__lxu2Y {
    height: 40em;
    justify-content: flex-end;
  }
    /* background-size: 50%;
    background-repeat: no-repeat;
    background-position: 0% 0%;
  } */
}

.styles_welcomeContent__38J_9 {
  padding: 6em 2em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .styles_welcomeContent__38J_9 {
    /* padding: 6em 10em; */
    width: 60%
  }
}

@media (min-width: 82em) {
  .styles_welcomeContent__38J_9 {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 8em 8em;
  }
}

.styles_welcomeImg__2q8Wd {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .styles_welcomeImg__2q8Wd {
    display: inline-block;
  }
}

.styles_welcomeTitle__3pSRy {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
}

@media (min-width: 82em) {
  .styles_welcomeTitle__3pSRy {
    font-size: 4.6rem !important;
    text-align: left;
    margin-bottom: 1em !important;
  }
}

.styles_welcomeText__3kXNf {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .styles_welcomeText__3kXNf {
    font-size: 1.6rem !important;
    text-align: left;
  }
}

.styles_welcomeBtn__1Rklr {
  margin-top: 3.2em !important;
}

@media (min-width: 82em) {
  .styles_welcomeBtn__1Rklr {
    /* margin-top: 2em !important; */
    align-self: flex-end;
  }
}

/*
  SHOP HEADER
*/

.styles_shopHeaderContainer__2cML1 {
  margin-top: 3.8em;
  margin-bottom: 0em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 62em) {
  .styles_shopHeaderContainer__2cML1 {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_shopHeader__31YSR {
  font-size: 2.2rem !important;
  font-weight: 500 !important;
  margin: 0 !important;
}

@media (min-width: 43em) {
  .styles_shopHeader__31YSR {
    font-size: 2.6rem !important;
  }
}

.styles_shopHeaderBtn__2i91Q {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  border: 2px solid var(--color-secondary) !important;
}

.styles_shopHeaderBtn__2i91Q *  {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}


/*
  POPULAR PRODUCTS
*/

.styles_popularProdContainer__29seX {
  padding-left: 1em;
  padding-right: 1em;
}

@media (min-width: 43em) {
  .styles_popularProdContainer__29seX {
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdContainer__29seX {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_popularProdScroll__1W2gC {
  overflow-x: auto;
  margin-bottom: 1em;
  white-space: nowrap;
}

@media (min-width: 62em) {
  .styles_popularProdScroll__1W2gC {
    white-space: normal;
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdScroll__1W2gC {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_popularProdItem__2fpyp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 26.5em;
  margin-top: 1.5em;
  padding: 1em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 43em) {
  .styles_popularProdItem__2fpyp {
    width: calc(50% - 1em);
    display: flex;
    height: 30em;
    padding: 2em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdItem__2fpyp {
    height: 40em;
  }
}

.styles_popularProdScroll__1W2gC .styles_popularProdItem__2fpyp {
  display: inline-flex;
  width: 75vw !important;
  height: 75vw;
  margin-right: 1em;
}

.styles_popularProdScroll__1W2gC .styles_popularProdItem__2fpyp:last-child {
  margin-right: 0em;
}


@media (min-width: 43em) {
  .styles_popularProdScroll__1W2gC .styles_popularProdItem__2fpyp{
    width: calc(45% - 1em) !important;
    height: 26em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdScroll__1W2gC .styles_popularProdItem__2fpyp{
    width: calc(50.33% - 1em) !important;
    /* margin-left: 0em; */
  }
}

.styles_popularProdTitle__wcl1d {
  text-transform: uppercase;
  font-size: 4.2rem !important;
  font-weight: 800;
  text-align: left;
  line-height: 1em;
  color: var(--color-text-in-secondary) !important;
}

@media (min-width: 43em) {
  .styles_popularProdTitle__wcl1d {
    font-size: 5rem !important;
  }
}

.styles_popularProdScroll__1W2gC .styles_popularProdTitle__wcl1d  {
  font-size: 1.8rem !important;
}

.styles_popularProdCategoryContainer__32j0j {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
}

.styles_popularProdCategory__2-ybb {
  font-size: 1.2rem !important;
  text-align: left;
  color: var(--color-text-in-secondary) !important
}

@media (min-width: 43em) {
  .styles_popularProdCategory__2-ybb {
    font-size: 1.4rem !important;
  }
}

.styles_popularProdBtnContainer__3D4fq {
  float: right;
}

.styles_popularProdBtn__3Fj8o,
.styles_latestSpecialsBtn__sg0Ga,
.styles_otherOfferItemBtn__u2nJ1,
.styles_recentNewsBtn__3SbAb {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.styles_popularProdBtn__3Fj8o *,
.styles_latestSpecialsBtn__sg0Ga *,
.styles_otherOfferItemBtn__u2nJ1 * ,
.styles_recentNewsBtn__3SbAb *  {
  font-weight: 500 !important;
}


/*
  Latest Specials
*/

.styles_latestSpecialsCon__20a2J {
  position: relative;
  margin-top: 1em;
}

.styles_latestSpecialsImg__3MyN_ {
  width: 100%;
}

.styles_latestSpecialsBtnCon__2BQMc {
  position: absolute;
  bottom: 1.5em;
  right:  1.5em;
}

@media (min-width: 62em) {
  .styles_latestSpecialsBtnCon__2BQMc {
    bottom: 4em;
    left: 7em;
    right:  auto;
  }
}


/*
  OTHER OFFERS
*/

.styles_otherOfferItemCon__2nXuA {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

@media (min-width: 43em) {
  .styles_otherOfferItemCon__2nXuA {
    flex-direction: row-reverse;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .styles_otherOfferItemCon__2nXuA {
    height: 40em;
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_otherOfferItemBg__1jozb {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
}

@media (min-width: 43em) {
  .styles_otherOfferItemBg__1jozb {
    width: 30%;
    height: 100%;
  }
}

@media (min-width: 62em) {
  .styles_otherOfferItemBg__1jozb {
    width: 50%;
  }
}

.styles_otherOfferItemInfo__1N8eo {
  padding: 3em 2em;
  background-color: var(--color-background-secondary);
  text-align: left;
}

@media (min-width: 43em) {
  .styles_otherOfferItemInfo__1N8eo {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;


    /* padding: 15em 5em 8em 5em; */
  }
}

.styles_otherOfferItemHeader__2eRfo {
  font-size: 3.4rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  text-align: left;
}

@media (min-width: 62em) {
  .styles_otherOfferItemHeader__2eRfo {
    font-size: 5rem !important;
  }
}

.styles_otherOfferItemText__1DtCE {
  font-size: 1.4rem !important;
  text-align: left;
  margin-bottom: 2.5em !important;
}

@media (min-width: 43em) {
  .styles_otherOfferItemText__1DtCE {
    /* width: 0%; */
    font-size: 1.8rem !important;
    padding-right: 1em;
  }
}

/*
.otherOfferItemBtn {
}
*/

/*
  RECENT NEWS
*/

.styles_recentNewsCon__1ny9v {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  margin-top: 1em;
  margin-bottom: 4em;
}


@media (min-width: 43em) {
  .styles_recentNewsCon__1ny9v {
    padding-left: 1em;
    justify-content: space-between;
  }
}

@media (min-width: 62em) {
  .styles_recentNewsCon__1ny9v {
    padding-left: 7em;
  }
}

.styles_recentNewsItem__1Dvp8 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 1em;
  min-width: 75%;
  height: 24em;
  padding: 1em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 43em) {
  .styles_recentNewsItem__1Dvp8 {
    margin-left: 0em;
    min-width: auto;
    width: calc(65% - 1em); /* white space between*/
    height: 30em;
    padding: 2em;
  }

  .styles_recentNewsItem__1Dvp8:first-child {
    width: 35%;
  }
}

@media (min-width: 62em) {
  .styles_recentNewsItem__1Dvp8 {
    height: 40em;
  }
}

.styles_recentNewsDate__1C_iX {
  color: var(--color-text-in-secondary) !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  text-align: left !important;
}

@media (min-width: 62em) {
  .styles_recentNewsDate__1C_iX {
    font-size: 1.4rem !important;
  }
}

.styles_recentNewsTitle__3Ysi2 {
  color: var(--color-text-in-secondary) !important;
  font-size: 2.4rem !important;
  font-weight: 800 !important;
  text-align: left !important;
}

@media (min-width: 62em) {
  .styles_recentNewsTitle__3Ysi2 {
    font-size: 3.8rem !important;
    margin-bottom: 2em;
    max-width: 20em;
  }
}

.styles_recentNewsBtnCon__3c8Yo {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  width: 100%;
}

.styles_cursorPointer__2rtDo {
  cursor: pointer;
}
/* styles.module.css */

  .styles_arrowLeft__3ULpP {
    font-size: 30px;
    display: flex;
    font-weight: bold;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 40%;
    right: 3%;
    overflow: hidden;
    color: red;
  }
  .styles_arrowLeft__3ULpP:hover{
    color: whitesmoke;
  }
  .styles_arrowRight__7fje6 {
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 40%;
    left: 3%;
    overflow: hidden;
    color:red;
  }
  .styles_arrowRight__7fje6:hover{
    color: whitesmoke;
  }







/*
 * Background image
 */

.styles_carouselBackground__3yyD8 {
  width: 100%;
  height: 100%;
  position: absolute;
}

.styles_carouselBackground__3yyD8 img {
  width: 100%;
  height: 100%;
}

.styles_carouselItemContainer__1mliq {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width : 100%;
  height: 100vh;
}

.styles_carouselItemContainerLeft__9oCzf {
    background-position: 60% 0%;
}

@media (min-width: 62em) {
  .styles_carouselItemContainer__1mliq {
    justify-content: center;
    align-items: flex-end;
    background-position: 0% 0;
    height: 90vh;
  }

  .styles_carouselItemContainerLeft__9oCzf {
      align-items: flex-start;
      padding-left: 7em;
  }
}

.styles_carouselTextContainer__2H_iy {
  padding: 1em;
  margin-bottom: 5em;
}

@media (min-width: 62em) {
  .styles_carouselTextContainer__2H_iy {
    width: 55%;
    padding-right: 4em;
  }
}

.styles_carouselSubHeaderText__2KvgY {
  color: var(--color-text-in-secondary) !important;
  text-align: left;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .styles_carouselSubHeaderText__2KvgY {
    font-size: 2.5rem !important;
  }
}
.styles_carouselHeaderText__2D9ZO {
  color: var(--color-text-in-secondary) !important;
  text-align: left;
  font-size: 4.1rem !important;
  font-weight: 800 !important;
  margin-top: 0.2em !important;
  /* white-space: pre */
}

@media (min-width: 62em) {
  .styles_carouselHeaderText__2D9ZO {
    font-size: 7.0rem !important;
    margin-top: 0em !important;
    margin-bottom: 1rem !important;
    padding: 0em !important;
  }
}


/* 
  CTA
*/

* {
  text-align: left;
}

.styles_ctaSubText__126p8 {
  display: block;
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .styles_ctaSubText__126p8 {
      width: 70% !important;
  }
}

.styles_playBtnCon__23V3j {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.styles_playBtnText__1z0vc {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.styles_rectangle__24fak {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
    .styles_rectangle__24fak {
        width: 24;
    }
}
.styles_iconContainer__1gLd8 { 
    display: flex;
    padding-right: 10px !important; 
}
@media (min-width:62em){
  .styles_iconContainer__1gLd8{
    position: absolute;
    top: 3em;
  }
}
button.styles_playBtnText__1z0vc * {
    font-weight: 500 !important;
}

/* 
    ABOUT US
*/

.styles_aboutUsCon__7_PnR {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center
}

@media (min-width: 82em) {
  .styles_aboutUsCon__7_PnR {
    height: 50em;
    justify-content: flex-end;
  }
}

.styles_aboutUsContent__3QoFb {
  padding: 6em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .styles_aboutUsContent__3QoFb {
    width: 70%
  }
}

@media (min-width: 82em) {
  .styles_aboutUsContent__3QoFb {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 8em 8em;
  }
}

.styles_aboutUsImg__2OWmg {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .styles_aboutUsImg__2OWmg {
    display: inline-block;
  }
}

.styles_aboutUsHeader__34bb_,
.styles_productOfferHeader__sZHIM,
.styles_valuesHeader__2mlRY,
.styles_valuesMissionHeader__ogWOb,
.styles_clientsHeader__3nUwN {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: center;
}

@media (min-width: 82em) {
  .styles_aboutUsHeader__34bb_,
  .styles_productOfferHeader__sZHIM,
  .styles_valuesHeader__2mlRY,
  .styles_valuesMissionHeader__ogWOb {
    font-size: 4.6rem !important;
    text-align: left;
    margin-bottom: 1em !important;
  }
}

.styles_aboutUsText__1Rwkz {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .styles_aboutUsText__1Rwkz {
    font-size: 1.6rem !important;
    text-align: left;
  }
}


/* 
  PRODUCTS OFFER
*/

.styles_productOfferCon__WOYyK {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .styles_productOfferCon__WOYyK {
    padding: 6em 7em;
  }
}

.styles_productOfferText__OpU7E {
  font-size: 1.6rem !important;
  text-align: left;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_productOfferText__OpU7E {
    width: 50%;
  }
}

.styles_productOfferItemCon__N-whB {
  margin-top: 4em;
}

.styles_productOfferItem__34kpr {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000019;
  padding: 0em 1em;
  width: calc(50% - 0.5em);
  margin-bottom: 1em;
  min-height: 15em;
}

@media (min-width: 62em) {
  .styles_productOfferItem__34kpr {
    width: calc(20% - 2em);
    margin-right: 2.5em;
    margin-bottom: 2.5em;
    min-height: 20em;
  }

  .styles_productOfferItem__34kpr:nth-child(5n) {
    margin-right: 0em;
  }
}

.styles_productOfferItem__34kpr:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 62em) {
  .styles_productOfferItem__34kpr:nth-child(2n) {
    margin-left: 0em;
  }
}

.styles_productOfferItemIcon__2nIq4 {
  font-size: 5rem;
  padding: 1em 25% 0.5em 25%;
  border-bottom: 0.1em solid var(--color-primary);
}

@media (min-width: 62em) {
  .styles_productOfferItemIcon__2nIq4 {
    font-size: 7rem;
    border-bottom: 0.05em solid var(--color-primary);
  }
}

.styles_productOfferItemName__GE8s2 {
  font-size: 1.2rem !important;
  font-weight: 600;
  text-align: center !important;
  height: 4rem !important;
  padding: 1em 0em !important;
}

@media (min-width: 62em) {
  .styles_productOfferItemName__GE8s2 {
    padding: 2em 0em !important;
    font-size: 1.4rem !important;
  }
}



/* 
  DELIVERIES
*/

.styles_deliveriesCon__2EjhK {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center;
  flex-direction: column;
}

@media (min-width: 82em) {
  .styles_deliveriesCon__2EjhK {
    height: 45em;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.styles_deliveriesContent__1Gifw {
  padding: 6em 1em;
  background-color: #343433;
}

@media (min-width: 62em) {
  .styles_deliveriesContent__1Gifw {
    width: 70%
  }
}

@media (min-width: 82em) {
  .styles_deliveriesContent__1Gifw {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 43%;
    padding: 8em 8em;
  }
}

.styles_deliveriesImg__2MG6t {
  height: 28em;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (min-width: 82em) {
  .styles_deliveriesImg__2MG6t {
    display: inline-block;
    height: 100%;
    width: 57%;
    background-position: 0% 0%;
  }
}

.styles_deliveriesHeader__24DaB {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: left;
  color:  var(--color-text-in-secondary) !important;
}

@media (min-width: 82em) {
  .styles_deliveriesHeader__24DaB {
    font-size: 4.6rem !important;
    margin-bottom: 1em !important;
  }
}

.styles_deliveriesText__3TwFg {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: left;
  color:  var(--color-text-in-secondary) !important;
}

@media (min-width: 82em) {
  .styles_deliveriesText__3TwFg {
    font-size: 1.6rem !important;
  }
}

/* 
  VALUES SECTION
*/

.styles_valuesCon__2FlUZ {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .styles_valuesCon__2FlUZ {
    padding: 6em 7em;
  }
}

.styles_valuesHeader__2mlRY {
  text-align: left !important;
  margin-bottom: 1em !important;
}

.styles_valuesItemCon__1sz2E {
  display: block;
}

@media (min-width: 43em) {
  .styles_valuesItemCon__1sz2E {
    display: flex;
    justify-content: space-between;
  }
}

.styles_valuesItem__3zjHs {
  margin-top: 2.5em;
  margin-bottom: 2.5em;
  width: 100%;
}

@media (min-width: 43em) {
  .styles_valuesItem__3zjHs {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    width: calc(33.33% - 4em)
  }
}

.styles_valuesItemTitle__2nHvt {
  font-size: 2.6rem !important;
  font-weight: 500 !important;
}

.styles_valuesItemTitleLine__27mQL {
  background-color: var(--color-primary);
  height: 0.5em;
  width: 5em;
  margin-top: 0.6em;
  margin-bottom: 2.5em;
}

.styles_valuesItemText__4c7X1 {
  text-align: left;
  font-size: 1.4rem !important;
}

.styles_valuesMissionCon__1_q0e {
  display: block;
}

@media (min-width: 82em) {
  .styles_valuesMissionCon__1_q0e {
    display: flex;
  }
}

.styles_valuesMissionImg__1ShIc {
  height: 20em;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .styles_valuesMissionImg__1ShIc {
    display: inline-block;
    height: 50em;
    width: 43%;
  }
}

.styles_valuesMissionContent__ltNoR {
  padding: 4em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 82em) {
  .styles_valuesMissionContent__ltNoR {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 4em 4em;
    padding-right: 2em;
  }
}

.styles_valuesMissionHeader__ogWOb {
  text-align: left !important;
  margin-bottom: 1em !important
}

.styles_valuesMissionText__1LeRG {
  text-align: left;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .styles_valuesMissionText__1LeRG {
    margin-top: 1em;
    margin-bottom: 1em;
    width: 85%;
  }
}

.styles_valuesMissionThinkTextCon__2YTqw {
  display: flex;
  flex-direction: column;
}

@media (min-width: 62em) {
  .styles_valuesMissionThinkTextCon__2YTqw {
    flex-direction: row;
  }
}

.styles_valuesMissionThinkText__3d3AS {
  color: var(--color-primary) !important;
  text-align: left;
  font-size: 2.2rem !important;
  font-weight: 700 !important;
}

@media (min-width: 62em) {
  .styles_valuesMissionThinkText__3d3AS {
    margin-right: 1em;
    margin-top: 1em;
  }

  .styles_valuesMissionThinkText__3d3AS::before {
    content: '\2022';
    margin-right: 0.5em;
  }

  .styles_valuesMissionThinkText__3d3AS:first-child::before {
    display: none;
  }
}

/* 
  CLIENT LIST
*/

.styles_clientsCon__1s-fZ {
  padding: 2em 1em;
}

@media (min-width: 62em) {
  .styles_clientsCon__1s-fZ {
    padding: 2em 7em;
  }
}

.styles_clientsHeader__3nUwN {
  text-align: left !important;
  margin-bottom: 1em !important;
}

.styles_clientsText__2-_pV {
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .styles_clientsText__2-_pV {
    margin-bottom: 4em !important;
  }
}

.styles_clientsIconCon__1sIG0 {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 2em;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .styles_clientsIconCon__1sIG0 {
    display: block;
    overflow-x: visible;
    overflow-x: initial;
    padding: 0em 7em;
  }
}

.styles_clientsIconItemCon__2VwEr {
  display: inline-block;
  width: auto;
}

@media (min-width: 62em) {
  .styles_clientsIconItemCon__2VwEr {
    width: 25% !important;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 4em;
  }
}

img.styles_clientsIcon__G33qa {
  height: 4.7em;
  margin-left: 4em;
}

@media (min-width: 62em) {
  img.styles_clientsIcon__G33qa {
    margin-left: 0em;
  }
}

.styles_responsiveBackground__2KjyV {
  background-size: cover; /* Ensures the image covers the entire area */
  background-position: center; /* Centers the image */
}

/*
  INPUT
*/
.styles_input__2cRhM {
  background: none;
  border: 1px solid #9a9a9a;
  color: var(--color-text-in-primary) !important;
  border-radius: 0px !important;
  height: 4em;
  text-align: left !important;
  font-size: 1.2rem !important;
}

.styles_inputRounded__1hKsQ {
  border-radius: 8px !important;
}

.styles_input__2cRhM * {
  background: none !important;
}

.styles_input__2cRhM::-webkit-input-placeholder {
  color: #656565;
}

.styles_input__2cRhM:-ms-input-placeholder {
  color: #656565;
}

.styles_input__2cRhM::placeholder {
  color: #656565;
}

/*
  SEARCH
*/

.styles_searchInput__1jRa_ {
  border: none !important;
  border-bottom: 1px var(--color-separator-light) solid !important;
  padding-left: 0em !important;
  border-radius: 0em !important;
  cursor: input;
  background: none !important;
}

.styles_searchInput__1jRa_ input::-webkit-input-placeholder {
  font-size: 1.1rem;
}

.styles_searchInput__1jRa_ input:-ms-input-placeholder {
  font-size: 1.1rem;
}

.styles_searchInput__1jRa_ input::placeholder {
  font-size: 1.1rem;
}

.styles_searchInput__1jRa_ > * {
  background: none !important;
}


/*
  SELECT
*/

.styles_selectCon__2KBy6 {
  display: flex;
  width: 100%;
  height: 4em;
  position: relative;
}

@media (min-width: 62em) {
  .styles_selectCon__2KBy6 {
    height: 3em;
  }
}

.styles_select__1WYC7 {
  width: 100%;
  height: 100% !important;
  border: 1px solid #d9d9d9  !important;
  border-radius: 0em;
}

.styles_selectRounded__11dCx .styles_select__1WYC7 {
  border-right: none !important;
  border-radius: 8px;
}

.styles_select__1WYC7 * {
  text-align: left;
  height: 100% !important;
  border: none !important;
}

.styles_select__1WYC7 span {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 1.4rem !important;
  color: #9A9A9A !important;
}

@media (min-width: 62em) {
  .styles_select__1WYC7 span {
    font-size: 1.4rem;
  }
}

.styles_selectSuffixBtn__3vq77 {
  height: 100% !important;
  background-color: var(--color-primary);
  /* position: absolute !important; */
  height: 100%;
  /* right: 0; */
  border-radius: 0 !important;
}

.styles_selectRounded__11dCx button.styles_selectSuffixBtn__3vq77 {
  border-radius: 8px !important;
}

.styles_selectSuffixBtnIcon__1pdQL {
  font-size: 2.4rem !important;
}

@media (min-width: 62em) {
  .styles_selectCon__2KBy6 {
    height: 3.5em;
  }
}

/*
  NEWSLETTER
*/

.styles_subscribeNewsletterCon__2RUny {
  display: flex !important;
  height: 4em;
}

@media (min-width: 62em) {
  .styles_subscribeNewsletterFooterCon__k2Y-u {
    height: 3em;
  }
}


.styles_subscribeNewsletterInput__NmOAC {
  flex: 1 1;
  height: 100%;
  font-size: 1.4rem !important;
  background-color: #fff !important;
}

.styles_subscribeNewsletterInput__NmOAC::-webkit-input-placeholder {
  font-size: 1.4rem;
}

.styles_subscribeNewsletterInput__NmOAC:-ms-input-placeholder {
  font-size: 1.4rem;
}

.styles_subscribeNewsletterInput__NmOAC::placeholder {
  font-size: 1.4rem;
}

.styles_newsletterFooterInput__AzfSX {
  font-size: 1.1rem !important;
}

.styles_newsletterFooterInput__AzfSX::-webkit-input-placeholder {
  font-size: 1.1rem;
}

.styles_newsletterFooterInput__AzfSX:-ms-input-placeholder {
  font-size: 1.1rem;
}

.styles_newsletterFooterInput__AzfSX::placeholder {
  font-size: 1.1rem;
}

button.styles_subscribeNewsletterBtn__3d4go {
  height: 100% !important;
}

button.styles_subscribeNewsletterBtn__3d4go * {
  font-weight: 700 !important;
}
/*
.subscribeNewsletterBtnIcon {

} */

/*
  TEXT AREA
*/

.styles_inputTextArea__31WRX {
  height: 16em !important;
}

/*
  TITLE
*/

.styles_products__3hWdw {
  padding: 1em;
}

@media (min-width: 62em) {
  .styles_products__3hWdw {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_titleCon__2Bbt4 {
  display: flex;
  margin-top: 2.5em;
  margin-bottom: 1.5em;
  border-bottom: 1px solid #9A9A9A;
}

.styles_titleText__3Qhxd {
  text-align: left;
  font-size: 2.6rem !important;
  font-weight: 600 !important;
  border-bottom: 0.6rem solid var(--color-primary);
  margin: 0em !important
}

@media (min-width: 62em) {
  .styles_titleText__3Qhxd {
    font-size: 2.8rem !important;
    font-weight: 600 !important;
  }
}

/*
  FILTERS
*/

.styles_filtersCon__2lUMg {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_filtersCon__2lUMg {
    justify-content: flex-end;
  }
}

.styles_filtersBtn__1-rGz {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

@media (min-width: 62em) {
  .styles_filtersBtn__1-rGz {
    display: none !important;
  }
}

.styles_filtersOptCon__2fkSC {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.styles_filterSearchIcon__z5HYZ {
  padding: 0em 1em;
  font-size: 2.2rem;
  color: #656565
}

@media (min-width: 62em) {
  .styles_filterSearchIcon__z5HYZ {
    display: none !important;
  }
}

.styles_filterSearchInputCon__S0jxR {
  display: none;
  width: 16em;
  margin-right: 1em;
  height: 100%;
}

@media (min-width: 62em) {
  .styles_filterSearchInputCon__S0jxR {
    display: flex;
    align-items: flex-end;
  }
}

.styles_filterSearchInputConMobile__1HGrV {
  display: flex;
  width: 100%;
  margin-right: 0em;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .styles_filterSearchInputConMobile__1HGrV {
    display: block;
  }
}

.styles_filterSearchInput__FWlTS {
  height: 100% !important;
}

.styles_filterSelect__3ohq9 {
  max-width: 40vw !important
}

@media (min-width: 62em) {
  .styles_filterSelect__3ohq9 {
    width: 16em !important
  }
}


/*
  FILTER
*/

.styles_filterMenuResultsCon__18i3E {
  display: block;
  margin-top: 2em !important;
}

@media (min-width: 62em) {
  .styles_filterMenuResultsCon__18i3E {
    display: flex;
  }
}
/*
  FILTER MENU
*/

.styles_filterMenuCon__19hh9 {
  width: 100%;
  text-align: left;
  padding-right: 2em;
  border-right: 1px solid #9A9A9A;
  margin-right: 4em;
}

@media (min-width: 62em) {
  .styles_filterMenuCon__19hh9 {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
}

.styles_filterMenuHeader__EXIg8 {
  display: block !important;
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 0.7em;
  text-transform: uppercase;
}

.styles_filterMenuHeaderCategories__3cQj2,
.styles_filterMenuSubHeader__2b2cW,
.styles_filterMenuCategories__LcqNV {
  display: none !important;
  text-transform: uppercase;
}

@media (min-width: 62em) {
  .styles_filterMenuHeaderCategories__3cQj2,
  .styles_filterMenuSubHeader__2b2cW,
  .styles_filterMenuCategories__LcqNV {
    display: block !important;
  }
}

.styles_filterMenuHeader__EXIg8.styles_filterMenuHeaderFilterBy__kSbcl {
  margin-top: 1.5em;
  margin-bottom: 0em;
}

.styles_filterMenuSubHeader__2b2cW,
.styles_filterMenuFilterBySubHeader__3Zt4N {
  font-size: 1.4rem !important;
  font-weight: 500;
  color: #656565 !important;
  margin-bottom: 0.7em;
}

.styles_filterMenuFilterBySubHeader__3Zt4N {
  margin-bottom: 0em;
  margin-top: 0.7em;
}

.styles_filterMenuSubHeaderItemsActive__2Nh_y {
  font-weight: 600;
  text-transform: capitalize;
}

.styles_filterMenuSubHeaderItems__2q29i {
  margin-bottom: 0.1em;
}

.styles_filterMenuSubHeaderItems__2q29i:hover {
  color: var(--color-primary);
}

.styles_filterMenuByItemsCon__YJ6FV {
  display: flex;
  flex-wrap: wrap;
}

.styles_filterMenuSubHeaderItems__2q29i,
.styles_filterMenuFilterByItems__zIsda {
  margin-left: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem !important;
  cursor: pointer;
  text-transform: capitalize;
}

.styles_filterMenuFilterByItems__zIsda {
  color: #9A9A9A !important;
  margin-left: 0em !important;
  font-weight: 400 !important;
  display: inline-block;
  width: 33.33%;
  padding-right: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1em;
  font-size: 1.2rem !important;
}

.styles_filterMenuFilterByItemsSelected__2Hks- {
  font-weight: 500 !important;
  color: var(--color-text-in-primary) !important;
}


.styles_filterMenuSubHeaderCount__ekbbt {
  padding-left: 0.5em;
}

.styles_filterMenuColorCon__C4VVP {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
}

.styles_filterMenuColor__1UqHh {
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border:  none;
  margin-right: 0.5em;
  height: 1.5em;
  width: 1.5em;
}

.styles_filterMenuColorActive__3wS7k {
  border: 1px solid #9A9A9A;
}

.styles_filterMenuColorIcon__2Z2sS {
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 50%;
  stroke: #9A9A9A80;
  stroke-width: 1px;
}

.styles_filterMenuColorActive__3wS7k .styles_filterMenuColorIcon__2Z2sS{
  border: none;
}

/*
  RESULTS
*/

.styles_filterResultCon__13Pe5 {
  width: 100%;
}

.styles_filterBreadCrumb__2WvNO {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1em;
  width: 100%;
  flex-wrap: wrap;
}

.styles_filterBreadCrumbItem__3HWx6 {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.styles_filterBreadCrumbText__1Nu9L {
  font-size: 1.4rem !important;
  white-space: nowrap;
}

.styles_filterResultsTextCon__1JyBe {
  flex: 1 1;
  margin-bottom: 1em !important;
  text-align: left;
}

.styles_filterResultsText__1Ni-Z {
  font-size: 1.6rem !important;
}

.styles_filterResultCardCon__210xt {
  text-align: left;
}

.styles_filterResultCard__1jcu- {
  display: inline-block;
  width: calc(50% - 0.5em) !important;
  margin-bottom: 1em;
  position: relative;
}

@media (min-width: 82em) {
  .styles_filterResultCard__1jcu- {
    width: calc(33.33% - 1em) !important;
  }
}

.styles_filterResultCard__1jcu-:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 82em) {
  .styles_filterResultCard__1jcu-:nth-child(2n) {
    margin-left: 0em;
  }

  .styles_filterResultCard__1jcu- {
    margin-right: 1em;
  }
}

/* .filterResultCard:nth-child(3n) {
  margin-left: 1em;
} */

.styles_filterResultPName__ZrVmp {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
}

@media (min-width: 43em) {
  .styles_filterResultPName__ZrVmp {
    font-size: 1.6rem !important;
  }
}

.styles_filterResultImg__2ZYUD {
  height: 12em !important;
}

@media (min-width: 62em) {
  .styles_filterResultImg__2ZYUD {
    height: 19em !important;
  }
}

.styles_emptyList__Iz3OH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8em 0em;
}

/*
  MORE
*/

.styles_moreTextCon__3xyUy {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

@media (min-width: 62em) {
  .styles_moreTextCon__3xyUy {
    display: none;
  }
}

button.styles_moreTextBtn__2COzD {
  color: #656565;
  border: none !important;
  box-shadow: none;
}

.styles_loginCon__2Jub4 {
  height: auto;
  padding: 2em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .styles_loginCon__2Jub4 {
    padding: 0em 0em;
    display: flex;
    flex-direction: row-reverse;
    min-height: calc(100vh - 6em);
  }
}

.styles_loginFormCon__3J8JI {
  display: block;
  width: 100%;
  padding: 0em;
}

@media (min-width: 62em) {
  .styles_loginFormCon__3J8JI {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60% !important;
    padding: 4em 8em;
  }
}

.styles_loginFormTitle__3r8PD {
  font-size: 2.6rem !important;
  font-weight: 600 !important;
  margin-top: 1em !important;
  margin-bottom: 2em !important;
}

@media (min-width: 62em) {
  .styles_loginFormTitle__3r8PD {
    font-size: 5.4rem !important;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }
}

.styles_loginFormSubText__14ApV {
  padding-bottom: 2em;
}

.styles_loginFormOptsCon__u5xoc {
  margin-top: 2em;
}



@media (min-width: 62em) {
  .styles_loginFormOptsCon__u5xoc {
    margin-top: 1em;
  }
}

.styles_loginFormOptsCon__u5xoc button > * {
  text-wrap: wrap;
  text-align: center;
}

.styles_loginFormOptsMigrateText__1vWPp {
  font-size: 1.2rem !important;
  margin-top: 1em;
}

.styles_loginAltOpts__3U_-x {
  display: flex;
  flex-direction: column;
}

.styles_loginAltOpts__3U_-x button:first-child {
  margin-bottom: 1em!important;
  margin-right: 1em!important;
}

.styles_loginAltOpts__3U_-x button > * {
  text-wrap: wrap;
  text-align: center;
}

.styles_loginFormBanner__10WtI {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  margin-top: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 70%;
}

@media (min-width: 62em) {
  .styles_loginFormBanner__10WtI {
    flex: 1 1;
    margin-top: 0em;
    height: inherit;
  }
}

.styles_loginFormBanner__10WtI ul li:before {
  content: '✓ ';
  margin-right: 1em;
}

.styles_loginFormBanner__10WtI ul {
  list-style: none;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
}

@media (min-width: 62em) {
  .styles_loginFormBanner__10WtI ul {
    font-size: 2.0rem;
  }
}



.styles_bannerText___zUib {

  color: white !important;

}

.styles_bannerTextCon__zF43B{
  margin-bottom: 2em;
}



.styles_lostCon__1OHQX {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6em 0;
}

.styles_lostHeader__2EuFN {
    font-size: 4.8rem !important;
    font-weight: 800 !important;
}


.styles_lostCon__yT-ix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6em 0;
}

.styles_lostHeader__2GA5r {
    font-size: 4.8rem !important;
    font-weight: 800 !important;
}


.styles_container__3cVTl {
  width: 100%;
  background-color: var(--color-background-footer);
  padding: 2em 1.5em;
}

.styles_container__3cVTl, .styles_container__3cVTl *{
  color: #fff
}

@media (min-width: 62em) {
  .styles_container__3cVTl {
    padding: 2em 7em;
  }
}

/*
  Header
*/

.styles_mainHeader__1LeYN {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
}


/*
  Items options
*/

.styles_itemListContainer__btZdp {
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (min-width: 62em) {
  .styles_itemListContainer__btZdp {
    flex-direction: row;
  }
}

.styles_itemContainer__3r4Nk {
  border-bottom: 1.6px solid #80807F;
  padding: 2em 0em ;
}

@media (min-width: 62em) {
  .styles_itemContainer__3r4Nk {
    flex: 1 1;
    display: flex;
    padding-right: 2em;
    padding-top: 0em;
    padding-bottom: 0em;
    border-bottom: none;
  }

  .styles_itemContainer__3r4Nk:nth-last-child(2)   {
    flex: 2 1;
    justify-content: flex-end;
    padding-right: 0em;
  }

  .styles_itemContainer__3r4Nk:nth-last-child(2) .styles_itemContent__13Mzv {
    width: 50%;
    flex-direction: column;
  }

  .styles_itemContainer__3r4Nk:nth-last-child(2) .styles_facebookDesktopIcon__2zE8M {
    display: block !important;
  }
}

.styles_itemHeader__11416 {
  display: flex;
  justify-content: space-between;
  align-items: left;
  /* padding: 1.5em 0em ; */

}

.styles_itemHeaderText__2UTwx {
  color: var(--color-text-in-secondary) !important;
  font-weight: 700;
  font-size: 1.8rem;
  text-align: left;
  text-transform: uppercase;
}

.styles_expandIcon__B8lZe {
  display: block !important;
  font-size: 1.6rem;
  color: #80807F;
}

@media (min-width: 62em) {
  .styles_expandIcon__B8lZe {
    display: none !important;
  }
}

/*
  FOOTER ITEMS
*/

.styles_footerItemMobile__3XcV1 {
  display: block;
}

@media (min-width: 62em) {
  .styles_footerItemMobile__3XcV1 {
    display: none;
  }
}

.styles_footerItemLaptop__lkumD {
  display: none;
}

@media (min-width: 62em) {
  .styles_footerItemLaptop__lkumD {
    display: block;
  }
}

.styles_footerItemLink__39v5F {
  margin-top: 0.8em;
  font-size: 1.4rem !important;
  color: #656565 !important;
  text-align: left;
  text-transform: uppercase !important;
}

/*
  FACEBOOK ICON
*/

.styles_socialMediaCon__fg10U{
  display: flex;
}
.styles_facebookIcon__-Eiiw {
  font-size: 2em;
}

.styles_facebookIconMobile__YA0FU {
  display: block;
}

@media (min-width: 62em) {
  .styles_facebookIconMobile__YA0FU {
    display: none !important;
  }
}

.styles_facebookDesktopIcon__2zE8M {
  display: none !important;
  margin-top: 1em;
  padding-left: 0.5em;
}

/*
  SUBSCRIBE TO NEWSLETTER
*/

.styles_newsletterContainer__pcoZ8 {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
}

@media (min-width: 62em) {
  .styles_newsletterContainer__pcoZ8 {
    position: absolute;
    right: -3em;
    bottom: 0;
  }
}


@media (min-width: 62em) {
  .styles_newsletterContainer__pcoZ8 {
    position: absolute;
    right: -4em;
    bottom: 0;
  }
}

@media (min-width: 82em) {
  .styles_newsletterContainer__pcoZ8 {
    right: 0em;
    bottom: 0;
  }
}

.styles_newsletterText__DSkI8 {
  color: #656565 !important;
  font-size: 1.2rem !important;
  text-align: left;
  margin-top: 0.5em;
}

.styles_newsletterInput__EVPyK {
  margin-top: 1em;
}

/*
  COPY RIGHT INFO
*/

.styles_copyrightContainer__2P_AO {
  margin-top: 3em;
  padding-top: 2em;
  text-align: left;
  border-top: 1px solid  #656565;
}

.styles_copyrightText__3mC3e {
  font-size: 1.2rem !important;
  color: #656565 !important;
}

/*
  CAROUSEL
*/

.styles_carouselItem__1YPMY {
  height: 70vh;
}

.styles_carouselSubText__1kHQH {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .styles_carouselSubText__1kHQH {
    font-size: 1.6rem !important;
  }
}

.styles_playBtnCon__3njXr {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.styles_playBtnText__2cgBI {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.styles_rectangle__pLB49 {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
    .styles_rectangle__pLB49 {
        width: 24;
    }
}
.styles_iconContainer__3SAKQ {
    display: flex;
    padding-right: 10px !important;
}
@media (min-width:62em){
  .styles_iconContainer__3SAKQ{
    position: absolute;
    top: 3em;
  }
}
button.styles_playBtnText__2cgBI * {
    font-weight: 500 !important;
}

/*
  WELCOME
*/

.styles_welcomeContainer__2C7ly {
  display: flex;
  background-color: var(--color-background-secondary);
  justify-content: center
}

@media (min-width: 82em) {
  .styles_welcomeContainer__2C7ly {
    height: 40em;
    justify-content: flex-end;
  }
    /* background-size: 50%;
    background-repeat: no-repeat;
    background-position: 0% 0%;
  } */
}

.styles_welcomeContent__2nEPW {
  padding: 6em 2em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .styles_welcomeContent__2nEPW {
    /* padding: 6em 10em; */
    width: 60%
  }
}

@media (min-width: 82em) {
  .styles_welcomeContent__2nEPW {
    display: flex;
    flex-direction: column;
    justify-content:center;
    width: 57%;
    padding: 8em 8em;
  }
}

.styles_welcomeImg__1TWr- {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .styles_welcomeImg__1TWr- {
    display: inline-block;
  }
}

.styles_welcomeTitle__2hO1T {
  font-size: 3.2rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
}

@media (min-width: 82em) {
  .styles_welcomeTitle__2hO1T {
    font-size: 4.6rem !important;
    text-align: left;
    margin-bottom: 1em !important;
  }
}

.styles_welcomeText__1RCfo {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .styles_welcomeText__1RCfo {
    font-size: 1.6rem !important;
    text-align: left;
  }
}

.styles_welcomeBtn__11k1d {
  margin-top: 3.2em !important;
}

@media (min-width: 82em) {
  .styles_welcomeBtn__11k1d {
    /* margin-top: 2em !important; */
    align-self: flex-end;
  }
}

/*
  TABS
*/

.styles_tabCon__30sF2 {
  padding: 1em 2em;
}

.styles_tabConBranches__1cFZ4 {
  padding-bottom: 0em !important;
}

@media (min-width: 62em) {
  .styles_tabCon__30sF2 {
    padding: 1em 7em 4em 7em;
  }
}

.styles_detailsHeaderText__1G19i {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

/*
  CONTACT DETAILS
*/

.styles_contactDetailsCon__3DNDc {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
  margin-top: 1em;
}

@media (min-width: 43em) {
  .styles_contactDetailsCon__3DNDc {
    width: 40em;
    margin-top: 2em;
    margin-bottom: 1em;
  }
}

.styles_contactDetailsCon__3DNDc hr {
  width: 0.15em;
  height: 2em;
  background-color: var(--color-text-in-secondary);
  border: none;
}

button.styles_phoneBtn__Wlgre {
  flex: 1 1;
  flex-direction: row-reverse !important;
  width: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.styles_phoneBtnIcon__1tz5r {
  margin-right: 0.5em;
  font-size: 1.6rem !important;
}

/*
  GET IN TOUCH
*/

.styles_getInTouchCon__3yiJL {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .styles_getInTouchCon__3yiJL {
    width: 70%;
  }
}

.styles_getInTouchText__3bi_k {
  font-size: 1.6rem !important;
  font-weight: 600;
}

.styles_getInTouchRadioGrp__1f7mE {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}

.styles_getInTouchRadioGrp__1f7mE *  {
  font-size: 1.4rem !important;
  font-weight: 500;
}

@media (min-width: 62em) {
  .styles_getInTouchCon__3yiJL {
    margin-bottom: 0em;
  }
}

.styles_getInTouchFormCon__2UB83 {
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_getInTouchFormCon__2UB83 {
    margin-bottom: 0em;
    width: calc(50% - 0.5em);
    margin-right: 1em;
  }

  .styles_getInTouchFormConInput__1a2LB {
    display: inline-block;
  }

  .styles_getInTouchFormConInput__1a2LB:nth-child(2n) {
    margin-right: 0em;
  }

  .styles_getInTouchFormConTextArea__1EY9f {
    width: 100%;
  }
}

.styles_getInTouchLabel__2z4D6 {
  font-size: 1.6rem !important;
}

/* .getInTouchFormItem {
} */

.styles_getInTouchCommsCon__2TPiy {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.styles_getInTouchComms__fmoBv {
  font-size: 1.4rem !important;
  font-weight: 500;
}

/*
  BRANCH TAB
*/



.styles_branchTabCon__2DpC_ {
  display: flex;
  flex-direction: column;
  margin-bottom: 4em;
  width: 100vw;
  margin-left: -2em;
  position: relative;
}

@media (min-width: 62em) {
  .styles_branchTabCon__2DpC_ {
    margin-left: -7em;
    margin-bottom: 0em;
  }
}

.styles_branchMapCon__4hAs7 {
  width: 100%;
  height: 100vh;
  background-color: #EDF3F4;
  position: relative;
}




.styles_branchDetailsCon__3h4ty {
  height: 95vh;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 2em;
  box-shadow: 0px 6px 30px #00000019;
  background-color: var(--color-background);
  overflow-y: scroll;
  margin: auto;
}

@media (min-width: 62em) {
  .styles_branchDetailsCon__3h4ty {
    position: absolute;
    top: 0;
    width: 25%;
    height: 90%;
    left: 7em;
    margin-top: 2%;
  }
}

.styles_branchDetailsHeader__f1dWp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 0.5em);
  padding: 1em 1em 3em 0em;
}

.styles_branchDetailsHeaderText__3P0c_ {
  font-size: 2.0rem;
  font-weight: 600;
}

.styles_branchDetailsHeaderCount__36w9Z,
.styles_branchDetailsCount__3E3_v {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.0rem;
  font-weight: 600;
  background-color: var(--color-primary);
  color: var(--color-text-in-secondary) !important;
  width: 3em;
  height: 3em;
}


.styles_branchDetails__3jfvL {
  max-height: 100%;
  overflow-y: scroll;
}

.styles_branchDetailsItem__2rG3t {
  border-top: 1px solid #C7C7C7;
  padding: 2em 0em;
}


.styles_branchDetailsItemCon__sQHlK {
  display: flex;
}

.styles_branchDetailsCount__3E3_v {
  width: 2.25em;
  height: 2.25em;
  font-size: 1.6rem;
}

.styles_branchDetailsInfoCon___eAhM {
  padding-left: 1em;
  display: flex;
  flex-direction: column;

}

.styles_branchDetailsName__1zMdW {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  margin-bottom: 0.5em;
}

.styles_branchDetailsInfo__2oam9  {
  display: flex;
  padding-top: 0.5em;
}

.styles_branchDetailsIcon__3DRE8 {
  color: var(--color-primary);
  font-size: 1.5em !important;
}

.styles_branchDetailsText__1rvF_,
.styles_branchDetailsAddrText__2J65A {
  padding-left: 0.5em;
  display: block;
  font-size: 1.4rem !important;
}

.styles_branchDetailsAddrText__2J65A {
  font-size: 1.1rem !important;
}

.styles_locationButton__1clqf {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
}

.styles_locationButton__1clqf:focus {
  outline: none;
}


.styles_cookieContent__OBoXb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 62em) {
  .styles_cookieContent__OBoXb {
    flex-direction: column;
    align-items: flex-end;
  }
}
  
  .styles_cookieText__11p1t {
    margin-right: 20px;
    font-size: 1.5rem !important;
  }
  
  .styles_cookieBtn__2fWKN {
    color: 'red' !important;
    font-size: 1.7 !important;
  }

  .styles_cookieDiv__Gzm6G {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;
  }


.account_accountCon__K5z-m {
  width: 100%;
  padding: 1em 1em;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .account_accountCon__K5z-m {
    padding: 2em 7em;
    flex-direction: row;
  }
}
.account_accountMenuCon__18wmb {
  background-color: none;
  width: 100%;
  padding: 0.5em 0em;
  border-radius: 0.5em;
  max-height: 85vh;
}

@media (min-width: 62em) {
  .account_accountMenuCon__18wmb {
    background-color: var(--color-background);
    padding: 0.5em;
    width: 20%;
  }
}

.account_accountMenuHeader__hynFQ {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  border-bottom: none;
  padding: 0em 0em;
}

@media (min-width: 62em) {
  .account_accountMenuHeader__hynFQ {
    font-size: 1.8rem !important;
    border-bottom: 1px solid #C7C7C7;
    padding: 1em 1em;
  }
}

.account_accountMenu__3U2_Z {
  padding: 1em 0.5em !important;
  border-radius: 0.3em;
  background-color: var(--color-background);
  /* display: flex; */
  /* align-items: center; */
}

@media (min-width: 62em) {
  .account_accountMenu__3U2_Z {
    padding: 0em 0em !important;
  }
}

button.account_accountMenuMoreBtn__Wx59_ {
  padding: 0em;
}

.account_accountContentCon__1rmBW {
  width: 100%;
}

@media (min-width: 62em) {
  .account_accountContentCon__1rmBW {
    width: inherit;
    flex: 1 1;
    padding-left: 1em;
  }
}

/*
  MENU
*/

.styles_menuContainer___hrdH {
  flex: 1 1;
  height: 8rem;
}

.styles_menuContainer___hrdH {
  background: inherit;
}

.styles_extendMenuContainer__3ySRQ {
  height: calc(100vh - 5em);
  background-color: var(--color-background);
  overflow-y: scroll;
}

@media (min-width: 62em) {
  .styles_extendMenuContainerMobile__1CUUL {
    display: none;
  }
}

@media (min-width: 62em) {
  .styles_menuContainer___hrdH {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_menuVerticalSeparator__Qr8_8 {
  display: none;
  margin: auto 0.8em auto 0.8em;
  border-left: 1px solid var(--color-separator-light);
  height: 1.75em;
  width: 0.1rem;
}

@media (min-width: 62em) {
  .styles_menuVerticalSeparator__Qr8_8 {
    display: block;
  }
}

.styles_icon__1WjNi {
  font-size: 2.2rem;
  cursor: pointer;
}

icon:hover {
  color: var(--color-primary);
}

@media (min-width: 62em) {
  .styles_icon__1WjNi {
    font-size: 1.8rem;
  }
}

/*
  G. FOX LOGO
*/

.styles_logoContainer__2IbFS {
  flex: 1 1;
  display: flex;
  align-items: center;
  padding-left: 1.5em;
}

@media (min-width: 62em) {
  .styles_logoContainer__2IbFS {
    padding-left: 0em;
  }
}

.styles_gfoxLogo__3hKzQ {
  width: 6.5em;
}

@media (min-width: 62em) {
  .styles_gfoxLogo__3hKzQ {
    width: 9em;
  }
}
/*
  ACCOUNT INFO
*/

.styles_accountContainer__wBRMv {
  display: flex;
  align-items: center;
}

.styles_avatarIconContainer__3j98O {
  display: none;
  cursor: pointer;
}

@media (min-width: 62em) {
  .styles_avatarIconContainer__3j98O {
    display: block;
    margin-left: 0.8em;
  }
}

.styles_lockContainer__1NBH_,
.styles_cartContainer__3Vcrc {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

@media (min-width: 62em) {
  .styles_lockContainer__1NBH_,
  .styles_cartContainer__3Vcrc {
    margin-left: 0em;
    margin-right: 0em;
  }
}

.styles_cartBadge__WfoSm {
  color: inherit !important;
  margin-top: 0.3em !important;
}

.styles_lockLoginText__1j80d {
  display: none;
}

@media (min-width: 62em) {
  .styles_lockLoginText__1j80d {
    display: block;
    font-size: 1.3rem !important;
    font-weight: 400;
    width: -webkit-max-content;
    width: max-content;
    padding-left: 0.5em;
    padding-right: 0.8em;
    color: inherit !important;
  }
}

/*
  SEARCH
*/

.styles_searchContainer__y6LJx {
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

@media (min-width: 62em) {
  .styles_searchContainer__y6LJx {
    flex: 1 1;
    margin-left: 0em;
    margin-right: 0em;
  }
}

.styles_searchIcon__1OWKK {
  display: block;
}

.styles_searchInput__250CE {
  display: none !important;
  color: #9a9a9a !important;
}

.styles_searchInput__250CE > * {
  color: #9a9a9a !important;
}

@media (min-width: 62em) {
  .styles_searchIcon__1OWKK {
    display: none !important;
  }

  .styles_searchInput__250CE {
    display: inline-flex !important;
  }
}

/*
  MENU ITEMS
*/

.styles_menuItemLink__2nMwM {
  display: none !important;
  color: inherit !important;
}

@media (min-width: 62em) {
  .styles_menuItemLink__2nMwM {
    display: flex !important;
  }

  .styles_menuItemLinkHoverBg__1cgpe:hover {
    background: var(--color-background);
    color: var(--color-primary) !important;
  }
}



.styles_menuItemContainer__15UMv {
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 1.5em;
}

@media (min-width: 62em) {
  .styles_menuItemContainer__15UMv {
    display: none;
  }
}

.styles_menuIcon__1LPIz {
  display: block;
  font-size: 2.6rem;
}

@media (min-width: 62em) {
  .styles_menuIcon__1LPIz {
    display: none
  }
}

.styles_menuItemText__1s5Ch {
  margin-left: 1.25em;
  margin-right: 1.25em;
  font-size: 1.4rem;
  font-weight: 500;
  color: inherit !important;
  text-transform: uppercase;
}

.styles_menuItemActiveText__3wEgL {
  font-weight: 700;
  text-transform: uppercase;
}

.styles_menuItemProductMenu__HiGZj {
  display: none;
}

.styles_menuItemLink__2nMwM:hover .styles_menuItemProductMenu__HiGZj {
  color: var(--color-text-in-primary) !important;
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  z-index: -1;
}

/*
  PRODUCT LIST MENU
*/

.styles_productListContainer__227Vg {
  height: 100%;
}

.styles_productList__1OzKG {
  background-color: var(--color-background);
  height: 100%;
  padding: 4em 0em;
  display: flex;
  overflow-y: scroll;
}

.styles_productListCategories__2cuBG {
  display: flex;
  flex-direction: column;
  border-right: 0.5px solid #000;
  width: 25%;
  height: 100%;
}

.styles_favoriteProduct__1v4bh {
  display: flex ;
  align-items: center;
  text-align: left ;
  font-size: 1.4rem;
  font-weight: 600 ;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
   color: var(--color-text-in-primary);
  cursor: pointer;
}
.styles_favoriteProduct__1v4bh:hover {
  background: lightgray;
}


.styles_productListText__Hw6vP {
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
  color: var(--color-text-in-primary);
  cursor: pointer;
}

.styles_productListActive__1AgFE {
  background: var(--color-primary);
}

.styles_productListText__Hw6vP:hover {
  color: var(--color-primary);
}

.styles_productListActive__1AgFE.styles_productListText__Hw6vP,
.styles_productListActive__1AgFE.styles_productListText__Hw6vP:hover {
  color: var(--color-text-in-secondary);
}

.styles_productListSubCategoryCon__1bYA3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0em 2em;
  width: 75%;
}

.styles_productListSubCategoryItem__32x5t {
  text-align: left;
}

.styles_productListSubCategory__2vixU {
  text-align: left;
  width: 35%;
  display: inline-block;
}

.styles_productListSubCategoryText__3od0Q {
  font-size: 1.4rem !important;
  padding: 0.5em;
  text-transform: capitalize;
}

.styles_productListSubCategory__2vixU:nth-child(2n) {
  width: 65%;
}

img.styles_productListBanner__2yD2I {
  display: none;
  max-height: 10em;
  max-width: 100%;
  width: auto;
  height: auto;
}

@media (min-width: 62em) {
  img.styles_productListBanner__2yD2I {
    display: inherit;
  }
}


/*
  Wishlist
*/

.styles_icon__1WjNi{
  margin-left: 0em !important;
}

@media (min-width: 62em) {
  .styles_icon__1WjNi {
    margin-left: 0.5em !important ;
  }
}

.checkout-summary_checkoutSidePanelContent__3NZoD {
  background-color: var(--color-background);
  padding: 2em;
  /* height: 40em; */
  overflow-y: scroll;
}

.checkout-summary_sideTotalItems__lw97_ {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
}

.checkout-summary_sideCon__3wCIq {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-summary_backOrderTextCon__i64kH {
  display: block !important;
}

.checkout-summary_backOrderText__Mnc0C {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem !important;
  color: red !important;
  width: 100% !important;
}

.checkout-summary_checkoutSidePanelContentText__29BnY .checkout-summary_sideCon__3wCIq {
  flex-direction: row;
  padding: 1em 0em;
}

.checkout-summary_sideConIndex__n8wnG {
  display: none !important;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  padding-right: 1em;
}

.checkout-summary_checkoutSidePanelContentText__29BnY .checkout-summary_sideConIndex__n8wnG {
  display: block !important;
}

.checkout-summary_sideConText__2Pvxg {
  width: calc(60% - 1em)
}

.checkout-summary_checkoutSidePanelContentText__29BnY .checkout-summary_sideConText__2Pvxg {
  flex: 1 1;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkout-summary_sideConTextName__-fr18 {
  font-size: 1.6rem !important;
  display: block !important;
  margin-bottom: 1em;
  padding-right: 1em;
}

.checkout-summary_checkoutSidePanelContentText__29BnY .checkout-summary_sideConTextName__-fr18 {
  margin-bottom: 0em;
  flex: 1 1;
}

.checkout-summary_sideConTextPriceCon__18LiZ {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.checkout-summary_sideConTextPrice__1EFtH {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.checkout-summary_sideConTextPriceOut__18Iur {
  font-size: 1.2rem !important;
  text-decoration: line-through;
}

.checkout-summary_sideConTextQty__3zo_l {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  margin-left: 1em;
}

.checkout-summary_cartItemImgCon__3RzII {
  height: 10em;
  width: 40%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.checkout-summary_checkoutSidePanelContentText__29BnY .checkout-summary_cartItemImgCon__3RzII {
  display: none !important;
}

.checkout-summary_cartItemImg__15unk {
  width: auto !important;
  height: auto !important;
  max-width: 60%;
  max-height: 60%;
}


/*
  DISCOUNT CLAIM
*/

.checkout-summary_claimDiscountHeader__1NTkG {
  padding: 1em 0em 1em 0em;
  cursor: pointer;
}


/*
  DELIVERY PRICING
*/

.checkout-summary_deliveryHeader__1b-YQ {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em 1em 0em;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}

.checkout-summary_deliveryOptsCon__3Yd2- {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5em;
  cursor: pointer;
}

.checkout-summary_deliveryOptsCon__3Yd2- * {
  font-size: 1.5rem !important;
}

.checkout-summary_deliveryOptsConActive___6rVF * {
  font-weight: 500 !important;
}

.checkout-summary_deliveryOpts__2Xzlk {
  flex: 1 1 !important;
  display: flex;
  align-items: center;
}

.checkout-summary_deliveryOptsIcon__sSKpa {
  font-size: 1.1rem;
  margin: auto 0.5em;
  color: var(--color-background)
}

.checkout-summary_deliveryOptsConActive___6rVF .checkout-summary_deliveryOptsIcon__sSKpa  {
  color: inherit;
}


/*
 * VAT PRICING
 */
.checkout-summary_vatPriceCon__1R_vF {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em 0em 0em ;
}

/*
  TOTAL PRICING
*/

.checkout-summary_totalPriceCon__20IpD {
  display: flex;
  justify-content: space-between;
  padding: 2em 0em 1em 0em ;
  border-top: 1px var(--color-title-in-primary) solid;
}

.checkout-summary_totalPriceHeader__YFUXb {
  display: block;
  font-size: 1.8rem !important;
  font-weight: 600 !important;
}

.checkout-summary_totalPriceSubHeader__1i84G {
  display: block;
  font-size: 1.4rem !important;
  color: #9A9A9A !important;
}

.checkout-summary_totalPrice__3avM_ {
  font-size: 2.6rem !important;
  font-weight: 700 !important;
  padding-left: 1em;
}

/*
  EMPTY LIST
 */

.checkout-summary_emptyListCon__2sqsx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25em;
}


.last-order_lastOrderCon__3KNAU {
  background-color: var(--color-background);
  padding: 1em 1em;
  width: 100%;
  overflow-x: auto; 
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 1em;
  border-radius: 8px;
}

.last-order_lastOrderInfo__1_aIt {
  margin-bottom: 1em;
}

.last-order_lastOrderInfoHeader__5JfG2 {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .last-order_lastOrderInfoHeader__5JfG2 {
    font-size: 1.5rem !important;
  }
}

.last-order_lastOrderInfoOrderId__1jGy4 {
  font-size: 1.1rem !important;
  margin-left: 0.5em !important;
}

@media (min-width: 62em) {
  .last-order_lastOrderInfoOrderId__1jGy4 {
    font-size: 1.5rem !important;
  }
}

.last-order_lastOrderCurrent__7uExj {
  margin-top: 1em;
}

.last-order_lastOrderInfoStatus__20pW6 {
  font-size: 1.1rem !important;
  color: #40EAA2 !important;
  font-weight: 500;
}

@media (min-width: 62em) {
  .last-order_lastOrderInfoStatus__20pW6 {
    font-size: 1.5rem !important;
  }
}

.last-order_checkoutSteps___I8V4 {
  padding: 0em;
}

@media (min-width: 62em) {
  .last-order_checkoutSteps___I8V4 {
    padding: 1em !important;
  }
}

.last-order_lastOrderCurrentHeader__iD-py {
  display: block;
  font-size: 1.4rem !important;
  font-weight: 500;
}

.last-order_lastOrderCurrentDate__iAH6Z {
  display: block;
  font-weight: 500 !important;
  font-size: 1.1rem !important;
  color: #9A9A9A !important; 
}

.last-order_checkoutStepsTitle__2B_3m,
.last-order_checkoutStepsSubTitle__2hGDa {
  display: none !important;
}

@media (min-width: 62em) {
  .last-order_checkoutStepsTitle__2B_3m {
    text-align: center !important;
    font-size: 1.3rem !important;
    font-weight: 500 !important;
    display: block !important;
    line-height: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .last-order_checkoutStepsSubTitle__2hGDa {
    font-size: 1.1rem !important;
    text-align: center !important;
    display: block !important;
  }
}

.last-order_lastOrderCurrent__7uExj {
  display: block;
}

@media (min-width: 62em) {
  .last-order_lastOrderCurrent__7uExj {
    display: none;
  }
}


.latest-news_con__2KrDP {
  padding: 1em 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}

/* .conFlexType {
} */

.latest-news_headerText__KxgMz {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  display: block;
}


.latest-news_conFlexType__332U1 .latest-news_headerCon__24vFN {
  display: flex;
  align-items: flex-start;
}

.latest-news_headlineImage__O-9JB {
  border-radius: 8px;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  height: 8em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.latest-news_conFlexType__332U1 .latest-news_headlineImage__O-9JB  {
  width: 50%;
  min-height: 100%;
  display: inline-block;
}

.latest-news_metaData__3Ge4R  {
  display: flex;
  flex-direction: column;
}

.latest-news_conFlexType__332U1 .latest-news_metaData__3Ge4R {
  flex-direction: column-reverse;
  display: inline-flex;
  width: 50%;
  padding-left: 1em;
}

.latest-news_headlineDate__3glvM,
.latest-news_writtenBy__3042t {
  font-size: 0.8rem !important;
  color: #9A9A9A !important;
}

.latest-news_writtenBy__3042t {
  padding-left: 1em;
}

.latest-news_headerTitle__37zYA {
  font-size: 1.6rem !important;
  font-weight: 700 !important;
  display: block;
  margin-bottom: 0.5em;
  line-height: 1.5em;
}

.latest-news_headlineContent__1Wml2 {
  font-size: 1.4rem !important;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 4;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  overflow: hidden;
  text-overflow: ellipsis;
}

.latest-news_conFlexType__332U1 .latest-news_headlineContent__1Wml2 {
  -webkit-line-clamp: 2;
}

.latest-news_viewMoreBtnCon__1pqbi {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5em;
}

button.latest-news_viewMoreBtn__1Njkt {
  padding: 0.5em;
  height: auto;
}

button.latest-news_viewMoreBtn__1Njkt * {
  font-weight: 500 !important;
}

.order-summary_orderCon__2eYUe {
  background-color: var(--color-background);
  width: 100%;
  padding: 1em 1em;
}


@media (min-width: 62em) {
  .order-summary_orderCon__2eYUe {
    display: flex;
    align-items: flex-start;
  }
}

.order-summary_orderInfoCon__381bg {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #707070;
}

@media (min-width: 62em) {
  .order-summary_orderInfoCon__381bg {
    border-right: 1px solid #707070;
    border-bottom: none;
    width: 50%;
    display: inline-block;
    padding-left: 2em;
    padding-right: 2em;
  }
}

.order-summary_orderInfoCon__381bg:last-child {
  border-bottom: none;
}

@media (min-width: 62em) {
  .order-summary_orderInfoCon__381bg:last-child {
    border-right: none;
  }
}

/* 
  ADDRESS INFO
*/

.order-summary_addrHeader__29ld0 {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  display: block;
  margin-bottom: 1em;
}

.order-summary_addrValueCon__3BxJe {
  display: flex;
  margin-bottom: 0.5em;
}

.order-summary_addrTitle__2ldrF {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  display: block;
}

.order-summary_addrTitle__2ldrF {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  width: 50%;
  /* padding-left: 1em; */
  display: inline-block
}

.order-summary_addrValue__3Ipdl {
  font-weight: 400 !important;
  font-size: 1.4rem !important;
  width: 50%;
  display: inline-block
}

/* 
  PAYMENT
*/

.order-summary_paymentValueCon__3ZXeC {
  margin-bottom: 0.5em;
}

.order-summary_paymentHeader__HJMfv {
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  display: block;
  /* margin-top: 1em; */
  margin-bottom: 1em;
}

.order-summary_paymentCardIcon__1i-BQ {
  width: 2.2em;
  margin-right: 1em !important;
}

.order-summary_paymentCardIconCon__3IlPD { 
  display: flex;
}

.order-summary_paymentProvider__39Njv {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.order-summary_paymentTitle__eu0fk {
  font-size: 1.2rem !important;
  display: block;
}

.order-summary_paymentValue__XscBh {
  font-size: 1.6rem !important;
  display: block;
}

.order-summary_cardIcon__2scKm {
  font-size: 2.8rem;
}

.previous-order_con__2esWQ {
  background-color: var(--color-background);
  padding: 1em 1em;
  border-radius: 8px;
  position: relative;
}

.previous-order_headerCon__3AycJ {
  display: flex;
  justify-content: space-between;
}

.previous-order_headerText__1L77n {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .previous-order_headerText__1L77n {
    font-size: 1.5rem !important;
  }
}

button.previous-order_historyBtn__2blga,
button.previous-order_reorderBtn__2h10N {
  height: 2em;
  width: 6em;
}

button.previous-order_historyBtn__2blga * ,
button.previous-order_reorderBtn__2h10N * {
  font-size: 1.1rem !important;
}

button.previous-order_reorderBtn__2h10N {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.previous-order_statusCon__2j2ky {
  display: flex;
}

.previous-order_statusConMiddle__33bwv {
  height: 8em;
}

.previous-order_statusIconCon__2tidP {
  width: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.previous-order_statusIcon__2K3Go {
  font-size: 2.8rem
}

.previous-order_statusInfoCon__31gPh {
  padding-left: 1em;
  flex: 1 1;
}

.previous-order_statusConMiddle__33bwv .previous-order_statusInfoCon__31gPh {
  padding: 0.5em 1em;
  overflow-y: scroll
}

.previous-order_statusInfoHeader__17MyS {
  font-size: 1.3rem !important;
  display: block;
}

.previous-order_statusInfoOrderDate__2lZbD {
  font-size: 1.1rem !important;
  font-weight: 300 !important;
  display: block;
}

.previous-order_statusInfoProduct__kWSkN {
  display: block;
  font-size: 1.1rem !important;
}


.previous-order_statusLine__3h3ye {
  border-left: 3px dotted #9A9A9A;
  height: 100%
}

.previous-order_statusPriceCon__3K_Fj {
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.5em;
  height: 100%;
}

.previous-order_statusPrice__11N3i {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}


.product-card_container__2Yx_5 {
  width: 100%;
  background: #F7F7F7 0% 0% no-repeat padding-box;
}

.product-card_imgContainerNoBG__2Vssu {
  background: none !important;
}

.product-card_imgContainer__1ayQG {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20em !important;
  background: transparent radial-gradient(closest-side at 50% 50%, #FAFAFA 0%, #D8D8D8 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 43em) {
  .product-card_imgContainer__1ayQG {
    height: 15em !important;
  }
}

.product-card_imgContainer__1ayQG img {
  max-width: 85%;
  max-height: 85%
}

.product-card_separatorCon__1XUDP {
  border-top: 1px #343433 !important;
  width: calc(100% - 3em);
  height: 1px;
  border: 1px solid #343433;
  margin: auto;
  text-align: center;
}

.product-card_infoContainer__1p4gY {
  padding: 1.5em;
  text-align: left;
}

.product-card_infoProdNameCon__2LVA_ {
  height: calc(1.8rem * 2) !important;
}

.product-card_infoProdName__1sC5E {
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: 500;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 43em) {
  .product-card_infoProdName__1sC5E {
    font-size: 1.6rem;
  }
}


@media (min-width: 43em) {
  .product-card_infoPositionName__2KrrR {
    font-size: 1.6rem;
    font: Poppins
  }
}

.product-card_infoOptionsCon__9j3G6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-card_infoOptionsConViewBtn__2T07X {
  margin-top: 0.5em;
}

@media (min-width: 43em) {
  .product-card_infoOptionsConViewBtn__2T07X {
    justify-content: flex-end;
  }
}

.product-card_infoOptionsColorCon__2DgU_ {
  display: flex;
  align-items: center;
}

.product-card_infoOptionsColorName__1OLyN {
  text-transform: capitalize;
  margin-left: 0.5em;
  font-weight: 1.6rem;
  font-weight: 300 !important;
  font-size: 1.5rem;
}

@media (min-width: 43em) {
  .product-card_infoOptionsColorName__1OLyN {
    font-size: 1.4rem;
  }
}

.product-card_infoOptionShopBtnIcon__u1wSn {
  color: var(--color-text-in-secondary) !important;
}

button.product-card_infoOptionShopBtn__CdRTx * {
  font-size: 2.2rem !important;
}

button.product-card_infoOptionViewBtn__2OeW4 {
  padding-left: 1em;
  padding-right: 1em;
  background: none !important;
}

@media (min-width: 43em) {
  .product-card_infoOptionViewBtnCon__3x8Ly {
    margin-right: 1em !important;
  }

  button.product-card_infoOptionViewBtn__2OeW4 {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/*
  COLOR INDICATOR
*/

.product-card_colorIndicatorCon__1Xx4w {
  border: 1px solid #656565;
  border-radius: 50%;
  padding: 2px;
  width: 2em;
  height: 2em;
}

.product-card_colorIndicatorColor__x3-kV {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


/*
 * PRICING
 */
.product-card_pricingCon__3Ci29 {
  margin: 2em 0em;
}

.product-card_pricingText__2tIql {
  font-weight: 700;
}

/* LABEL */


.product-card_onHoldLabel__3-QBU {
  position: relative;
  background-color: rgba(143, 137, 137, 0.7); /* semi-transparent black */
  color: white;
  padding: 5px 10px;
  z-index: 1;
  margin-bottom: 0.1em;
}

.product-card_labelHold__32wss{
  position: absolute;
}
.product-card_imageNavigation__-roGp {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
  pointer-events: none;
}

.product-card_prevArrow__2PsbN,
.product-card_nextArrow__10wiX {
  font-size: 1.5rem;
  color: black;
  padding: 0.5em;
  cursor: pointer;
  pointer-events: all;
}

.product-card_prevArrow__2PsbN:hover,
.product-card_nextArrow__10wiX:hover {
  color: red;
}
.product-card_imageWrapper__D8RJJ {
  position: relative;
  width: 100%;
}
/*
 * PRCICING
 */

.pricing_con__YiCRl {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1.5em;
}

.pricing_pricingText__3N8N6 {
  font-weight: 600;
  font-size: 1.4rem;
}

@media (min-width: 43em) {
  .pricing_pricingText__3N8N6 {
    font-size: 1.6rem;
  }
}

.pricing_pricingTextOriginal__om7GN {
  margin-left: 0.5rem;
  color: #808080;
  font-weight: 400;
  font-size: 1.2rem;
  text-decoration: line-through;
}

.pricing_pricingTextExcludingVAT__1LGqy {
  margin-left: 0.5rem;
  color: #808080;
  font-weight: 400;
  font-size: 1.2rem;
  margin-right: 0.5em !important;
}

@media (min-width: 43em) {
  .pricing_pricingText__3N8N6 {
    font-size: 1.6rem;
  }
}

.pricing_onHoldLabel__3de3v {
  position: absolute;
  background-color: rgba(143, 137, 137, 0.7); /* semi-transparent black */
  color: white;
  padding: 5px 10px;
  z-index: 1;
}

.pricing_ecommerceText__3r9A3{
  font-size: 1.3rem !important;
  margin-left: 0.0em !important;
}

.download-app_con__3X9W6 {
   display: flex;
   flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background);
  padding: 1em 1em;
  border-radius: 8px;
  position: relative;
  min-height: 27em;
}

.download-app_centerBtn__ZRQT0 {
    margin: auto !important;
    background-color: transparent !important;
      border: none !important;
}

.download-app_headerText__2a753 {
  display: block;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: 1em !important;
}

.download-app_chart__1V9vo {
  margin-top: 1em !important;
  width: 100% !important;
  height: auto !important;
}

.download-app_playBtnCon__1PJQH {
  display: flex;
  margin-top: 1em;
  position: relative;
}

button.download-app_playBtnText__lfkds {
  margin-right: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.download-app_rectangle__2v2NT {
  margin-right: 0.2em;
  padding-right: 0.5em;
  font-weight: 500 !important;
  color: var(--color-text-in-secondary) !important;
  font-size: 1.8rem !important;
}

@media (min-width: 62em) {
    .download-app_rectangle__2v2NT {
        width: 24;
    }
}
.download-app_iconContainer__2UUrH { 
    display: flex;
    padding-right: 10px !important; 
}



.Team-card_container__2Z8LB {
  width: 100%;
  background: #FFFFFE 0% 0% no-repeat padding-box;
}

.Team-card_imgContainer__14Obb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25em;
  background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFE 0%, #FFFFFE 100%) 0% 0% no-repeat padding-box;
}

@media (min-width: 43em) {
  .Team-card_imgContainer__14Obb {
    height: 40em;
  }
}

.Team-card_imgContainer__14Obb img {
  max-width: 90%;
  max-height: 90%
}

.Team-card_separatorCon__2pMt_ {
  border-top: 1px #343333 !important;
  width: calc(54% - 3em);
  height: 1px;
  border: 4px solid #D8232A;
  margin-right: 2px;
  text-align: center;
  
}

.Team-card_infoContainer__Daa5- {
  padding: 1.5em;
  text-align: left;
}

.Team-card_infoProdNameCon__3SK2Q {
  height: calc(1.6rem * 4) !important;
}

.Team-card_infoProdName__1_2xI {
  font-size: 1.8rem;
  line-height: 1.4em;
  font-weight: 500;
  display:inline-table;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 43em) {
  .Team-card_infoProdName__1_2xI {
    font-size: 1.2rem;
    font: Poppins;
    font-weight: bold;
  }
}

.Team-card_infoOptionsCon__2IF4G {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Team-card_infoOptionsConViewBtn__3lUoF {
  margin-top: 0.5em;
}

@media (min-width: 43em) {
  .Team-card_infoOptionsConViewBtn__3lUoF {
    justify-content: flex-end;
  }
}

.Team-card_infoOptionsColorCon__80N4- {
  display: flex;
  align-items: center;
}

.Team-card_infoOptionsColorName__21Lyj {
  text-transform: capitalize;
  margin-left: 0.5em;
  font-weight: 1.6rem;
  font-weight: 300 !important;
  font-size: 1.5rem;
}

@media (min-width: 43em) {
  .Team-card_infoOptionsColorName__21Lyj {
    font-size: 1.4rem;
  }
}

.Team-card_infoOptionShopBtnIcon__6vgHz {
  color: var(--color-text-in-secondary) !important;
}

button.Team-card_infoOptionShopBtn__3rSr9 * {
  font-size: 2.2rem !important;
} 

button.Team-card_infoOptionViewBtn__86gKc {
  padding-left: 1em;
  padding-right: 1em;
  background: none !important;
}

@media (min-width: 43em) {
  .Team-card_infoOptionViewBtnCon__3EAY1 {
    margin-right: 1em !important;
  }

  button.Team-card_infoOptionViewBtn__86gKc {
    padding-left: 3em;
    padding-right: 3em;
  }
}

/* 
  COLOR INDICATOR
*/

.Team-card_colorIndicatorCon__1kgmU {
  border: 1px solid #656565;
  border-radius: 50%;
  padding: 2px;
  width: 2em;
  height: 2em;
}

.Team-card_colorIndicatorColor__eYiwk {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.Team-card_detailsName__xNzhv {
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: 400;
  padding-left: 15px;
  position:absolute;
}
@media (min-width: 43em) {
  .Team-card_detailsName__xNzhv {
    font-size: 1.1rem;
    padding-left: 15px;
    font: Poppins;
  }
}

.Team-card_positionName__1mNNW {
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 43em) {
  .Team-card_positionName__1mNNW {
    font-size: 1.2rem;
    font: Poppins;
  }
}
@media (min-width: 43em) {
  .Team-card_infoProdName__1_2xI {
    font-size: 1.6rem;
  }
}

.Team-card_iconColor__3Lql9{
  color: #D8232A !important;
}
.Order-card_OrderMonitorView__36ANx {
  padding: 1em;
}

@media (min-width: 43em) {
  .Order-card_OrderMonitorView__36ANx {
    padding: 1.5em;
    display: inherit !important;
  }
}

.Order-card_OrderMobileView__1PNqU {
  display: block !important;
}

@media (min-width: 43em) {
  .Order-card_OrderMobileView__1PNqU{
    display: none !important;
  }
  
}


.Order-card_orderIDCon__12CS- {
}

.Order-card_orderID__3Sa0h {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  display:inline-table;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 43em) {
  .Order-card_orderID__3Sa0h {
    font-size: 1.6rem !important;
  }
}


.Order-card_total__1g95z{
  font-size: 1.8rem !important;
  font-weight: 800 ;
  right: 0;
  margin-top: 1em;
}

@media (min-width: 43em) {
  .Order-card_total__1g95z{
    font-size: 2.4rem;
    font-weight: 800;
  }
}

.Order-card_orderDate__2mxs0 {
  font-size: 1.2rem !important;
  font-weight: 500 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #9A9A9A !important;
  text-overflow: ellipsis;
  margin: 0em;
}

@media (min-width: 43em) {
  .Order-card_orderDate__2mxs0 {
    font-size: 1.6rem !important;
    font-weight: 500;
    font: Poppins;
    margin-top: 0.5em !important;
    margin-bottom: 0.75em !important;
  }
}

@media (min-width: 43em) {
  .Order-card_orderID__3Sa0h {
    font-size: 2.0rem;
  }
}

button.Order-card_infoOptionViewBtn__3M-Oj {
  padding-left: 1em;

}

.Order-card_infoContainer__1bgQO {
  /* padding: 1.5em; */
  text-align: left;
  position: relative;
}

.Order-card_infoProdNameCon__25gL8 {
  position: relative !important;
}

.Order-card_infoProdNameCon__25gL8 .Order-card_viewMoreBtn__1TeEo {
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 43em) {
  .Order-card_infoProdNameCon__25gL8 .Order-card_viewMoreBtn__1TeEo {
    display: none;
  }
}


.Order-card_totalCon__3Hlxs{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Order-card_totalCon__3Hlxs .Order-card_viewMoreBtn__1TeEo {
  display: none;
}

@media (min-width: 43em) {
  .Order-card_totalCon__3Hlxs{
    justify-content: space-between;
  }

  .Order-card_totalCon__3Hlxs .Order-card_viewMoreBtn__1TeEo {
    display: inline-flex;
  }
}

.Order-card_dotIcon__qDxPp {
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
}

.Order-card_status__1O_ZU{
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  flex-direction: row;
}

@media (min-width: 43em) {
  .Order-card_status__1O_ZU{
  font-size: 1.6rem;
  flex-direction: row;
  }
}
  
button.Order-card_viewMoreBtn__1TeEo{
  height: auto !important;
  padding: 0em !important;
  flex-direction: row !important;
  font-size: 1.4rem !important;
  font-weight: 500 !important; 
  color: #D8232A !important;
}

@media (min-width: 43em) {
  button.Order-card_viewMoreBtn__1TeEo{
    color: #000000 !important;
    font-weight:600 !important;
  }
}

.Order-card_btnCon__3YET_ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5em;
  gap: 0.5em;
  margin-top: 1em;
}

@media (min-width: 43em) {
  .Order-card_btnCon__3YET_ {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin-top: 0;
  }
}

.Order-card_cancelOrder__3Yu5C,
.Order-card_trackOrder__N4MIs {
  width: 100%;
  padding-left: 1em !important;
  padding-right: 1em !important;
  margin: 0 !important;
}

@media (min-width: 43em) {
  .Order-card_cancelOrder__3Yu5C,
  .Order-card_trackOrder__N4MIs {
    width: auto;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }
  
  .Order-card_cancelOrder__3Yu5C {
    margin-right: 0.5em !important;
  }
  
  .Order-card_trackOrder__N4MIs {
    margin-left: 0.5em !important;
  }
}
.catalogue-card_specialsListItem__1cmOx {
  display: block;
  width: 100%;
  padding: 2em 1.5em;
  margin-bottom: 1em;
  background: #FFFFFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000019;
  height: 40em;
  cursor: pointer;
  position: relative;
}

@media (min-width: 43em) {
  .catalogue-card_specialsListItem__1cmOx {
    width: calc(33.33% - 0.75em);

    height: auto;
    margin-right: 1em;
  }

  .catalogue-card_specialsListItem__1cmOx:nth-child(3n) {
    margin-right: 0em;
  }
}

@media (min-width: 63em) {
  .catalogue-card_specialsListItem__1cmOx {
    width: calc(25% - 0.75em);
  }

  .catalogue-card_specialsListItem__1cmOx:nth-child(3n) {
    margin-right: 1em;
  }

  .catalogue-card_specialsListItem__1cmOx:nth-child(4n) {
    margin-right: 0em;
  }
}


.catalogue-card_specialsListImgCon__1KyQ_ {
}

.catalogue-card_specialsListImgCon__1KyQ_ img {
  width: 100%;
  height: auto;
  max-height: 90%;
}

.catalogue-card_specialsListContent__2brxP {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: static;
  position: initial;
  bottom: 1.25em;
}

.catalogue-card_specialsListSeprator__YY9ip {
  padding-bottom: 0.5em;
  background-color: var(--color-primary);
  border: none;
  width: 6em;
}

.catalogue-card_specialsListTitle__y13-V {
  padding-top: 0.3em;
  color: #343433 !important;
  font-size: 2.2rem !important;
  font-weight: 700;
  display: block !important;
}

@media (min-width: 43em) {
  .catalogue-card_specialsListTitle__y13-V {
    font-size: 1.6rem !important;
  }
}

.catalogue-card_specialsListBranch__1mKk_ {
  color: var(--color-primary) !important;
  font-size: 2.0rem !important;
  font-weight: 600;
}

@media (min-width: 43em) {
  .catalogue-card_specialsListBranch__1mKk_ {
    font-size: 1.4rem !important;
  }
}


/*
  DELIVERY PRICING
*/

.checkout-delivery_deliveryHeader__36UyD {
  display: flex;
  justify-content: space-between;
  padding: 1em 0em 1em 0em;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}

.checkout-delivery_deliveryOptsCon__3VKWg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5em;
  cursor: pointer;
}

.checkout-delivery_deliveryOptsCon__3VKWg * {
  font-size: 1.3rem !important;
}

.checkout-delivery_deliveryOptsConActive__GoxqQ * {
  font-weight: 500 !important;
}

.checkout-delivery_deliveryOpts__1J1A- {
  flex: 1 1 !important;
  display: flex;
  align-items: center;
}

.checkout-delivery_deliveryOptsIcon__183h5 {
  font-size: 1.1rem;
  margin: auto 0.5em;
  color: var(--color-background)
}

.checkout-delivery_deliveryOptsConActive__GoxqQ .checkout-delivery_deliveryOptsIcon__183h5  {
  color: inherit;
}


.checkout-delivery_infoContainer__l2ycJ {
  /* padding: 1.5em; */
  text-align: left;
  position: relative;
}

.checkout-delivery_infoProdNameCon__2_nIB {
  position: relative !important;
}

.checkout-delivery_infoProdNameCon__2_nIB .checkout-delivery_viewMoreBtn__23YTH {
  display: inline-flex;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 43em) {
  .checkout-delivery_infoProdNameCon__2_nIB .checkout-delivery_viewMoreBtn__23YTH {
    display: none;
  }
}


.checkout-delivery_totalCon__3O3gZ{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.checkout-delivery_totalCon__3O3gZ .checkout-delivery_viewMoreBtn__23YTH {
  display: none;
}

@media (min-width: 43em) {
  .checkout-delivery_totalCon__3O3gZ{
    justify-content: space-between;
  }

  .checkout-delivery_totalCon__3O3gZ .checkout-delivery_viewMoreBtn__23YTH {
    display: inline-flex;
  }
}

.checkout-delivery_OrderMonitorView__3_roS {
  padding: 1em;
}

@media (min-width: 43em) {
  .checkout-delivery_OrderMonitorView__3_roS {
    padding: 1.5em;
    display: inherit !important;
  }
}

.checkout-delivery_trackOrder__1OEAj{
  flex-direction: row !important;
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
  margin-left: 0.5em;
}

@media (min-width: 43em) {
  .checkout-delivery_cancelOrder__2qaBt{
  }

  .checkout-delivery_trackOrder__1OEAj{
  }
}

.checkout-delivery_btnCon__3bBJ3 {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 1em ;
}

@media (min-width: 43em) {
  .checkout-delivery_btnCon__3bBJ3 {
    margin-top: 0em ;
    position: absolute;
    right: 0;
    top: 0;
  }
}



.styles_labelText__2Mt3B {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  text-align: center;
}

.styles_labelText__2Mt3B > * {
  text-align: inherit;
}



/*
  CTA
*/

.styles_carouselItem__KBrs1 {
  height: 90vh;
}

.styles_ctaSubText__2mTUL {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
}

@media (min-width: 62em) {
  .styles_ctaSubText__2mTUL {
    font-size: 1.6rem !important;
  }
}

/*
  FILTER
*/

.styles_filtersCon__1Sunw {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 4em 1em;
}

@media (min-width: 62em) {
  .styles_filtersCon__1Sunw {
    padding: 4em 7em;
  }
}

.styles_filtersHeader__2tGVu {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .styles_filtersHeader__2tGVu {
    font-size: 2.8rem !important;
  }
}

.styles_filtersOptCon__1i3Lh {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.styles_filterSearchIcon__2cAU4 {
  padding: 0em 1em;
  font-size: 2.2rem;
  color: #656565
}

@media (min-width: 62em) {
  .styles_filterSearchIcon__2cAU4 {
    display: none !important;
  }
}

.styles_filterSearchInputCon__3vYTS {
  display: none;
  width: 16em;
  margin-right: 1em;
  height: 100%;
}

@media (min-width: 62em) {
  .styles_filterSearchInputCon__3vYTS {
    display: flex;
    align-items: flex-end;
  }
}

.styles_filterSearchInput__2A1Qi {
  height: 100% !important;
}

.styles_filterSelect__38Wfb {
  max-width: 40vw !important
}

@media (min-width: 62em) {
  .styles_filterSelect__38Wfb {
    width: 16em !important
  }
}

/*
  SPECIALS LIST
*/

.styles_specialsListCon__1EHe5 {
  display: block;
  padding: 1em;
}

@media (min-width: 43em) {
  .styles_specialsListCon__1EHe5 {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 62em) {
  .styles_specialsListCon__1EHe5 {
    padding: 1em 7em;
  }
}


.styles_welcomeText__23yVM {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  display: block;
  margin-top: 1em !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .styles_welcomeText__23yVM {
    font-size: 2.0rem !important;
  }
}

.styles_welcomeSummaryText__21ZfJ {
  font-size: 1.1rem !important;
  color: #9A9A9A !important;
}

@media (min-width: 62em) {
  .styles_welcomeSummaryText__21ZfJ {
    font-size: 1.6rem !important;
  }
}

img.styles_bannerItem__2T9ST {
  margin-top: 0.5em !important;
  margin-bottom: 1em !important;
  width: 100%;
}

.styles_dashboardContent__17O8p {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 62em) {
  .styles_dashboardContent__17O8p > div,
  .styles_dashboardContent__17O8p > img {
    width: calc(50% - 1em) !important;
    height: 19em;
    overflow-y: scroll;
    margin: 0em 1em  1em 0em !important;
  }

  .styles_dashboardContent__17O8p > img {
    display: none !important;
  }

  .styles_dashboardContent__17O8p > :nth-child(2) {
    margin-right: 0em !important;
  }
}

@media (min-width: 82em) {
  .styles_dashboardContent__17O8p > div,
  .styles_dashboardContent__17O8p > img {
    width: calc(33.33% - 0.67em) !important;
    height: 19em;
    overflow-y: scroll;
    margin: 0em 1em  1em 0em !important;
  }

  .styles_dashboardContent__17O8p > img {
    display: block !important;
  }

  .styles_dashboardContent__17O8p > :nth-child(2) {
    margin-right: 1em !important;
  }

  .styles_dashboardContent__17O8p > :nth-child(3) {
    margin-right: 0em !important;
  }
} 

img.styles_specialsImage__t7jB7 {
  margin-bottom: 1em !important;
  width: 100%;
  border-radius: 8px;
}

@media (min-width: 62em) {
  img.styles_specialsImage__t7jB7 {
    margin-bottom: 0em !important;
  }
}

.styles_dashboardContent2__2l8y0 {
  display: flex;
  flex-wrap: wrap;
}

.styles_previousOrderCon__2NB1j {
  height: auto;
  width: 100%;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .styles_previousOrderCon__2NB1j {
    width: calc(50% - 1em);
    height: 32em;
    margin-right: 1em; 
  }
}

@media (min-width: 82em) {
  .styles_previousOrderCon__2NB1j {
    width: calc(30% - 0.75em);
  }

  .styles_dashboardContent2__2l8y0 .styles_previousOrderCon__2NB1j {
    height: 25em !important;
  }
}

.styles_tabInfoCon__OuHCY {
  margin-top: 1em !important;
  padding: 0em 1em !important;
  border-radius: 8px;
  background-color: var(--color-background);
  width: 100%;
}


@media (min-width: 62em) {
  .styles_tabInfoCon__OuHCY {
    margin-top: 0em !important;
    width: calc(50% - 1em);
    height: 32em !important;
    margin-right: 1em !important;   
    overflow: auto;
  }
}

@media (min-width: 82em) {
  .styles_tabInfoCon__OuHCY {
    width: calc(40% - 0.75em);
    height: 25em !important;
  }
}

.styles_dashboardContent2__2l8y0 .styles_specialsImage__t7jB7 {
  display: none;
}

@media (min-width: 62em) {
  .styles_dashboardContent2__2l8y0 .styles_specialsImage__t7jB7 {
    display: block;
    width: calc(50% - 1em) !important;
    margin-left: 1em;
  }
}

@media (min-width: 82em) {
  .styles_dashboardContent2__2l8y0 .styles_specialsImage__t7jB7 {
    display: none;
  }
}

.styles_bankingInfoCon__FSLcK {
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_bankingInfoCon__FSLcK {
    margin-top: 0em !important;
    margin-bottom: 0em !important;
    width: calc(50% - 0.5em);
    height: 19em;
    margin-right: 0em; 
    overflow: auto;
  }
}


@media (min-width: 82em) {
  .styles_bankingInfoCon__FSLcK {
    width: calc(30% - 0.75em);
  }
}

.styles_bankingInfoHeader__3XQDq {
  display: block;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  margin-bottom: 1em !important;
}

.styles_bankingInfoSubHeader__bafDQ {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.styles_bankingInfoValue__38CMq,
.styles_bankingInfoValueExtra__nMj9s {
  font-size: 1.6rem !important;
  width: 50% !important;
}

.styles_bankingInfoValueExtra__nMj9s {
  display: block ;
  width: 100% !important;
}

/* .tableCon {

} */
.quotes_OrderMonitorView__2lrqW {
  display: none !important;
}

@media (min-width: 43em) {
  .quotes_OrderMonitorView__2lrqW {
    display: inherit !important;
  }
}

.quotes_OrderMobileView__L5Qir {
  display: block !important;
}

@media (min-width: 43em) {
  .quotes_OrderMobileView__L5Qir {
    display: none !important;
  }
}
.quotes_tableRow__3b8f9 {
  padding: 5em 0em;
}

.quotes_tableCon__3O352 .quotes_ant-table__31-pw .quotes_ant-table-tbody__2SjdJ > tr > td {
  padding: 8em 0em !important;
}

.quotes_colRefNo__1hl06 {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: var(--color-primary) !important;
  white-space: nowrap;
}

.quotes_colDateNo__2xw0D {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}
.quotes_colDescriptionNo__2i2Mu {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}

.quotes_colStatus__3ucU4 {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
}

button.quotes_colViewBtn__1mnSp {
  padding: 0.5em !important;
  height: auto !important;
}

button.quotes_colViewBtn__1mnSp * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

button.quotes_colViewMonBtn__2ud3a {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
  height: auto !important;
}

/* 
  Account Members
*/

.quotes_colMembersCon__1QIBh {
  display: flex;
  align-items: center;
}

.quotes_colMembersAvatar__2k4bW {
  display: none !important;
}

@media (min-width: 43em) {
  .quotes_colMembersAvatar__2k4bW {
    display: block !important;
  }
}

.quotes_colMembersNameCon__2RsKc {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}

/* .cartCon {
} */

.checkout-item_deletedItem__RLWLU {
  color: red !important;
  font-size: 14px !important; /* Adjust size to your preference */
}
.checkout-item_favoriteProduct__1rNXh {
  display: flex ;
  align-items: center;
  text-align: left ;
  font-size: 1.4rem;
  font-weight: 600 ;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
   color: var(--color-text-in-primary);
  cursor: pointer;
}
.checkout-item_favoriteProduct__1rNXh:hover {
  background: lightgray;
}

.checkout-item_cartTableViewContainer__2Ljb5 {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.checkout-item_cartTableView__38F39 {
  display: none;
}

@media (min-width: 62em) {
  .checkout-item_cartTableView__38F39 {
    display: inherit;
  }
}

.checkout-item_cartTableRow__31CJG {
  height: 50px !important;
}

.checkout-item_cartTableProductInfo__3JLpQ {
  padding: 1em;
}

.checkout-item_cartMobView__9eLBV {
  display: block;
}

@media (min-width: 62em) {
  .checkout-item_cartMobView__9eLBV {
    display: none;
  }
}

.checkout-item_cartItemCon__2gVbK {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background);
  padding: 2em;
  margin-bottom: 1em;
}

.checkout-item_cartItemCloseIconCon__ohRhv {
  align-self: flex-end;
}

.checkout-item_cartItemCloseIconCon__ohRhv button {
  padding: 0em !important;
}

@media (min-width: 62em) {
  .checkout-item_cartItemCloseIconCon__ohRhv {
    padding: inherit !important;
    display: block !important;
  }
}

.checkout-item_cartItemCloseIcon__3Bzqg {
  font-size: 1.8em;
  color: var(--color-primary);
}

.checkout-item_cartItemName__1Z_YD {
  font-size: 1.8rem !important;
  font-weight: 600;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

@media (min-width: 62em) {
  .checkout-item_cartItemName__1Z_YD {
    font-size: 2.0rem !important;
    line-height: 1.5em;
  }
}

.checkout-item_cartItemDetailsCon__BM2C0 {
  height: 14em;
  display: flex;
  margin-bottom: 1em;
}

.checkout-item_cartItemImgCon__XeL7w {
  height: 100%;
  width: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media (min-width: 62em) {
  .checkout-item_cartItemImgCon__XeL7w {
    margin: auto;
    width: 80%;
    height: 120px;
  }
}

.checkout-item_cartItemImg__1woqZ {
  width: auto !important;
  height: auto !important;
  max-width: 90%;
  max-height: 90%;
}

@media (min-width: 62em) {
  .checkout-item_cartItemImg__1woqZ {
    max-width: 70%;
    max-height: 70%;
  }
}

.checkout-item_cartItemDetails__Iybbz {
  width: 50%;
}

.checkout-item_cartItemDetailsSku__1lrLQ {
  display:block;
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .checkout-item_cartItemDetailsSku__1lrLQ {
    font-weight: 400 !important;

  }
}

.checkout-item_cartItemDetailsFieldName__3LIB0 {
  font-size: 1.6rem !important;
  font-weight: 500 !important;
}

.checkout-item_cartItemDetailsFieldValue__38F_3 {
  font-size: 1.6rem !important;
}

.checkout-item_cartItemPriceCon__q9Url {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout-item_cartItemPrice__2GYrr {
  font-size: 2.0rem !important;
  font-weight: bold !important;
}


/*
  TABLE EMPTY INDICATOR
*/

.checkout-item_emptyListCon__37U-e {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25em;
}

.checkout-item_emptyListShop__y_2K3 {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
}


/*
 * PRICING INFO
 */
.checkout-item_pricingInfo__1rgtW{
  display: flex;
  flex-direction: column;
  height: auto !important
}


.checkout-item_backOrderText__2lMIx {
  font-size: 1.4rem !important;
  color: red !important;
  margin-bottom: 2em;
}

.checkout-item_priceConSelectedTbl__3tIKJ{
  height: auto;
}


/* .tableCon {

} */

.quotes-summary_tableRow__5XbsD {
  padding: 5em 0em;
}

.quotes-summary_tableCon__2JjG6 .quotes-summary_ant-table__wup-O .quotes-summary_ant-table-tbody__2y7ZU > tr > td {
  padding: 8em 0em !important;
}

.quotes-summary_colRefNo__NeK1Z {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: var(--color-primary) !important;
}

.quotes-summary_colDateNo__20mFa {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}
.quotes-summary_colDescriptionNo__5a161 {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}

.quotes-summary_colStatus__3-JXF {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
}

button.quotes-summary_colViewBtn__39zpQ {
  padding: 0.5em !important;
  height: auto !important;
}

button.quotes-summary_colViewBtn__39zpQ * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

/* .tableCon {

} */
.voucher_OrderMonitorView__1FQ6U {
  display: none !important;
}

@media (min-width: 43em) {
  .voucher_OrderMonitorView__1FQ6U {
    display: inherit !important;
  }
}

.voucher_OrderMobileView__18sTa {
  display: block !important;
}

@media (min-width: 43em) {
  .voucher_OrderMobileView__18sTa {
    display: none !important;
  }
}
.voucher_tableRow__3GnKC {
  padding: 5em 0em;
}

.voucher_tableCon__3eJKf .voucher_ant-table__hk210 .voucher_ant-table-tbody__6t9nm > tr > td {
  padding: 8em 0em !important;
}

.voucher_colRefNo__2GGaA {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: var(--color-primary) !important;
  white-space: nowrap;
}

.voucher_colDateNo__2NBcv {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}
.voucher_colDescriptionNo__31a5W {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
  color: #343433 !important;
}

.voucher_colStatus__VfC0c {
  font-size: 1.4rem !important;
  font-weight: 300 !important;
}

button.voucher_colViewBtn__1bDgc {
  padding: 0.5em !important;
  height: auto !important;
}

button.voucher_colViewBtn__1bDgc * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

button.voucher_colViewMonBtn__29JDW {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.6em !important;
  height: auto !important;
}

/*
  Account Members
*/

.voucher_colMembersCon__2yLSj {
  display: flex;
  align-items: center;
}

.voucher_colMembersAvatar__3YK-7 {
  display: none !important;
}

@media (min-width: 43em) {
  .voucher_colMembersAvatar__3YK-7 {
    display: block !important;
  }
}

.voucher_colMembersNameCon__1fqEh {
  display: flex;
  flex-direction: column;
  padding-left: 1em;
}

.styles_accountDashboardIcon__10KNE {
  font-size: 2.5rem !important;
}

@media (min-width: 62em) {
  .styles_accountDashboardIcon__10KNE {
    font-size: 2.2rem !important;
  }
}
.styles_headerText__Se6WS {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.styles_ordersCard__3hbbX{
  display: inline-block;
  width: calc(100%) !important;
  background-color: #FFFFFE;
  margin-bottom: 2em;
  border-radius: 10px;
}
button.styles_loadMoreBtn__2MIi6{
  height: auto !important;
  padding: 0em !important;
  flex-direction: row !important;
  font-size: 1.4rem !important;
  font-weight: 800 ;
  color: #9A9A9A !important;
}

.styles_loadCon__3AlJh{
  position: relative !important;
  text-align: center !important;
}

/*
  EMPTY LIST
 */

.styles_emptyListCon__3or2z {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25em;
}


.styles_tableCon__1tW1X {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}
.styles_searchContainer__3U71U {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

.styles_searchInput__2s35b {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

.styles_pageHeaderOptionSearch__16cg- {
  border-radius: 20px;
  padding-left: 10px;
}

.styles_searchDatePickerContainer__1bnRH {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.styles_searchContainer__3U71U {
  margin-right: 1rem !important;
}

.styles_datePickerContainer__2xr8t {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.styles_datePicker__2UJAp {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

@media screen and (min-width: 62em) {
  .styles_searchDatePickerContainer__1bnRH {
    flex-direction: row;
  }

  .styles_searchInput__2s35b,
  .styles_datePicker__2UJAp {
    width: 300px !important;
  }
}


.styles_ordersCard__2BCKp{
  display: inline-block;
  width: calc(100%) !important;
  background-color: #FFFFFE;
  margin-bottom: 2em;
  border-radius: 10px;
}
button.styles_loadMoreBtn__3Ue0R{
  height: auto !important;
  padding: 0em !important;
  flex-direction: row !important;
  font-size: 1.4rem !important;
  font-weight: 800 ; 
  color: #9A9A9A !important;
}

.styles_loadCon__3GhH3{
  position: relative !important;
  text-align: center !important;
}

/* 
  EMPTY LIST
 */

.styles_emptyListCon__2kNer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25em;
}


.styles_tableCon__3FJQ6 {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}
.styles_searchContainer__1E1l6 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

.styles_searchInput__1eUZO {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

.styles_pageHeaderOptionSearch__38UMx {
  border-radius: 20px;
  padding-left: 10px;
}

.styles_searchDatePickerContainer__1JsyE {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.styles_searchContainer__1E1l6 {
  margin-right: 1rem !important;
}

.styles_datePickerContainer__3V6Ba {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.styles_datePicker__1GVpj {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

@media screen and (min-width: 62em) {
  .styles_searchDatePickerContainer__1JsyE {
    flex-direction: row;
  }

  .styles_searchInput__1eUZO,
  .styles_datePicker__1GVpj {
    width: 300px !important;
  }
}


.styles_headerText__3K0da {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.styles_tableCon__2wgkn {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}

.styles_searchContainer__3zxw6 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

.styles_searchInput__2dzEy {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

.styles_pageHeaderOptionSearch__2muVg {
  border-radius: 20px;
  padding-left: 10px;
}

.styles_searchDatePickerContainer__2dSvy {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.styles_searchContainer__3zxw6 {
  margin-right: 1rem !important;
}

.styles_datePickerContainer__5FlzB {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.styles_datePicker__113QM {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}


@media screen and (min-width: 62em) {
  .styles_searchDatePickerContainer__2dSvy {
    flex-direction: row;
  }

  .styles_searchInput__2dzEy,
  .styles_datePicker__113QM {
    width: 300px !important;
  }
}








.styles_headerText__3lJ4r {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.styles_tableCon__3cbwp {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}

.styles_searchContainer__1NDDC {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

.styles_searchInput__2tVOV {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

.styles_pageHeaderOptionSearch__RwdOC {
  border-radius: 20px;
  padding-left: 10px;
}

.styles_searchDatePickerContainer__4bNEx {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.styles_searchContainer__1NDDC {
  margin-right: 1rem !important;
}

.styles_datePickerContainer__3qOGN {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.styles_datePicker__2Zpj1 {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}


@media screen and (min-width: 62em) {
  .styles_searchDatePickerContainer__4bNEx {
    flex-direction: row;
  }

  .styles_searchInput__2tVOV,
  .styles_datePicker__2Zpj1 {
    width: 300px !important;
  }
}


.styles_headerText__2HvQU {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.styles_tableCon__CDNhf {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}

.styles_searchContainer__3KlyR {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px !important;
}

.styles_searchInput__1EKFu {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}

.styles_pageHeaderOptionSearch__3uXaC {
  border-radius: 20px;
  padding-left: 10px;
}

.styles_searchDatePickerContainer__2WQdW {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.styles_searchContainer__3KlyR {
  margin-right: 1rem !important;
}

.styles_datePickerContainer__2zDlb {
  margin-right: 5px !important;
  margin-bottom: 5px !important;
}

.styles_datePicker__1EVMS {
  width: 100% !important;
  height: 40px !important;
  border-radius: 0.5em !important;
}


@media screen and (min-width: 62em) {
  .styles_searchDatePickerContainer__2WQdW {
    flex-direction: row;
  }

  .styles_searchInput__1EKFu,
  .styles_datePicker__1EVMS {
    width: 300px !important;
  }
}


.styles_inviteBtn__z5EMG {
  /* margin: 1em 0; */
}

/* 
    MODAL FORM
*/
.styles_checkoutModal__3w0Mo {
  width: 46em !important;
}

.styles_formModalBody__5eWtj {
  display: flex;
  justify-content: space-between;
}

.styles_headerText__qfNRh {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.styles_contentCon__1Z4YX {
  background-color: none;
  border-radius: 8px;
  padding: 0em;
}

@media (min-width: 62em) {
  .styles_contentCon__1Z4YX {
    background-color: var(--color-background);
    padding: 2em;
  }
}

.styles_userInfoCon__1hidZ {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 0.5px solid #C7C7C7;
}

@media (min-width: 42em) {
  .styles_userInfoCon__1hidZ {
    padding-bottom: 2em;
  }
}

.styles_userAvatar__3l_tP {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 3em !important;
  width: 3em !important;
}

.styles_userAvatar__3l_tP * {
  color: var(--color-text-in-primary);
  font-size: 3.0rem !important;
}

.styles_userInfo__OildX {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  min-width: 60%;
}

.styles_userName__2yhcs {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
}

.styles_userEmail__j9A4n,
.styles_userPhone___IdU7,
.styles_userAddress__3gPqX {
  font-size: 1.1rem !important;
  color: #9A9A9A !important
}

.styles_addAddressCon__3n1zb {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}

@media (min-width: 42em) {
  .styles_addAddressCon__3n1zb {
    width: auto;
    align-self: flex-end;
  }
}

button.styles_addAddress__aNYH8 {
  /* width:  */
  padding-left: 2em !important;
  padding-right: 2em !important;
}

/*
  FORM
*/

/* .formCon {
} */

.styles_getInTouchCon__3U7-s {
  margin-top: 2em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 62em) {
  .styles_getInTouchCon__3U7-s {
    width: 90%;
  }
}

.styles_getInTouchText__kag7Y {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-top: 2em !important;
  display: block;

}

/* .getInTouchRadioGrp {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
} */

/* .getInTouchRadioGrp *  {
  font-size: 1.4rem !important;
  font-weight: 500;
} */

@media (min-width: 62em) {
  .styles_getInTouchCon__3U7-s {
    margin-bottom: 0em;
  }
}

.styles_getInTouchFormCon__XaVey {
  display: block;
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_getInTouchFormCon__XaVey {
    margin-bottom: 0em;
    width: calc(50% - 0.5em);
  }

  .styles_getInTouchFormConResponsive__1RP2u {
    display: inline-block;
    margin-left: 0em;
  }

  .styles_formCon__1ldKe .styles_getInTouchFormItems__qeHQe .styles_getInTouchFormConResponsive__1RP2u:nth-child(2n) {
    margin-left: 1em;
  }

  .styles_getInTouchFormConTextArea__ztSj2 {
    width: 100%;
  }
}

.styles_getInTouchLabel__25UUy {
  font-size: 1.4rem !important;
  display: block;
  margin-bottom: 0.5em;
}

.styles_getInTouchNote__yVhM0 {
  color: var(--color-primary) !important;
  font-size: 0.9rem !important;
  display: block;
  margin-bottom: 0.5em;
}

button.styles_resetPassword__2Dyvn {
  padding: 0.5em 0 !important;
  height: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

button.styles_resetPassword__2Dyvn * {
  color: var(--color-primary) !important;
  font-weight: 600 !important;
  font-size: 1.6rem !important;
}

.styles_getInTouchCommsCon__3KgLg {
  /* margin-top: 1em; */
  margin-bottom: 2.5em;
}

.styles_getInTouchComms__1ra3p {
  font-size: 1.4rem !important;
  font-weight: 500;
}



.styles_select__1w9ht {
  margin-top: 2em !important;
  width: 100%;
  height: 3em !important;
  background: none !important;
  border-radius: 0.5em !important;
}
.styles_select__1w9ht * {
  text-align: left;
  height: 100% !important;
  border-radius: 0.5em !important;
  background: none !important;
}

.styles_select__1w9ht span {
  display: flex;
  align-items: center;

}

@media (min-width: 62em) {
  .styles_select__1w9ht{
    width: 60% !important;
    left: 4% !important;
  }
}


.styles_addAddress__aNYH8{
  background-color:  var(--color-background) !important ;
  border-radius: var(--color-background) !important;
}

.styles_inputDisabled__13CKe {
  color: #ccc !important;
}

.styles_linkButton__2kMcS{
  display: flex;
  flex-direction: row;
}



.avatar-default-user-icon {
  font-size: 1em;
}

.account-avatar-container {
  background: none !important; 
  border: 2px solid var(--color-text-in-primary) !important;
}


nav.transparent-navbar .account-avatar-container {
  border: 2px solid var(--color-title-nav-transparent) !important;
}

.styles_circularButton__ARVWj {
  width: 30px;
  height: 25px;
  border-radius: 15px; 
  text-align: center;
  padding: 2px;
}
.styles_inputDisabled__2maPL {
  color: #ccc !important;
}

.styles_datePicker__2ococ {
  background: none;
  border: 1px solid #9a9a9a;
  color: var(--color-text-in-primary) !important;
  border-radius: 0px !important;
  height: 4em;
  text-align: left !important;
  width: 100% !important;
  font-size: 1.2rem !important;
}


.styles_headerText__cO5Yo {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}

.styles_tableCon__2Ihfl {
  margin-top: 1em;
  padding: 1em;
  background-color: var(--color-background);
  border-radius: 8px;
}

.styles_create-flex__Fd1NY {
    display: "flex";
    flex-direction: 'row';
    margin-bottom: 16px;
}

.styles_parcels-input__33R_k {
    width: 265px !important;
    background: none;
    border: 1.25px solid #ACACAC;;
    border-radius: 0px !important;
    font-size: 1.2rem;
    padding: 6px;
    margin-right: 8px;
}

.styles_order-tab-heading__1-j3k {
    font-size: 15px;
    font-weight: bold;
}

.styles_order-spacing__3kHpb {
    margin-top: 20px;
}

.styles_order-tab-comments__10xXF {
    font: 12px;
    font-weight: normal;
    color: #7C7C7C;   
}

.styles_product-border__1RbBu {
    display: flex;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 35px;
    padding-top: 13px;
}

.styles_order-product-image__2P2Y6 {
    width: 100px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    margin-right: 16px;
}

.styles_image-style__3OK-A {
    max-width: 100px;
    max-height: 80px;
    /* margin-top: 25px; */
}

.styles_product-quantity-font__3eG13 {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
}

.styles_product-font__1K0Z9 {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center
}

.styles_product-text-margin__A-4vy {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.styles_coloumn-flex__2ixbc {
    display: flex;
    flex-direction: column;
}

.styles_heading1__1_Rul {
    font-weight: 600;
    font-size: 13px;
}

.styles_heading2__xFm9i {
    font-style: 14px;
    color: #7C7C7C;
}

.styles_heading3__EJgpt {
    font-weight: 600;
    font-size: 18px;
}

.styles_order-product-info__1MBsA {
    display: flex;
    justify-content: space-between;
}

.styles_long-text__i08Eh {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.styles_product-image__x2ts7 {
    display: block;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
}

.styles_editable-cell__3d8l_ {
    position: relative;
}

.styles_editable-cell-value-wrap__3pbjM {
    padding: 5px 12px;
    cursor: pointer;
}

.styles_editable-row__3X3VD:hover .styles_editable-cell-value-wrap__3pbjM {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .styles_editable-row__3X3VD:hover .styles_editable-cell-value-wrap__3pbjM {
    border: 1px solid #434343;
}

.styles_message-box__3kc2I {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #2F80ED;
    border-radius: 15.6308px;
    margin-top: 16px;
    margin-left: 32px;
    width: 430px;
}

.styles_message-box-font__3Jt2M {
    font-weight: 600;
    font-size: 20.4462px;
    padding-bottom: 10px
}

.styles_message-box-time__1x9w7 {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.styles_flex-only__3NN4j {
    display: flex;
}

.styles_message-box-sender__wKHiA {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15.6308px;
    background: #FFFFFF;
    border-radius: 15.6308px;
    margin-top: 30px;
    margin-left: 32px;
    width: 380px;
    height: 75px;
}

.styles_message-image__10FST {
    width: 82px;
    height: 84px;
    border-radius: 52.7538px;
    display: flex;
    justify-content: flex-end
}

.styles_message-bottom-margin__33-Z7 {
    width: 100%;
    background: #FFFFFF;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.styles_message-bottom-style__3c0ua {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
}

.styles_icon-style-right__20Id3 {
    cursor: pointer;
}

.styles_pageheader-options__31VBK .styles_orders-date-picker__1K1u7 {
    margin-right: 16px;
    border-radius: 4px !important;
    height: 40px;
    width: 240px;
    border: 1px solid #E2E8F0 !important;
}

.styles_pageheader-options__31VBK .styles_orders-date-picker__1K1u7 * {
    color: #CBD5E0 !important;
}

.styles_pageheader-options-search__bChcy.styles_orders-search__Zb_CU {
    margin-right: 0px;
    width: auto;
}

.styles_orders-drawer-header-sutitle__1XPc0 {
    color: #000;
    font-size: 1.2rem;
    margin-top: 16px;
}

.styles_order-statustab-head__1ntzH {
    margin-bottom: 16px;
}

.styles_order-messages-list__3SuAO {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
}

.styles_create-flex__oog-K {
    display: "flex";
    flex-direction: 'row';
    margin-bottom: 16px;
}

.styles_parcels-input__2YjH8 {
    width: 265px !important;
    background: none;
    border: 1.25px solid #ACACAC;;
    border-radius: 0px !important;
    font-size: 1.2rem;
    padding: 6px;
    margin-right: 8px;
}

.styles_order-tab-heading__Slgou {
    font-size: 15px;
    font-weight: bold;
}

.styles_order-spacing__1Ec58 {
    margin-top: 20px;
}

.styles_order-tab-comments__CKn8Z {
    font: 12px;
    font-weight: normal;
    color: #7C7C7C;   
}

.styles_product-border__1_MGI {
    display: flex;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 35px;
    padding-top: 13px;
}

.styles_order-product-image__L2KoL {
    width: 100px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 16px;
    margin-right: 16px;
}

.styles_image-style__2XlcZ {
    max-width: 100px;
    max-height: 80px;
    /* margin-top: 25px; */
}

.styles_product-quantity-font__2HZEZ {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
}

.styles_product-font__3nExC {
    color: #7C7C7C;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: center
}

.styles_product-text-margin__1x-Hf {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.styles_columnFlex__3Uqn- {
    display: flex;
    flex-direction: column;
}

.styles_heading1__kxe_3 {
    font-weight: 600;
    font-size: 13px;
}

.styles_heading2__1f3Uw {
    font-style: 14px;
    color: #7C7C7C;
}

.styles_heading3__3Nd_t {
    font-weight: 600;
    font-size: 18px;
}

.styles_order-product-info__PRsMh {
    display: flex;
    justify-content: space-between;
}

.styles_long-text__OSqDj {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

img.styles_product-image__1ERKo {
    display: block;
    max-width: 30px;
    max-height: 30px;
    width: auto;
    height: auto;
}

.styles_editable-cell__1FY06 {
    position: relative;
}

.styles_editable-cell-value-wrap__1T93o {
    padding: 5px 12px;
    cursor: pointer;
}

.styles_editable-row__2DurH:hover .styles_editable-cell-value-wrap__1T93o {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .styles_editable-row__2DurH:hover .styles_editable-cell-value-wrap__1T93o {
    border: 1px solid #434343;
}

.styles_messageBox__2IuqQ {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: #2F80ED;
    border-radius: 15.6308px;
    margin-top: 16px;
    margin-left: 0em;
    width: 20em;
}

@media screen and (min-width: 62em) {
    .styles_messageBox__2IuqQ {
    width: 430px;
    margin-left: 32px;
    }
}


.styles_messageBoxFont__veX3A {
    font-weight: 600;
    font-size: 20.4462px;
    padding-bottom: 10px
}

.styles_messageBoxTime__3lqsI {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}



.styles_flexOnly__p-CLK {
    display: flex;
}

.styles_messageBoxSender__2OkXx {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15.6308px;
    background: #FFFFFF;
    border-radius: 15.6308px;
    margin-top: 30px;
    margin-left: 32px;
    width: 20em;
    height: 75px;
}

@media screen and (min-width: 62em) {
    .styles_messageBoxSender__2OkXx {
    width: 430px;
    margin-left: 32px;
    }
 
}

.styles_messageImage__37Cjo {
    width: 82px;
    height: 84px;
    border-radius: 52.7538px;
    display: flex;
    justify-content: flex-end
}

.styles_messageBottomMargin__382Ag {
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    bottom: 0px;
    left: 0px;
}

.styles_messageBottomStyle__3x_2W {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
}

.styles_iconStyleRight__kDZ4Y {
    cursor: pointer;
}

.styles_pageHeaderOptions__j1yQd .styles_ordersDatePicker__1KEg6 {
    margin-right: 16px;
    border-radius: 4px !important;
    height: 40px;
    width: 240px;
    border: 1px solid #E2E8F0 !important;
}

.styles_pageheader-options__f6oTB .styles_orders-date-picker__32Gub * {
    color: #CBD5E0 !important;
}

.styles_pageheader-options-search__2jYCD.styles_orders-search__20WXw {
    margin-right: 0px;
    width: auto;
}

.styles_orders-drawer-header-sutitle__4h1Tm {
    color: #000;
    font-size: 1.2rem;
    margin-top: 16px;
}

.styles_order-statustab-head__2rb1o {
    margin-bottom: 16px;
}

.styles_orderMessagesList__3gAho {
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 20px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column-reverse;
}

.styles_searchDatePickerContainer__320z0 {
 position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 85vh;
}

/* styles.module.css */

.loyalty-card_container__3n8f2 {
    display: flex;
    align-items: center;
    width:100%;
}

.loyalty-card_LoyaltyCard__VQJH4 {
    position: relative; 
    border-radius: 1.5em !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 25rem !important;
    margin: 0 auto;
    overflow: hidden;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(/static/media/loyalty-card-barcode.d0559b18.jpg) !important;
}

.loyalty-card_LoyaltyCard__VQJH4:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.loyalty-card_loyaltyCardHeader__3Ydit {
    position: relative;
    border-top-left-radius: 0.2em; 
    border-top-right-radius: 0.2em; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    margin-top: -2em !important;
}

.loyalty-card_foxImageContainer__3C-1f
{
    display: flex;
    flex-direction: column;
}

.loyalty-card_foxImage__1vjBE {
    width:30%;
    height: 5em; 
    display: block;
    margin-top: 0em !important;
}

.loyalty-card_foxText__2u0TS {
    margin-top:-2em; 
    font-style: italic;
    font-weight: bold;
}
.loyalty-card_bidvestImage__9JjTY {
    width: 4em;
    height: 4em; 
    display: block;
    margin: 0 !important;
}

.loyalty-card_loyaltyCardBody__zYKSL {
    text-align: center;
}

.loyalty-card_fullName__FicEx {
    margin: 0;
    font-size: 1.5em;
    color: #333;
}

.loyalty-card_cardNumber__308DI {
    margin-top: 1em;
    font-size: 1.2em;
    color: #555; 
    text-align: center;
}

.loyalty-card_websiteLink__1KtSM {
    margin-top: 0.5em;
    font-size: 1.2em;
    color: red; 
    font-weight: bold;
    text-align: center;
}


.styles_headerText__c6YSb {
  font-weight: 600 !important;
  font-size: 1.4rem !important;
  margin-top: 0.5em;
}
  
  @media (min-width: 62em) {
    .styles_welcomeText__3Tu3n {
      font-size: 2.0rem !important;
    }
  }

  .styles_cardCon__4cYN8 {
    display: flex;
    flex-direction: row;
    width: 100%;
    grid-gap: 1em;
    gap: 1em;
  }
  
  @media screen and (max-width: 768px) {
    .styles_cardCon__4cYN8 {
      flex-direction: column;
      grid-gap:1em;
      gap:1em;
    }
  }
  
.styles_searchDatePickerContainer__3JQpn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.5em;
}

@media screen and (min-width: 62em) {
  .styles_searchDatePickerContainer__3JQpn {
    flex-direction: row;
  }
}


.allocate-loyalty-card_headerText__JQvDd {
    font-weight: 600 !important;
    font-size: 1.4rem !important;
    margin-top: 0.5em;
  }
  
  .allocate-loyalty-card_contentCon__BWYhF {
    background-color: none;
    border-radius: 8px;
    padding: 0em;
  }
  
  @media (min-width: 62em) {
    .allocate-loyalty-card_contentCon__BWYhF {
      background-color: var(--color-background);
      padding: 2em;
    }
  }
  
  .allocate-loyalty-card_userInfoCon__1-CC7 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;
    padding-bottom: 1em;
    border-bottom: 0.5px solid #C7C7C7;
  }
  
  @media (min-width: 42em) {
    .allocate-loyalty-card_userInfoCon__1-CC7 {
      padding-bottom: 2em;
    }
  }
  
  .allocate-loyalty-card_userAvatar__1WAJG {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 3em !important;
    width: 3em !important;
  }
  
  .allocate-loyalty-card_userAvatar__1WAJG * {
    color: var(--color-text-in-primary);
    font-size: 3.0rem !important;
  }
  
  .allocate-loyalty-card_userInfo__LRW56 {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    min-width: 60%;
  }


  /*
    FORM
  */
  
  /* .formCon {
  } */
  
  .allocate-loyalty-card_getInTouchCon__1tyGg {
    margin-top: 2em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
  }
  
  @media (min-width: 62em) {
    .allocate-loyalty-card_getInTouchCon__1tyGg {
      width: 90%;
    }
  }
  
  .allocate-loyalty-card_getInTouchText__1SxHU {
    font-size: 1.6rem !important;
    font-weight: 600;
    margin-top: 2em !important;
    display: block;
  
  }  
  @media (min-width: 62em) {
    .allocate-loyalty-card_getInTouchCon__1tyGg {
      margin-bottom: 0em;
    }
  }
  
  .allocate-loyalty-card_getInTouchFormCon__2gYoS {
    display: block;
    margin-top: 1em;
    width: 100%;
  }
  
  @media (min-width: 62em) {
    .allocate-loyalty-card_getInTouchFormCon__2gYoS {
      margin-bottom: 0em;
      width: calc(50% - 0.5em);
    }
  
    .allocate-loyalty-card_getInTouchFormConResponsive__2v240 {
      display: inline-block;
      margin-left: 0em;
    }
  
    .allocate-loyalty-card_formCon__3ZjMU .allocate-loyalty-card_getInTouchFormItems__2d_GS .allocate-loyalty-card_getInTouchFormConResponsive__2v240:nth-child(2n) {
      margin-left: 1em;
    }
  
    .allocate-loyalty-card_getInTouchFormConTextArea__1Zi_9 {
      width: 100%;
    }
  }
  
  .allocate-loyalty-card_getInTouchLabel__12Ksm {
    font-size: 1.4rem !important;
    display: block;
    margin-bottom: 0.5em;
  }
  
  .allocate-loyalty-card_getInTouchNote__2SaZE {
    color: var(--color-primary) !important;
    font-size: 0.9rem !important;
    display: block;
    margin-bottom: 0.5em;
  }
  
  .allocate-loyalty-card_getInTouchCommsCon__3ppzh {
    /* margin-top: 1em; */
    margin-bottom: 2.5em;
  }
  
  .allocate-loyalty-card_getInTouchComms__cTisa {
    font-size: 1.4rem !important;
    font-weight: 500;
  }

  
  .allocate-loyalty-card_inputDisabled__1VhMP {
    color: #ccc !important;
  }
  
  .allocate-loyalty-card_linkButton__2AUnG{
    display: flex;
    flex-direction: row;
  }
  
.loyalty-card-front_container__28Knu {
    display: flex;
    align-items: center;
    width:100%;
}

.loyalty-card-front_cardContainer__3VRzl {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(/static/media/loyalty_card_back.296cd195.jpg) !important;
    width: 100% !important;
    height: 25rem;
    border-radius: 1.5rem !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    position: relative;
    overflow: hidden;
  }
  .loyalty-card-front_cardContainer__3VRzl:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
  
  .loyalty-card-front_foxLogo__3Gznx {
    width: 8em ;
    height: 8em ;
    margin-top: -2.3em;
  }
  
  .loyalty-card-front_cardText__22NZf {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
  
  .loyalty-card-front_cardText__22NZf h1 {
    font-size: 24px;
    margin: 0;
    font-weight: normal;
    color: whitesmoke;
  }
  .loyalty-card-front_cardTopText__2rhN- {
    position: absolute;
    top: 4em !important;
    left: 2em !important;
  }
  .loyalty-card-front_cardTopText__2rhN- h2 {
    font-size: 15;
    margin-top: 0em;
    font-weight: normal;
    color: whitesmoke;
  }
  

.styles_mobileMenuCon__2Kz4A {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em 1em;
  height: 100%;
  background-color: var(--color-background);
  margin-bottom: 1em;
}

.styles_menuItem__1rR9L {
  padding: 0;
  padding: 1.5em 0em;
} 

.styles_menuItem__1rR9L,
.styles_menuItem__1rR9L * {
  color: var(--color-text-in-primary) !important;
  text-align: left;
}

.styles_menuItemBorder__1_OXv {
  border-bottom: 1px solid #343433;
}

.styles_menuItemLink__2lWki {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.styles_menuItemText__1MkvX {
  padding: 0;
  font-size: 1.8rem;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.styles_menuLoginBtnCon__jRLu4 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6em;
}

.styles_menuLoginBtnCon__jRLu4 > *{
  width: calc(50% - 0.5em);
}

.styles_menuItemRightArrow__GMArA {
  font-size: 2.0rem;
  color: var(--color-text-in-primary);
}

/* 
  PRODUCT LIST
*/

.styles_productCategoryCon__2DQe8 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: center;
}

.styles_favoritesCon__1vBCD {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.styles_productCategoryItem__2YfoI {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  text-transform: capitalize;
}

.styles_productCategory__GwoEa {
  display: block;
  padding: 0.5em 1em;
  text-align: left;
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

.styles_productCategory__GwoEa:first-child {
  padding-top: 1em;
}

.styles_favoriteProduct__uVxUf {
  display: flex ;
  align-items: center;
  text-align: left ;
  font-size: 1.4rem;
  font-weight: 600 ;
  text-transform: uppercase;
  height: 3em;
  padding-left: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
   color: var(--color-text-in-primary);
  cursor: pointer;
  flex: 1 1; 
}
.styles_favoriteProduct__uVxUf:hover {
  background: lightgray;
}


/* 
  MOBILE CATEGORY OPTIONS
*/

.styles_mobileCategoryHeader__1My0f {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: var(--color-primary);
  padding: 1em;
  margin-bottom: 2em;
}

.styles_mobileCategoryCloseIcon__jbIGJ {
  font-size: 2.4rem;
  color: var(--color-text-in-secondary) !important;
}

.styles_mobileCategoryHeaderText__1-Oyk {
  color: var(--color-text-in-secondary) !important;
  font-size: 1.6rem !important;
  font-weight: 500 !important;
  display: flex;
  justify-content: center;
  margin: 0em !important;
}

.styles_mobileCategoryItemCon__138cH {
  text-align: left;
  padding-bottom: 1.5em;
}

.styles_mobileCategoryItem__1mom1 {
  font-size: 1.6rem !important;
  font-weight: 500 !important;
  text-transform: capitalize;
}

.search-results_con__2ZQ_v {
  margin-top: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 1em 1em;
}

@media (min-width: 62em) {
  .search-results_con__2ZQ_v {
    padding: 1em 5.5em;
  }
}

.search-results_con__2ZQ_v  * {
  color: var(--color-text-in-primary) ;
}

.search-results_contentCon__14DhS {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em;
  background-color: var(--color-background);
  box-shadow: 0px 3px 20px #00000019;
  min-height: 24em;
  border-radius: 8px;
  /* min-height: 65vh; */
  height: 85vh;
}

@media (min-width: 62em) {
  .search-results_contentCon__14DhS {
    height: auto;
    max-height: 45vh;
  }
}

.search-results_searchCloseBtn__2BKNH {
  padding: 0em 0em !important;
  height: auto !important;
  align-self: flex-end;
}

.search-results_searchCloseIcon__1zSW4 {
  font-size: 2.2rem !important;
  color: var(--color-primary) !important;
}

.search-results_searchInputCon__1f_xF {
  display: block;
  margin: 0.5em 0em;
}

@media (min-width: 62em) {
  .search-results_searchInputCon__1f_xF {
    display: none;
  }
}

.search-results_searchSpinCon__dmhYB {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-results_searchItemsCon__LjrVr {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.search-results_searchItem__3BL9P {
  display: flex;
  align-items: center;
  padding: 2em 0.5em;
  border-bottom: 0.1px solid #707070;
}

.search-results_searchItem__3BL9P:hover {
  background: var(--color-background-secondary);
  cursor: pointer;
}

.search-results_searchItemImg__3Cvyl {
  height: 2.5em;
  width: 2.5em;
}

.search-results_searchItemImg__3Cvyl img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.search-results_searchItemInfo__2s0xr {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0em 0.5em !important;
}

.search-results_searchItemName__1b-iS {
  flex: 1 1 !important;
  font-size: 1.4rem !important;
  margin-bottom: 0.5em;
}

.search-results_searchItemPriceText__2eh5F {
  font-size: 1.4rem !important;
}

.search-results_searchItemPriceText2__3-s66 {
  font-size: 1.2rem !important;
}

.search-results_searchItemInactive__1dfY1 {
  font-size: 1.2rem !important;
  color: red !important;
}

.search-results_viewAllButtonContainer__14Ls2 {
  display: flex;
  justify-content: center;
  align-items: center;
}


.search-results_searchItemCategory__2H9Jv{
  color: #ff0000 !important;
  font-size: 10px !important;
}


/*
  CHECKOUT LAYOUT
*/
.checkout_checkoutConBg__2-juw {
  background-color: var(--color-background-secondary);
}

.checkout_checkoutCon__1v_5h {
  padding: 1em 1em;
  margin-top: 2.5em;
}

@media (min-width: 62em) {
  .checkout_checkoutCon__1v_5h {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.checkout_checkoutPageTitle__3tGNX {
  font-weight: 600 !important;
  margin-bottom: 1em !important;
  font-size: 2.6rem !important;
}

.checkout_checkoutStepsCon__2IsPu {
  background-color: var(--color-background);
  padding: 1em 0em;
  width: 100%;
  height: 8em;
  overflow-x: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .checkout_checkoutStepsCon__2IsPu {
    height: auto;
    padding: 2em 1em;
  }
}

.checkout_checkoutHeaderCompCon__2LBxl {
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .checkout_checkoutStepsTitle__1eis3 {
    font-size: 1.6rem !important;
    font-weight: 600 !important;
    text-align: center;
    display: flex;
    line-height: 1.5em;
  }
}

.checkout_checkoutStepsMobTitle__10UoM {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  margin-top: 2em !important;
  padding-left: 3em;
}

.checkout_checkoutMainCon__7CYKu {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: 1em; */
}

@media (min-width: 43em) {
  .checkout_checkoutMainCon__7CYKu {
    /* margin-top: 1em; */
    flex-direction: row;
    width: 100%;
  }
}

.checkout_checkoutMainConFlexReverse__1J7RC {
  display: flex;
  flex-direction: column-reverse;
}

@media (min-width: 43em) {
  .checkout_checkoutMainConFlexReverse__1J7RC {
    flex-direction: row;
  }
}

.checkout_checkoutContent__2TJJm {
  flex: 1 1;
  margin-top: 1em;
  /* margin-bottom: 1em; */
  padding-right: 0em;
}

@media (min-width: 43em) {
  .checkout_checkoutContent__2TJJm {
    margin-top: 0em;
    padding-right: 1em;
  }
}

.checkout_checkoutContentHeader__2v7Ju {
  font-size: 1.6rem !important;
  font-weight: 600 !important;
  padding-bottom: 1em !important;
  display: block;
  /* margin-top: 0.5em; */
}

@media (min-width: 43em) {
  .checkout_checkoutContentHeader__2v7Ju {
    font-size: 1.8rem !important;
    margin-top: 0em;
  }
}

.checkout_checkoutSidePanel__xJx4m {
  width: 100%;
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
}

@media (min-width: 43em) {
  .checkout_checkoutSidePanel__xJx4m {
    width: 40%;
    margin-top: 0em;
    margin-bottom: 0em;
    max-height: 100%
  }
}

@media (min-width: 62em) {
  .checkout_checkoutSidePanel__xJx4m {
    width: 30%;
  }
}

.checkout_checkoutPayCon__LONy5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.checkout_checkoutOptsCon__MrKKj{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

@media (min-width: 62em) {
  .checkout_checkoutOptsCon__MrKKj {
     display: flex;
     flex-direction: row;
     align-items: center !important;
     justify-content: flex-start !important;
     grid-gap: 1em;
     gap: 1em;
     flex-wrap: nowrap;
  }

  .checkout_checkoutPayCon__LONy5{
    flex-direction: row;
    margin-bottom: 2.8em !important;
  }


}

.checkout_checkoutOptsBackArrow__3AA7G {
  font-size: 2rem !important;
}

button.checkout_checkoutOptsBtn__TdOkB {
  width: 80% !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

button.checkout_checkoutOptsTopBtn__1l4Lj {
  width: 80% !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

button.checkout_saveAsStandingOrder__lw4NB{
  width: 80% !important;
  margin-bottom: 1em;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  button.checkout_checkoutOptsBtn__TdOkB {
    width: inherit !important;
    margin-left: 2em;
  }

  button.checkout_checkoutOptsTopBtn__1l4Lj {
    width: 49% !important;
  }

  button.checkout_checkoutOptsTopBtn__1l4Lj:last-child{
    position: absolute;
  }

  button.checkout_checkoutOptsBtn__TdOkB:last-child {
    position: absolute;
    right: 0 !important;
  }

  button.checkout_saveAsStandingOrder__lw4NB{
    width: inherit !important;
    margin-left: 2em;
    margin-bottom: 2em !important;
    margin-top: 2em !important;
  }
}


.styles_prodInfo__2b2Jj {
  padding-left: 1em;
  padding-right: 1em;
}

@media (min-width: 62em) {
  .styles_prodInfo__2b2Jj {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_prodInfo__2b2Jj {
  text-align: left;
}

.styles_backNavCon__3za_O {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2.5em;
  margin-bottom: 1em;
}

.styles_backNavArrow__F4bRc {
  color: var(--color-primary);
  font-size: 2rem;
  cursor: pointer;
}

.styles_backNavText__2Ewp- {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding-right: 0.5em;
  cursor: pointer;
}

@media (min-width: 62em) {
  .styles_backNavText__2Ewp- {
    font-size: 1.6rem !important;
  }
}

.styles_backNavBorder__3eRX4 {
  flex: 1 1;
  border-bottom: 1px solid #9A9A9A;
  height: 1em;
}

.styles_infoCon__VfIEO {
  display: block;
}

@media (min-width: 62em) {
  .styles_infoCon__VfIEO {
    display: flex;
    margin-top: 3em;
  }
}

.styles_carouselCon__1hJPU {
  width: 100%;
}

@media (min-width: 62em) {
  .styles_carouselCon__1hJPU {
    width: 40%;
  }
}

.styles_carousel__tGJak {
  width: 100%;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .styles_carousel__tGJak {
    margin-bottom: 1em;
  }
}

.styles_carouselImgCon__3IwBJ {
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: calc(100vw - 2em);
  background: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .styles_carouselImgCon__3IwBJ {
    height: 35em;
  }
}

.styles_carouselImg__1FgbQ {
  width: auto !important;
  height: auto !important;
  max-width: 70%;
  max-height: 70%;
}

.styles_carouselExtraImages__1uhP2 {
  display: flex;
}

@media (min-width: 62em) {
  .styles_carouselExtraImages__1uhP2 {
    display: flex;
  }
}

.styles_carouselImgOtherCon__2MNrZ {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  background: var(--color-background-secondary);
  width: 7em;
  height: 7em;
  margin-right: 1em;
  cursor: pointer;
}

.styles_carouselImgOtherConActive__3VY_V {
  border: 1px #000 solid;
}

.styles_carouselImgOther__3rVsj {
  width: auto !important;
  height: auto !important;
  max-width: 70%;
  max-height: 70%;
}

/*
  .productInfoCon {
  }
*/

@media (min-width: 62em) {
  .styles_productInfoCon__1vUun {
    width: 60%;
    padding-left: 4em;
    padding-right: 4em;
  }
}

.styles_productSku__3e169 {
  color: #343433 !important;
  font-size: 1.1rem !important;
  font-weight: 600;
  text-align: left;
}

@media (min-width: 62em) {
  .styles_productSku__3e169 {
    font-size: 1.6rem !important;
  }
}

.styles_productName__1jxHJ {
  color: #343433 !important;
  font-size: 2.4rem !important;
  font-weight: 600;
  text-align: left;
  margin-top: 0em !important;
}

@media (min-width: 62em) {
  .styles_productName__1jxHJ {
    margin-top: 0.2em !important;
    font-size: 4.6rem !important;
    line-height: 1em !important;
  }
}

ul.styles_productAttributesCon__2cLXF {
  padding: 1em 1em 0.5em 1em;
}

@media (min-width: 62em) {
  ul.styles_productAttributesCon__2cLXF {
    padding: 1em 0em 0.5em 0em;
  }
}

ul.styles_productAttributesCon__2cLXF li {
  color: #343433 !important;
  font-size: 1.4rem !important;
  padding-bottom: 0.5em;
}

@media (min-width: 62em) {
  ul.styles_productAttributesCon__2cLXF > li {
    font-size: 1.4rem !important;
    min-width: 50%;
    display: inline-block;
  }

  ul.styles_productAttributesCon__2cLXF > li::before{
    content: '\2022';
    margin-right: 1em;
    font-size: 1.4rem !important;
  }
}

button.styles_productAttributesReadMore__1ay4W {
  padding: 0em;
  height: auto;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  button.styles_productAttributesReadMore__1ay4W {
    display: none;
  }

  ul.styles_productAttributesCon__2cLXF > li::before{
    content: '\2022';
    margin-right: 1em;
    font-size: 1.4rem !important;
  }
}

button.styles_productAttributesReadMore__1ay4W * {
  color: var(--color-primary);
  font-size: 1.4rem !important;
  font-weight: 600;
}

.styles_productSelectCon__3B33Z {
  flex-wrap: wrap;
  display: flex;
  margin-top: -1em;
  margin-bottom: 2em;
}

.styles_productSelectItemCon__1Vf6I {
  flex: 1 1;
  margin-top: 1em;
  margin-right: 1em;
  cursor: pointer;
}

@media (max-width: 43em) {
  .styles_productSelectItemCon__1Vf6I {
  }
}

.styles_productSelectItemName__VGZ2w {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: #343433;
  font-size: 1.4rem !important;
  font-weight: 600;
}

.styles_productSelectItemValueCon__3m5Ef {
  display: flex;
  align-items: center;
}

.styles_productSelectItemValue__v_6zA {
  color: #343433;
  font-size: 1.4rem !important;
}

.styles_backOrderText__1V5sI {
  font-size: 1.4rem !important;
  color: red !important;
  margin-bottom: 2em;
}

.styles_deliveryTimeText__3d37s {
  font-size: 1.4rem !important;
  margin-bottom: 2em;
}

.styles_quantityHeader__3cQlw {
  font-size: 1.4rem !important;
  font-weight: 600;
}

.styles_priceCon__188sw {
  margin: 2em 0em;
}

.styles_priceText__2kWbn {
  font-size: 1.8rem !important;
}

.styles_priceText2__2lCmy {
  font-size: 1.4rem !important;
}

.styles_quantityCon__2NrEa {
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .styles_quantityCon__2NrEa {
    display: flex;
    align-items: center;
    padding-top: 1em;
  }
}

.styles_cartCountCon__13FDK {
  display: flex;
  margin-top: 0.5em !important;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .styles_cartCountCon__13FDK {
    margin: 0em 4em 0em 0em !important;
  }
}

button.styles_addToCartBtn__n9YpT {
  padding-left: 4em;
  padding-right: 4em;
}

.styles_accountHolderNotice__2VwUe {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 1em;
  border-top: 1px solid #343433;
}

.styles_accountHolderNoticeHeader__Rq9lw {
  color: var(--color-primary) !important;
  font-size: 1.4rem !important;
  font-weight: 600;
  margin-top: 1em;
}

.styles_accountHolderNoticeText__1xwzQ {
  font-size: 1.1rem !important;
  color: #343433;
  margin-top: 1em;
}

/*
  OTHER DETAILS
*/

.styles_detailsCon__3sFMI {
  display: block;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .styles_detailsCon__3sFMI {
    display: none;
  }
}

.styles_detailsConTab__3P934 {
  display: none !important;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.styles_detailsGuideCon__3rRyG {
  display: block !important;
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

@media (min-width: 62em) {
  .styles_detailsConTab__3P934 {
    display: block !important;
  }
}

.styles_detailsHeader__26qkX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #9A9A9A;
  margin-top: 1em;
  margin-bottom: 1em;
}

.styles_detailsHeaderText__3rP_N {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.styles_detailsHeaderTextActive__2LNnS {
  padding-bottom: 0em;
  border-bottom: 0.6rem solid var(--color-primary);
}

.styles_detailsHeaderGuide__19ny8 {
  font-size: 1.4rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.styles_detailsHeaderGuideActive__2y9ci {
  padding-bottom: 0em;
  border-bottom: 0.6rem solid var(--color-primary);
}

.styles_detailsHeaderIcon__2aPv9 {
  color: #343433;
  font-size: 2.0rem;
}


@media (min-width: 62em) {
  .styles_detailsHeaderIcon__2aPv9 {
    display: none !important;
  }
}

/* .detailsDescCon {

} */

.styles_detailsDescTab__l2gt9{
  margin-left: 0.7em;
}

.styles_detailsDescHtmlContent__xSJrC img {
  max-width: 100% !important;
}

.styles_detailsDescText__1pD5j {
  font-size: 1.4rem !important;
  margin-top: 1em !important;
}

.styles_detailsDescSubHeader__3gBx3 {
  display: block;
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-top: 1em;
}

.styles_detailsDescItem__V6Oo4 {
  font-size: 1.4rem !important;
  display: block;
}

.styles_guidelinesTabCon__3HKYF {
  display: flex;
  flex-direction: column;
}

.styles_emptyList__NWFY3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 0em;
}

/*
  GUIDELINES TAB
*/

.styles_guidelinesIconCon__2Ol52 {
  display: flex;
  flex-wrap: wrap;
}

.styles_pdfIcon__1nQ1f {
  font-size: 4.4rem;
}

/*
  CHECKOUT MODAL
*/

.styles_checkoutModal__1xYKO {
  width: 46em !important;
}

.styles_checkoutModalBody__12APx {
  display: flex;
  justify-content: space-between;
}

.styles_checkoutModalDetails__x55Qz {
  width: calc(50% - 2em);
  display: flex;
  flex-direction: column;
  justify-content:  space-between;
}

.styles_checkoutModalDetailsHeader__gk9Jx {
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  margin-bottom: 1.5em;
}

.styles_checkoutModalInfo__1_y0i {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  margin-bottom: 1.5em;
}

.styles_checkoutModalInfo__1_y0i:nth-child(3) {
  margin-bottom: 0em;
}

.styles_checkoutModalImgCon__2Wtzt {
  width: calc(50% - 2em);
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 12em;
  background: var(--color-background-secondary);
}

/* Complimentary products */

.styles_complimentaryHeader__2myd2 {
  font-size: 1.8rem !important;
  font-weight: 600;
}

.styles_filterResultCardCon__UvWuC {
  text-align: left;
}

.styles_filterResultCardCon__UvWuC {
  text-align: left;
}

.styles_filterResultCard__1VS4t {
  display: inline-block;
  width: calc(50% - 0.5em) !important;
  margin-bottom: 1em;
}

@media (min-width: 82em) {
  .styles_filterResultCard__1VS4t {
    width: calc(24.33% - 1em) !important;
  }
}

.styles_filterResultCard__1VS4t:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 82em) {
  .styles_filterResultCard__1VS4t:nth-child(2n) {
    margin-left: 0em;
  }

  .styles_filterResultCard__1VS4t {
    margin-right: 1em;
  }
}

/* Rating  */

/* .cardsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: auto;
} */

.styles_cardsContainer__2rSgT {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  height: 300px; /* Set a fixed height for vertical scrolling */
}

 @media (min-width: 62em) {
  .styles_cardsContainer__2rSgT {
    display: flex;
    width: calc(50.33% - 0em) !important;
    align-items: center !important;
    overflow-x: auto;
    white-space: nowrap !important;
  }
}

button.styles_historyBtn__2WYwa,
button.styles_viewMoreBtn__3mKT7 {
  height: 2em;
  width: 6em;
}

button.styles_historyBtn__2WYwa * ,
button.styles_viewMoreBtn__3mKT7 * {
  font-size: 1.1rem !important;
}

button.styles_viewMoreBtn__3mKT7 {
  position: absolute;
  bottom: 0em;
  right: 1em;
}

/* Wishlist button  */
.styles_wishlistButton__1XOqA {
  border: 1px solid #343433;
  padding: 1em !important;
  display: flex;
  margin-left: 0.5em !important;
  width: 4.6em !important;
}

.styles_wishlistButtonIcon__HxBiS {
  font-size: 1.2rem !important;
}

button.styles_wishlistButton__1XOqA{
  padding-left: 2em !important;
  padding-right: 2em !important;
  border: 1px solid #343433;
  /* background-color: transparent; */
}


/* LABEL */

.styles_onHoldLabel__MxzTD {
  position: relative;
  background-color: rgba(143, 137, 137, 0.7); /* semi-transparent black */
  color: white;
  padding: 5px 10px;
  z-index: 1;
  margin-bottom: 0.1em;
}

.styles_labelContainer__2Kx9k{
  position: relative;
}

.styles_labelHold__-iYaO{
  position: absolute;
}

.styles_filterBreadCrumb__2iXBh {
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1em;
  width: 100%;
  flex-wrap: wrap;
}

.styles_filterBreadCrumbItem__CTDGd {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.styles_filterBreadCrumbText__1vbc- {
  font-size: 1.4rem !important;
  white-space: nowrap;
}

.styles_filterBreadCrumbText__1vbc-:hover {
  color: #d8232a;
}

.styles_chevronIcon__3BRkr
{
  color: #d8232a;
}

/* AverageRatingCard.module.css */

.average-rating_noReviews__1RGly {
  font-size: 16px; 
  text-align: center; 
}


.average-rating_averageRatingCard__1XyTU {
  width: 90%;
  height: 200px;
}

@media (min-width: 43em) {
  .average-rating_averageRatingCard__1XyTU {
    width: 70%;
  }
}

@media (min-width: 62em) {
  .average-rating_averageRatingCard__1XyTU {
    width: 50%;
  }
}

@media (min-width: 82em) {
  .average-rating_averageRatingCard__1XyTU {
    width: 300px;
  }
}

.average-rating_ratingContainer__17gfA {
  display: flex;
  align-items: center;
}

.average-rating_rating__3J1uy {
  margin-right: 10px !important;
}

.average-rating_ratingValue__Y6opT {
  font-size: 24px !important;  /* Adjust this value to make the rating value larger */
  font-weight: bold;
}


button.average-rating_historyBtn__3qEX_,
button.average-rating_reorderBtn__39kh0 {
  height: 2em;
  width: 6em;
}

button.average-rating_historyBtn__3qEX_ * ,
button.average-rating_reorderBtn__39kh0 * {
  font-size: 1.1rem !important;
}

button.average-rating_reorderBtn__39kh0 {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

/* ReviewCard.module.css */

.ReviewCard_container__w7dXe {
  width: 90%;
  margin: 20px auto;
}

@media (min-width: 43em) {
  .ReviewCard_container__w7dXe {
    width: 70%;
  }
}

@media (min-width: 62em) {
  .ReviewCard_container__w7dXe {
    width: 50%;
  }
}

@media (min-width: 82em) {
  .ReviewCard_container__w7dXe {
    width: 300px;
  }
}

.ReviewCard_reviewCard__3n2Ah {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ReviewCard_infoContainer__2GwNv {
  display: flex;
  flex-direction: column;
}

.ReviewCard_date__25n0D {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
}

.ReviewCard_comment__2w0RO {
  margin-top: 10px;
}

button.ReviewCard_viewMoreBtn__pdU9o,
button.ReviewCard_historyBtn__uctFR,
button.ReviewCard_reorderBtn__-N88n {
  height: 2em;
  width: 6em;
  background-color: transparent; /* Remove background color */
  border: none; /* Remove border */
  color: inherit ; 
}

button.ReviewCard_viewMoreBtn__pdU9o{
  top: 0.10em;
  bottom: 1em;
  right: 1em;

}

button.ReviewCard_viewMoreBtn__pdU9o *,
button.ReviewCard_historyBtn__uctFR * ,
button.ReviewCard_reorderBtn__-N88n * { 
  font-size: 1.1rem !important;
}

button.ReviewCard_reorderBtn__-N88n {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.ReviewCard_comment__2w0RO{
   font-size: 1.4rem !important;
   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
   
}

.ReviewCard_date__25n0D{
    font-size: 1.2rem !important;
}








.styles_loginFormSocialButtons__3p_qn {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 5rem;
}

@media (min-width: 82em) {
  .styles_loginFormSocialButtons__3p_qn {
    flex-direction: row;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
  }
}


.styles_loginOR__gazdf {
  display: none;
  font-size: 1.4rem !important;
  justify-content: center;
}

@media (min-width: 82em) {
  .styles_loginOR__gazdf {
    font-size: 1.6rem !important;
    display: block;
    width: auto;
  }
}





.styles_loginCon__3FSUU {
  height: auto;
  padding: 2em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .styles_loginCon__3FSUU {
    padding: 0em 0em;
    display: flex;
    flex-direction: row-reverse;
    min-height: calc(100vh - 6em);
  }
}

.styles_loginFormCon__3DVsR {
  display: block;
  width: 100%;
  padding: 0em;
}

@media (min-width: 62em) {
  .styles_loginFormCon__3DVsR {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60% !important;
    padding-left: 8em;;
  }
}

.styles_loginFormItemCon___1LUB {
  margin-top: 1em;
}

@media (min-width: 62em) {
  .styles_loginFormItemCon___1LUB {
    width: 70%;
  }
}

.styles_loginFormTitle__tw4-k {
  font-size: 2.6rem !important;
  font-weight: 600 !important;
  margin-top: 1em !important;
  margin-bottom: 2em !important;
}

@media (min-width: 62em) {
  .styles_loginFormTitle__tw4-k {
    font-size: 5.4rem !important;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }
}

.styles_loginFormItemLabel__2Xin0 {
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .styles_loginFormItemLabel__2Xin0 {
    font-size: 1.6rem !important;
  }
}

.styles_loginFormRememberRadio__DaXCE {
  font-size: 1.1rem !important;
}

@media (min-width: 62em) {
  .styles_loginFormRememberRadio__DaXCE {
    font-size: 1.4rem !important;
  }
}

.styles_loginFormOptsCon__12h7S {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .styles_loginFormOptsCon__12h7S {
    width: 70%;
  }
}

button.styles_loginFormResetBtn__2eeFn,
button.styles_loginFormSignUpBtn__3iTYq {
  height: auto !important;
  padding: 0em !important;
}

button.styles_loginFormResetBtn__2eeFn *,
button.styles_loginFormSignUpBtn__3iTYq * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  color: var(--color-primary);
}

button.styles_loginFormSubmitBtn__k3yh3 {
  margin-top: 2em;
  margin-bottom: 1em;
}

button.styles_loginFormSignUpLargeBtn__2M0UW {
  margin-top: 2em;
  margin-bottom: 1em;
  margin-left: 1em;
}

.styles_loginFormSignUpTextCon__2nHih {
  margin-bottom: 2em;
}

.styles_loginFormSignUpText__19kgb {
  font-size: 1.4rem !important;
}

/*
.loginFormSignUpBtn {
}
*/

.styles_loginFormBanner__3bAx- {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
  margin-top: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 70%;
  height: 8.5em;
}

@media (min-width: 62em) {
  .styles_loginFormBanner__3bAx- {
    flex: 1 1;
    align-items: flex-end;
    margin-top: 0em;
    height: inherit;
    padding: 0em 2em 4em 0em;
    justify-content: flex-end;
  }
}

.styles_loginFormBannerText__2lefM {
  font-size: 2.8rem !important;
  font-weight: 800;
  color: var(--color-text-in-secondary) !important;
}

@media (min-width: 62em) {
  .styles_loginFormBannerText__2lefM {
    font-size: 8.6rem !important;
    line-height: 1em !important;
  }
}

.styles_buttonContainer__s1us3 {
  display: flex;
  justify-content: space-between;
  margin-top: 16px !important;
}

.styles_tabCon__2bWXW {
  padding: 1em 1em;
  background-color: #f7f7f7;
}

.styles_detailsHeaderText__5zlmf {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
  margin-bottom: 0em !important;
}

.styles_registerHeader__MCc70 {
  align-self: center;
  color: #343433 !important;
  font: Poppins, bold;
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}
.styles_registerRadioGrp__28PJg {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
}

.styles_registerRadioGrp__28PJg * {
  font-size: 1.4rem !important;
  font-weight: 500;
}

@media (min-width: 62em) {
  .styles_registerHeader__MCc70 {
    font-size: 2.8rem !important;
  }
}

@media (min-width: 62em) {
  .styles_tabCon__2bWXW {
    padding: 1em 7em 4em 7em;
    background-color: #f7f7f7;
  }
}

.styles_detailsHeaderText__5zlmf {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.styles_contactDetailsCon__zTR6z {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
}

@media (min-width: 43em) {
  .styles_contactDetailsCon__zTR6z {
    width: 40em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.styles_contactDetailsCon__zTR6z hr {
  width: 0.15em;
  height: 2em;
  background-color: var(--color-text-in-secondary);
  border: none;
}

button.styles_phoneBtn__2Yuz8 {
  flex: 1 1;
  flex-direction: row-reverse !important;
  width: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.styles_phoneBtnIcon__ARUnE {
  margin-right: 0.5em;
  font-size: 1.6rem !important;
}

/* 
  DETAILS
*/

.styles_detailsCon__jNc5X {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .styles_detailsCon__jNc5X {
    width: 70%;
  }
}

.styles_detailsText__2woEV {
  font-size: 1.6rem !important;
  font-weight: 600;
}

@media (min-width: 62em) {
  .styles_registerFormCon__30-WV {
    margin-bottom: 0em;
  }
}

.styles_registerFormCon__30-WV {
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_registerFormCon__30-WV {
    margin-bottom: 0em;
    width: calc(50% - 0.5em);
    margin-right: 1em;
  }

  .styles_registerFormConInput__mTkaV {
    display: inline-block;
  }

  .styles_registerFormConInput__mTkaV:nth-child(2n) {
    margin-right: 0em;
  }

  .styles_registerFormConTextArea__3ZhXy {
    width: 100%;
  }
}

.styles_registerLabel__F3TR0 {
  font-size: 1.125rem !important;
}
.styles_registerredSmall__3NC9- {
  color: #d8232a !important;
  font-size: 1.2rem !important;
}

.styles_registerLabelred__2-iPu {
  color: #d8232a !important;
  font-size: 1.4rem !important;
}

.styles_registerLabelredBold__3uxC6 {
  color: #d8232a !important;
  font-size: 1.4rem !important;
  font: Poppins, medium;
}
.styles_registerLabelblack__1uWxg {
  color: #343433;
  font-size: 1.4rem !important;
}

.styles_registerFormConCommsCon__2MZ-Z {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.styles_registerFormConComms__2CDP5 {
  font-size: 1.4rem !important;
  font-weight: 500;
}

.styles_select__2gd2z {
  margin-top: 1em !important;
  width: 100%;
  height: 3em !important;
  background: none !important;
  display: block !important;
}

.styles_select__2gd2z * {
  text-align: left;
  height: 100% !important;
  background: none !important;
}

.styles_select__2gd2z span {
  display: flex;
  align-items: center;
}

@media (min-width: 62em) {
  .styles_select__2gd2z {
    width: 60% !important;
  }
}

.styles_inputBackground__1VThc{
  background: white !important;
}
/*
  TITLE
*/

.styles_products__1XuVb {
  padding: 1em;
}

@media (min-width: 62em) {
  .styles_products__1XuVb {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_titleCon__QAz_N {
  display: flex;
  margin-top: 2.0em;
  margin-left: 2.1em;
  margin-bottom: 1.5em;
}

.styles_titleText__6vGX_ {

  font-size: 2.4rem !important;
  font-weight: 600 !important;
  color:#535353;
  font: Poppins;
}

@media (min-width: 62em) {
  .styles_titleText__6vGX_ {
    font-size: 2.4rem !important;
    font-weight: 600 !important;
    color:#535353;
    font: Poppins;
    margin-left: 1em;
  }
}

/*
  FILTERS
*/

.styles_filtersCon__2hdt5 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_filtersCon__2hdt5 {
    justify-content: flex-end;
  }
}

.styles_filtersBtn__3GBzF {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

@media (min-width: 62em) {
  .styles_filtersBtn__3GBzF {
    display: none !important;
  }
}

.styles_filtersOptCon__1-gC5 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.styles_filterSearchIcon__2nF0f {
  padding: 0em 1em;
  font-size: 2.2rem;
  color: #656565
}

@media (min-width: 62em) {
  .styles_filterSearchIcon__2nF0f {
    display: none !important;
  }
}

.styles_filterSearchInputCon__2y-m3 {
  display: none;
  width: 16em;
  margin-right: 1em;
  height: 100%;
}

@media (min-width: 62em) {
  .styles_filterSearchInputCon__2y-m3 {
    display: flex;
    align-items: flex-end;
  }
}

.styles_filterSearchInput__3syVN {
  height: 100% !important;
}

.styles_filterSelect__1jJjf {
  max-width: 40vw !important
}

@media (min-width: 62em) {
  .styles_filterSelect__1jJjf {
    width: 16em !important
  }
}


/*
  FILTER
*/

.styles_filterMenuResultsCon__2WFBJ {
  display: flex;
  margin-top: 2em !important;
  text-align: center;
}

/*
  FILTER MENU
*/

.styles_filterMenuCon__2Ihxf {
  display: none;
  width: 30%;
  text-align: left;
  padding-right: 2em;
  border-right: 1px solid #9A9A9A;
  margin-right: 4em;
}

@media (min-width: 62em) {
  .styles_filterMenuCon__2Ihxf {
    display: flex;
    flex-direction: column;
  }
}

.styles_filterMenuHeader__2R0Zk {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 0.7em;
}

.styles_filterMenuHeader__2R0Zk.styles_filterMenuHeaderFilterBy__3uuVI {
  margin-top: 1.5em;
  margin-bottom: 0em;
}

.styles_filterMenuSubHeader__1dSEh,
.styles_filterMenuFilterBySubHeader__1mY3j {
  font-size: 1.6rem !important;
  font-weight: 500;
  color: #656565 !important;
  margin-bottom: 0.7em;
}

.styles_filterMenuFilterBySubHeader__1mY3j {
  margin-bottom: 0em;
  margin-top: 0.7em;
}

.styles_filterMenuSubHeaderItems__2C8-h,
.styles_filterMenuFilterByItems__1LSSO {
  margin-left: 1.5em;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.4rem !important;
  cursor: pointer;
}

.styles_filterMenuFilterByItems__1LSSO {
  color: #9A9A9A !important;
  margin-left: 0em !important;
  font-weight: 400 !important;
}


.styles_filterMenuColorIcon__kjTPP {
  font-size: 1.6rem;
  margin-right: 0.5em;
  border: 1px solid #9A9A9A;
  border-radius: 50%;
  cursor: pointer;
}


/*
  RESULTS
*/

.styles_filterResultsTextCon__32oU1 {
  flex: 1 1;
  margin-bottom: 1em !important;
  text-align: left;
}

.styles_filterResultsText__tykwB {
  font-size: 1.2rem !important;
  font: Poppins;

}

.styles_filterResultCardCon__3M39n {
  text-align: center;
}


.styles_filterResultCard__3m0Dg {
  display: inline-block;
  width: calc(90% - 0.5em) !important;
  margin-left: 1.5em;
  box-shadow: 0 4px 8px 0 #00000019;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .styles_filterResultCard__3m0Dg {
    width: calc(21% - 1em) !important;
    margin-left: 5em;
  }
}

.styles_filterResultCard__3m0Dg:nth-child(2n) {
  margin-left: 1.5em;
  margin-bottom: 2em;
}

@media (min-width: 62em) {
  .styles_filterResultCard__3m0Dg:nth-child(2n) {
    margin-left: 3.5em;
    margin-bottom: 2em;
  }

  .styles_filterResultCard__3m0Dg {
    margin-left: 3.5em;
  }
}

/* .filterResultCard:nth-child(3n) {
  margin-left: 1em;
} */

.styles_filterResultPName__2JBFw {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
}

@media (min-width: 43em) {
  .styles_filterResultPName__2JBFw {
    font-size: 1.3rem !important;
    font:Poppins
  }
}

.styles_filterResultImg__LpmaV {
  height: 25em !important;
}

@media (min-width: 62em) {
  .styles_filterResultImg__LpmaV {
    height: 20em !important;
  }
}

/*
  MORE
*/

.styles_moreTextCon__2nAmG {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

@media (min-width: 62em) {
  .styles_moreTextCon__2nAmG {
    display: none;
  }
}

button.styles_moreTextBtn__2NKBn {
  color: #656565;
  border: none !important;
  box-shadow: none;
}


.styles_pdfHeader__3t28Q {
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: center;
}

@media (min-width: 82em) {
  .styles_pdfHeader__3t28Q{
    font-size: 2.5rem !important;
    text-align: left;
    margin-bottom: 0em !important;
  }
}

.styles_aboutUsText__NZEH9 {
  font-size: 1.4rem !important;
  font-weight: 400 !important;
  text-align: center;
}

@media (min-width: 82em) {
  .styles_aboutUsText__NZEH9 {
    font-size: 1.6rem !important;
    text-align: left;
  }
}


/*
  PDF
*/

.styles_pdfShowcaseCon__1NkTz {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .styles_pdfShowcaseCon__1NkTz {
    padding: 6em 7em;
  }
}

.styles_productOfferText__1g6R4 {
  font-size: 1.6rem !important;
  text-align: left;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_productOfferText__1g6R4 {
    width: 50%;
  }
}

.styles_pdfCon__1cjCb {
  margin-top: 4em;
}

.styles_pdItem__1PKqU {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFE 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000019;
  padding: 0em 1em;
  width: calc(50% - 0.5em);
  margin-bottom: 1em;
  min-height: 15em;
}

@media (min-width: 62em) {
  .styles_pdItem__1PKqU {
    width: calc(20% - 2em);
    margin-right: 2.5em;
    margin-bottom: 2.5em;
    min-height: 20em;
  }

  .styles_pdItem__1PKqU:nth-child(5n) {
    margin-right: 0em;
  }
}

.styles_pdItem__1PKqU:nth-child(2n) {
  margin-left: 1em;
}

@media (min-width: 62em) {
  .styles_pdItem__1PKqU:nth-child(2n) {
    margin-left: 0em;
  }
}

.styles_pdItemIcon__esplI {
  font-size: 5rem;
  padding: 1em 25% 0.5em 25%;
  border-bottom: 0.1em solid var(--color-primary);
}

@media (min-width: 62em) {
  .styles_pdItemIcon__esplI {
    font-size: 7rem;
    border-bottom: 0.05em solid var(--color-primary);
  }
}

.styles_pdItemName__30lgD {
  font-size: 1.2rem !important;
  font-weight: 600;
  text-align: center !important;
  height: 4rem !important;
  padding: 1em 0em !important;
}

@media (min-width: 62em) {
  .styles_pdItemName__30lgD {
    padding: 2em 0em !important;
    font-size: 1.4rem !important;
  }
}



/*
  DELIVERIES
*/

.styles_deliveriesCon__PIHsg {
  display: flex;
  background-color: var(--color-background-primary);
  justify-content: center;
  flex-direction: column;

}

@media (min-width: 82em) {
  .styles_deliveriesCon__PIHsg {
    height: 45em;
    margin-bottom: 100px;
  }
}

/*
  POPULAR PRODUCTS
*/

.styles_popularProdContainer__36fKh {
  padding-left: 1em;
  padding-right: 1em;
}

@media (min-width: 43em) {
  .styles_popularProdContainer__36fKh {
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdContainer__36fKh {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_popularProdScroll__1hZS9 {
  overflow-x: auto;
  margin-bottom: 1em;
  white-space: nowrap;
}

@media (min-width: 62em) {
  .styles_popularProdScroll__1hZS9 {
    white-space: normal;
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdScroll__1hZS9 {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_popularProdItem__iHG7h {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 26.5em;
  margin-top: 1.5em;
  padding: 1em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 43em) {
  .styles_popularProdItem__iHG7h {
    width: calc(50% - 1em);
    display: flex;
    height: 30em;
    padding: 2em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdItem__iHG7h {
    height: 40em;
  }
}

.styles_popularProdScroll__1hZS9 .styles_popularProdItem__iHG7h {
  display: inline-flex;
  width: 75vw !important;
  height: 75vw;
  margin-left: 1em;
}

@media (min-width: 43em) {
  .styles_popularProdScroll__1hZS9 .styles_popularProdItem__iHG7h{
    width: calc(45% - 1em) !important;
    height: 26em;
  }
}

@media (min-width: 62em) {
  .styles_popularProdScroll__1hZS9 .styles_popularProdItem__iHG7h{
    width: calc(33.33% - 1em) !important;
    margin-left: 0em;
  }
}

.styles_popularProdTitle__Jj0L8 {
  text-transform: uppercase;
  font-size: 4.2rem !important;
  font-weight: 800;
  text-align: left;
  line-height: 1em;
  color: var(--color-text-in-secondary) !important;
}

@media (min-width: 43em) {
  .styles_popularProdTitle__Jj0L8 {
    font-size: 5rem !important;
  }
}

.styles_popularProdScroll__1hZS9 .styles_popularProdTitle__Jj0L8  {
  font-size: 1.8rem !important;
}

.styles_popularProdCategoryContainer__1clM4 {
  text-align: left;
  margin-top: 1em;
  margin-bottom: 1em;
}

.styles_popularProdCategory__2nZ_3 {
  font-size: 1.2rem !important;
  text-align: left;
  color: var(--color-text-in-secondary) !important
}

@media (min-width: 43em) {
  .styles_popularProdCategory__2nZ_3 {
    font-size: 1.4rem !important;
  }
}

.styles_popularProdBtnContainer__2ecP0 {
  float: right;
}

.styles_popularProdBtn__12ffY,
.styles_latestSpecialsBtn__3T5kj,
.styles_otherOfferItemBtn__2maBv,
.styles_recentNewsBtn__2Tq3x {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.styles_popularProdBtn__12ffY *,
.styles_latestSpecialsBtn__3T5kj *,
.styles_otherOfferItemBtn__2maBv * ,
.styles_recentNewsBtn__2Tq3x *  {
  font-weight: 500 !important;
}

/*
  SHOP HEADER
*/

.styles_shopHeaderContainer__pcIXI {
  margin-top: 3.8em;
  margin-bottom: 0em;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 62em) {
  .styles_shopHeaderContainer__pcIXI {
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_shopHeader__Gljxh {
  font-size: 2.2rem !important;
  font-weight: 500 !important;
  margin: 0 !important;
}

@media (min-width: 43em) {
  .styles_shopHeader__Gljxh {
    font-size: 2.6rem !important;
  }
}

.styles_shopHeaderBtn__3vrHl {
  height: 3em !important;
  padding-left: 2em !important;
  padding-right: 2em !important;
  border: 2px solid var(--color-secondary) !important;
}

.styles_shopHeaderBtn__3vrHl *  {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}

/*
  CAROUSEL
*/

.styles_carouselItem__3-WQ7 {
  height: 80vh;
}

.styles_carouselSubText__2WQc7 {
  color: var(--color-text-in-secondary) !important;
  text-align: left !important;
  font-size: 1.4rem !important;
  display: flex;
  margin-bottom: 0.1em;
  color: #FFFFFE;
}

@media (min-width: 62em) {
  .styles_carouselSubText__2WQc7 {
    font-size: 1.6rem !important;
    color: #FFFFFE;
  }
}



.styles_tabCon__2Uhui {
  padding: 1em 1em;
  background-color: #FFFFFF;
  position: relative;
}

.styles_historyHeader__3m0zR {
  align-self: center;
  color: #343433 !important;
  font: Poppins,bold;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}
@media (min-width: 62em) {
  .styles_historyHeader__3m0zR {
    font-size: 5rem !important;
  }
}
.styles_historyYears__1JCs-{
  align-self: center;
  color: #000000;
  font: Poppins,bold;
  font-size: 6.0rem !important;
  font-weight: 800 !important;
  margin-bottom: 0em !important;
}
@media (min-width: 62em) {
  .styles_historyHeader__3m0zR {
    font-size: 2.8rem !important;
  }
}

.styles_historyConRight__2ifM3 {
  display: flex;
  flex-direction: column;
  padding: 1em;
  position: relative;
  right: 1em;
}

@media (min-width: 43em) {
  .styles_historyConRight__2ifM3 {
    flex-direction: row;
    height: 40em;
  }
}
@media (min-width: 62em) {
  .styles_historyConRight__2ifM3 {
    height: 40em;
    padding-left: 7em;
    padding-right: 7em;
  }
}
.styles_historyConLeft__1sb1M {
  display: flex;
  flex-direction: column;
  padding: 1em;
  position: relative;
  left:1em
}


@media (min-width: 43em) {
  .styles_historyConLeft__1sb1M {
    flex-direction: row-reverse;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .styles_historyCon__3hars {
    height: 40em;
    padding-left: 7em;
    padding-right: 7em;
  }
}

.styles_otherOfferItemBgRight__1bSTl {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
  position: relative;
  right: 1em;
}

@media (min-width: 43em) {
  .styles_otherOfferItemBgRight__1bSTl {
    width: 55%;
    height: 100%;
  }
}

@media (min-width: 62em) {
  .styles_otherOfferItemBgRight__1bSTl {
    width: 55%;
  }
}


.styles_otherOfferItemBgLeft__19tNV {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
  position: relative;
  left:1em;
}

@media (min-width: 43em) {
  .styles_otherOfferItemBgLeft__19tNV {
    width: 58%;
    height: 100%;
  }
}

@media (min-width: 62em) {
  .styles_otherOfferItemBgLeft__19tNV {
    width: 58%;
  }
}
.styles_historyItemConRight__1ccRp {
  padding: 3em 2em;
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  right: 1em;
}

@media (min-width: 43em) {
  .styles_historyItemConRight__1ccRp {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;
  }
}

.styles_historyItemConLeft__3FscK {
  padding: 3em 2em;
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  left: 1em;
}
.styles_historyItemConLeftLast__272We {
  padding: 3em 2em;
  background-color: #FFFFFF;
  text-align: left;
  position: relative;
  left: 1em;
}
@media (min-width: 43em) {
  .styles_historyItemConLeft__3FscK {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;
  }
    .styles_historyItemConLeftLast__272We {
    flex: 1 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3em 3em;
    width: 50%;
    left: 50%;
    top: 13%;
  }

}


@media (min-width: 62em) {
  .styles_historyItemConLeftLast__272We {
    padding: 3em 2em;
    background-color: #FFFFFF;
    text-align: left;
    position: relative;
    left: 1em;
    width: 100%;
    top: 30%;
  }

}



.styles_otherOfferItemBg__3pRwD {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 0;
  height: 24em;
}

@media (min-width: 43em) {
  .styles_otherOfferItemBg__3pRwD {
    width: 30%;
    height: 100%;
  }
}

.styles_timeLineCon__2Xqb2{
  position: relative;
  top:10em
}

.styles_historyConRightFirst__2WZCu{
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 23em;
}

@media (min-width: 43em) {
  .styles_historyConRightFirst__2WZCu {
    flex-direction: row;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .styles_historyConRightFirst__2WZCu {
    height: 40em;
    padding-left: 7em;
    padding-right: 0em;
  }
}


.styles_historyConRightFirst__2WZCu::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 92px;
    margin-bottom: -1em;
}

.styles_historyConRightLast__1Pv6z{
  display: inline-block;
  position: relative;
  margin-bottom: 12em;
}

@media (min-width: 43em) {
  .styles_historyConRightLast__1Pv6z {
    flex-direction: row;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .styles_historyConRightLast__1Pv6z {
    height: 40em;
    padding-left: 50em;
    padding-right: 5em;
  }
}



.styles_historyConRightLast__1Pv6z::before{
  content: '';
  width: 1px;
  height: 50px;
  background: #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -2px;
  height: 159px;
  margin-bottom: -1em;
}
@media (min-width: 43em) {
    .styles_historyConRightLast__1Pv6z::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: -8.5%;
    left: 50%;
    margin-left: -2px;
    height: 57.5em;
    margin-bottom: -1em;
  } 
}
@media (min-width: 62em) {
  .styles_historyConRightLast__1Pv6z::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: -8.5%;
    left: 50%;
    margin-left: -2px;
    height: 57.5em;
    margin-bottom: -1em;
  }
}



.styles_historyConRight__2ifM3{
  display: inline-block;
  position: relative;
  margin-bottom: 12em;
}

@media (min-width: 43em) {
  .styles_historyConRight__2ifM3 {
    display: flex;
    flex-direction: row;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .styles_historyConRight__2ifM3 {
    height: 40em;
    padding-left: 2em;
    padding-right: 0em;
  }
}

.styles_historyConRight__2ifM3::before{
  content: '';
  width: 1px;
  height: 50px;
  background: #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -2px;
  height: 159px;
  margin-bottom: -1em;
}


.styles_historyConLeft__1sb1M{
  display: inline-block;
  position: relative;
  margin-bottom: 12em;
}
@media (min-width: 43em) {
  .styles_historyConLeft__1sb1M {
    display: flex;
    flex-direction: row-reverse;
    height: 40em;
  }
}

@media (min-width: 62em) {
  .styles_historyConLeft__1sb1M {
    height: 40em;
    padding-left: 7em;
    padding-right: 5em;
  }
}

.styles_historyConLeft__1sb1M::before{
  content: '';
  width: 1px;
  height: 50px;
  background: #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -2px;
  height: 159px;
  margin-bottom: -1em;
}
@media (min-width: 43em) {
   .styles_historyConLeft__1sb1M::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
     .styles_historyConRight__2ifM3::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
}
@media (min-width: 62em) {
    .styles_historyConLeft__1sb1M::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
    .styles_historyConRight__2ifM3::before{
    content: '';
    width: 1px;
    height: 50px;
    background: #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -2px;
    height: 196px;
    margin-bottom: -1em;
  }
}

/* The circles on the timeline */
.styles_historyConLeft__1sb1M::after {
   content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: 46.5% !important;
    background-color: white;
    border: 4px solid #D8232A;
    top: -85px;
    border-radius: 50%;
    z-index: 1;
}

@media (min-width: 43em) {
  .styles_historyConLeft__1sb1M::after {
    left: 48.5% !important;
  }

}

@media (min-width: 62em) {
    .styles_historyConLeft__1sb1M::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      left: 49.0% !important;
      background-color: white;
      border: 4px solid #D8232A;
      top: -85px;
      border-radius: 50%;
      z-index: 1;
  }
}


/* The circles on the timeline */
.styles_historyConRight__2ifM3::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 25px;
    height: 25px;
    left: 47% !important;
    background-color: white;
    border: 4px solid #D8232A;
    top: -85px;
    border-radius: 50%;
    z-index: 1;
}
@media (min-width: 43em) {
    .styles_historyConRight__2ifM3::after {
    left: 48.5% !important;
  }
}

@media (min-width: 62em) {
    .styles_historyConRight__2ifM3::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 49% !important;
      background-color: white;
      border: 4px solid #D8232A;
      top: -85px;
      border-radius: 50%;
      z-index: 1;
  }
}

.styles_historyConRightLast__1Pv6z::after{
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: 46.5% !important;
    background-color: white;
    border: 4px solid #D8232A;
    top: -85px;
    border-radius: 50%;
    z-index: 1;
}
@media (min-width: 43em) {
    .styles_historyConRightLast__1Pv6z::after{
    left:48.5% !important;
  }
}
@media (min-width: 62em) {
      .styles_historyConRightLast__1Pv6z::after{
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      left: 49% !important;
      background-color: white;
      border: 4px solid #D8232A;
      top: -85px;
      border-radius: 50%;
      z-index: 1;
  }
}

.styles_years1968__1D1Q-{
  position: absolute;
  left: 13.5em;
  /* top: 51em; */
  top: 17.3%;
  Z-INDEX: 1;
  background-color: #FFFFFF;
  color: #D8232A !important;
  line-height: -12em;
  font-weight: 600;
}




.styles_yearsConLeft__viuSd{
   position: absolute;
    Z-INDEX: 1;
    left: 42.5%;
    margin-top: -7.6em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .styles_yearsConLeft__viuSd{
    left:46.5%;
  }
}
.styles_yearsConRight__DMMbV{
   position: absolute;
    Z-INDEX: 1;
    left:49.5%;
    margin-top: -7.6em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .styles_yearsConRight__DMMbV{
    left:49.5%;
  }
}



.styles_yearsConRightBeginning__3oCua{
   position: absolute;
    Z-INDEX: 1;
    left:45.5%;
    margin-top: 2.4em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .styles_yearsConRightBeginning__3oCua{
    left:48%;
  }
}


.styles_yearsConLeftEnd__3uPnb{
   position: absolute;
    Z-INDEX: 1;
    left: 45.5%;
    margin-top: -7.6em;
    background-color: #FFFFFF;
}
@media (min-width: 43em) {
    .styles_yearsConLeftEnd__3uPnb{
    left:48%;
  }
}

.styles_years2010__1Hk_F{
    position: absolute;
    left: 13.8em;
    /* top: 51em; */
    top: 81.7%;
    Z-INDEX: 1;
    background-color: #FFFFFF;
    color: #D8232A !important;
    line-height: -12em;
    font-weight: 600;
}

.styles_years2011__2sUmL{
    position: absolute;
    left: 11.5em;
    /* top: 51em; */
    top: 31.8%;
    Z-INDEX: 1;
    background-color: #FFFFFF;
    color: #D8232A !important;
    line-height: -12em;
    font-weight: 600;
}
.styles_yearsRight__M9oyr{
  position: absolute;
  Z-INDEX: 1;
  left: 61.5%;
  margin-top: -2em;
}

.styles_yearsLeft__Y4dZP{
    position: absolute;
    Z-INDEX: 1;
    left: 0.5%;
    margin-top: -2em;
}
.styles_yearsLeftLast__whnXA{
    position: absolute;
    Z-INDEX: 1;
    left: 0.5%;
    margin-top: -2em;
}

@media (min-width: 43em) {
    .styles_yearsLeftLast__whnXA{
      position: absolute;
      Z-INDEX: 1;
      left: 0.5%;
      margin-top: 6em;
  }
}
@media (min-width: 62em) {
    .styles_yearsLeftLast__whnXA{
      position: absolute;
      Z-INDEX: 1;
      left: 0.5%;
      margin-top: -2em;
  }
}

@media (min-width: 43em) {

  .styles_yearsLeft__Y4dZP{
   position: absolute;
    Z-INDEX: 1;
    left: 2.5%;
    margin-top: -6em;
  }
   .styles_yearsRight__M9oyr{
    position: absolute;
    Z-INDEX: 1;
    left: 50.5%;
    margin-top: -7em;
   }

    .styles_historyYears__1JCs-{
    align-self: center;
    color: #000000;
    font: Poppins,bold;
    font-size: 15.0rem !important;
    font-weight: 800 !important;
    margin-bottom: 0em !important;
  }

     
}
@media (min-width: 62em) {
   .styles_yearsLeftLast__whnXA{
   position: absolute;
    Z-INDEX: 1;
    left: 8.5%;
    margin-top: 15em;
  }
   .styles_yearsRight__M9oyr{
    position: absolute;
    Z-INDEX: 1;
    left: 55.5%;
    margin-top: -7em;
   }
}
.styles_line__3lNBD{
  width: 15%;
  height: 1px;
  left: 42%;
  position: absolute;
  margin-top:4.3em;
  background: rgb(6, 6, 6);
}

.styles_lineEnd__2zZde{
  width: 15%;
  height: 1px;
  left: 42%;
  position: absolute;
  margin-top:-7.6em;
  background: rgb(6, 6, 6);
}


.styles_yearText__1OxkQ{
  align-self: center;
  color:#D8232A !important;
  font: Poppins,bold;
  font-size: 1.4rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

.styles_vLine__2EZCb{
    border-left: 1px solid #000;
    height: 100px;
    position: absolute;
    left: 50%;
    margin-left: -3px;
    top: 96.5%;
}

@media (min-width: 62em) {
  .styles_vLine__2EZCb {
    display: none;
  }
}
@media (min-width: 43em) {
  .styles_vLine__2EZCb {
    display: none;
  }
}

@media (min-width: 62em) {
  .styles_yearsConRight__DMMbV {
    display: none;
  }
  .styles_yearsConLeft__viuSd {
    display: none;
  }
  .styles_yearsConRightBeginning__3oCua {
    display: none;
  }
   .styles_yearsConLeftEnd__3uPnb {
    display: none;
  }
}
@media (min-width: 43em) {
  .styles_yearsConRight__DMMbV {
    display: none;
  }
  .styles_yearsConLeft__viuSd {
    display: none;
  }
  .styles_yearsConRightBeginning__3oCua {
    display: none;
  }
   .styles_yearsConLeftEnd__3uPnb {
    display: none;
  }
}
/* 

*/
.styles_tabCon__5Tnhr {
  padding: 1em 1em;
  background-color: #F7F7F7;
}

.styles_detailsHeaderText__3SBcp {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
  margin-bottom: 0em !important;
}

.styles_registerHeader__3vu0m {
  align-self: center;
  color: #343433 !important;
  font: Poppins,bold;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .styles_registerHeader__3vu0m {
    font-size: 2.8rem !important;
  }
}

@media (min-width: 62em) {
  .styles_tabCon__5Tnhr {
    padding: 1em 7em 4em 7em;
    background-color: #F7F7F7;
  }
}

.styles_detailsHeaderText__3SBcp {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.styles_contactDetailsCon__FVetk {
  display: flex;
  align-items: center;
  background-color: var(--color-primary);
} 

@media (min-width: 43em) {
  .styles_contactDetailsCon__FVetk {
    width: 40em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.styles_contactDetailsCon__FVetk hr {
  width: 0.15em;
  height: 2em;
  background-color: var(--color-text-in-secondary);
  border: none;
}

button.styles_phoneBtn__3G0H_ {
  flex: 1 1;
  flex-direction: row-reverse !important;
  width: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.styles_phoneBtnIcon__2c9QR {
  margin-right: 0.5em;
  font-size: 1.6rem !important;
}


/* 
  DETAILS
*/

.styles_detailsCon__2vS4o {
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .styles_detailsCon__2vS4o {
    width: 90%;
  }
}

.styles_detailsConHeader__9ypYe {
  display: flex;
  align-items: center;
}

.styles_detailsText__1GXOf {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 0 !important;
}



@media (min-width: 62em) {
  .styles_registerFormCon__1zZaS{
    margin-bottom: 0em;
  }
}

.styles_registerFormCon__1zZaS{
  margin-top: 1em;
  width: 100%;
}

@media (min-width: 62em) {
  .styles_registerFormCon__1zZaS {
    margin-bottom: 0em;
    margin-right: 1em;
  }

  .styles_registerFormConInput__2XTst {
    display: inline-block;
    width: calc(50% - 1em);
  }

  .styles_registerFormConInput__2XTst:nth-child(2n) {
    margin-right: 0em;
  }

  .styles_registerFormConTextArea__3H17m{
    width: 100%;
  }
}


.styles_registerLabel__eG6ea {
  font-size: 1.125rem !important;
}
.styles_registerredSmall__1MO5_{
  color:#D8232A !important;
  font-size: 1.2rem !important;
}

.styles_registerLabelred__flgrj{
  color:#D8232A !important;
  font-size: 1.4rem !important;
}

.styles_registerLabelredBold__3C0kL{
  color:#D8232A !important;
  font-size: 1.4rem !important;
  font:Poppins,medium
}
.styles_registerLabelblack__xDU1l{
  color:#343433;
  font-size: 1.4rem !important;
}


.styles_registerFormConCommsCon__2c5tB {
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.styles_registerFormConComms__1xoOc {
  font-size: 1.4rem !important;
  font-weight: 500;
}

/* Date picker */

.styles_datePicker__J_B5- {
  background: none;
  border: 1px solid #9a9a9a;
  color: var(--color-text-in-primary) !important;
  border-radius: 0px !important;
  height: 4em;
  text-align: left !important;
  width: 100% !important;
  font-size: 1.2rem !important;
}

.styles_searchIcon__1lYi3 {
  font-size: 16px;
}

.styles_addressContainer__Q_Rp- {
  display: flex;
  align-items: center;
}

/* 
  DETAILS
*/

.styles_detailsCon__19e0S {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .styles_detailsCon__19e0S {
    width: 70%;
  }
}

.styles_cardSelectorCon__2Y3vs {
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
}

.styles_cardSelector__JXbtW {
  padding: 0em 0em 1em 0em !important;
  font-size: 1.4rem !important;
  width: 100%;
}

.styles_cardIcon__1cN8Y {
  font-size: 1.4rem;
}

.styles_cardDigits__1dKE5 {
  font-size: 1.4rem !important;
}

.styles_cardDigits__1dKE5::before {
  content: '**** **** ****';
  margin-left: 1em
}

.styles_cardCon__2PDgE {
  margin-top: 2em;
  margin-bottom: 1em;
}
@media (min-width: 62em) {
  .styles_cardCon__2PDgE {
    width: 70%;
  }
}

.styles_detailsText__3N3Xw {
  font-size: 1.6rem !important;
  font-weight: 600;
  margin-bottom: 1em !important;
}



.styles_checkoutCon__cCHHc {
  margin-bottom: 1em;
}

.styles_checkoutItemsCon__Vq75P {
  display: none;
}

@media (min-width: 62em) {
  .styles_checkoutItemsCon__Vq75P {
    display: block;
  }
}

.styles_checkoutTermsRadio__vB3Ay {
  margin-bottom: 2.0em !important;
  width: 100%;
  font-size: 1.4rem !important;
}

.styles_checkoutTermsBottomRadio__1a9M4 {
  margin-top: 2.0em !important;
  margin-bottom: 2.0em !important;
  width: 100%;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .styles_checkoutTermsBottomRadio__1a9M4 {
    width: 80%;
    font-size: 1.4rem !important;
  }
}

@media (min-width: 62em) {
  .styles_checkoutTermsRadio__vB3Ay {
    width: 80%;
    font-size: 1.4rem !important;
  }
}

.styles_checkoutNewsletterHeader__1gaQ1 {
  display: block !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-top: 1em;
  margin-bottom: 1em;
}

.styles_checkoutNewsletterText__37lVs {
  display: block !important;
  font-size: 1.6rem !important;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .styles_checkoutNewsletterHeader__1gaQ1 {
    font-size: 2.0rem !important;
  }
}

.styles_checkoutNewsletterRadio__2DDCS {
  width: 100%;
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .styles_checkoutNewsletterRadio__2DDCS {
    width: 80%;
  }
}

.styles_registerLabelredBold__PzyM0 {
  color: #d8232a !important;
  font-size: 1.4rem !important;
  font: Poppins, medium;
}


.styles_loginCon__3rztJ {
  height: auto;
  padding: 2em 1em;
  background-color: var(--color-background-secondary);
}

@media (min-width: 62em) {
  .styles_loginCon__3rztJ {
    flex-direction: row-reverse;
    min-height: calc(100vh - 6em);
  }
}

.styles_loginFormCon__14GDo {
  display: block;
  width: 100%;
  padding: 0em;
}

@media (min-width: 62em) {
  .styles_loginFormCon__14GDo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;
  }
}

.styles_loginFormItemCon__HAsiV {
  margin-top: 1em;
}

@media (min-width: 62em) {
  .styles_loginFormItemCon__HAsiV {
    width: 70%;
  }
}

.styles_loginFormTitle__1iiY- {
  font-size: 2.6rem !important;
  font-weight: 600 !important;
  margin-top: 1em !important;
  margin-bottom: 2em !important;
}

@media (min-width: 62em) {
  .styles_loginFormTitle__1iiY- {
    font-size: 5.4rem !important;
    margin-top: 0em !important;
    margin-bottom: 1em !important;
  }
}

.styles_loginFormItemLabel__2dFGP {
  font-size: 1.4rem !important;
}

@media (min-width: 62em) {
  .styles_loginFormItemLabel__2dFGP {
    font-size: 1.6rem !important;
  }
}

.styles_loginFormRememberRadio__JTqfZ {
  font-size: 1.1rem !important;
}

@media (min-width: 62em) {
  .styles_loginFormRememberRadio__JTqfZ {
    font-size: 1.4rem !important;
  }
}

.styles_loginFormOptsCon__2ymyz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

@media (min-width: 62em) {
  .styles_loginFormOptsCon__2ymyz {
    width: 70%;
  }
}

button.styles_loginFormResetBtn__1LBgF,
button.styles_loginFormSignUpBtn__2ro-W {
  height: auto !important;
  padding: 0em !important;
}

button.styles_loginFormResetBtn__1LBgF *,
button.styles_loginFormSignUpBtn__2ro-W * {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
  color: var(--color-primary);
}

button.styles_loginFormSubmitBtn__FAj59 {
  margin-top: 2em;
  margin-bottom: 1em;
}

.styles_loginFormSignUpText___gJS2 {
  font-size: 1.4rem !important;
}

/* 
.loginFormSignUpBtn {
} 
*/

.styles_loginFormBanner__34HzR {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
  margin-top: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 70%;
  height: 8.5em;
}

@media (min-width: 62em) {
  .styles_loginFormBanner__34HzR {
    flex: 1 1;
    align-items: flex-end;
    margin-top: 0em;
    height: inherit;
    padding: 0em 2em 4em 0em;
    justify-content: flex-end;
  }
}

.styles_loginFormBannerText__281XK {
  font-size: 2.8rem !important;
  font-weight: 800;
  color: var(--color-text-in-secondary) !important;
}

@media (min-width: 62em) {
  .styles_loginFormBannerText__281XK {
    font-size: 8.6rem !important;
    line-height: 1em !important;
  }
}


/* 
  FILTER
*/

.styles_filtersCon__1HFtS {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2em 1em;
}

@media (min-width: 62em) {
  .styles_filtersCon__1HFtS {
    padding: 2em 7em;
  }
}

.styles_filtersHeader__270yv {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 0em !important;
}

@media (min-width: 62em) {
  .styles_filtersHeader__270yv {
    font-size: 2.8rem !important;
  }
}

/* 
  SPECIALS LIST
*/

.styles_specialsListCon__1E_vW {
  display: block;
  padding: 1em;
}

@media (min-width: 43em) {
  .styles_specialsListCon__1E_vW {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (min-width: 62em) {
  .styles_specialsListCon__1E_vW {
    padding: 1em 7em;
  }
}


/* 
  FILTER
*/

.styles_filtersCon__2fU-x {
  width: 100%;
  padding: 2em 1em;
}

@media (min-width: 62em) {
  .styles_filtersCon__2fU-x {
    padding: 2em 7em;
  }
}

.styles_filtersHeader__2eTGr {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
}

@media (min-width: 62em) {
  .styles_filtersHeader__2eTGr {
    font-size: 2.8rem !important;
  }
}

.styles_detailsHeaderText__sIEXo {
  font-size: 1.6rem !important;
  font-weight: 600;
  padding: 0.5em 0em;
}

.styles_documentLink__32Flj {
  margin-top: 1em !important;
}

.styles_pdfIcon__3KNDu {
  font-size: 4.2rem !important;
}

/* 
  FILTER
*/

.styles_con__11b_W {
  width: 100%;
  padding: 4em 1em;
}

@media (min-width: 62em) {
  .styles_con__11b_W {
    padding: 4em 7em;
  }
}

.styles_conHeaderTitle__3L71w {
  align-self: center;
  color: #535353 !important;
  font-size: 2.0rem !important;
  font-weight: 600 !important;
  margin-bottom: 1em !important;
}

@media (min-width: 62em) {
  .styles_conHeaderTitle__3L71w {
    font-size: 2.8rem !important;
  }
}


/* 
  CERTIFICATES 
*/

.styles_certContainer__ZPoOS {
  display: flex;
  /* background-color: var(--color-background-secondary); */
  justify-content: center;
  width: 100%;
}

@media (min-width: 82em) {
  .styles_certContainer__ZPoOS {
    height: 40em;
    justify-content: flex-end;
  }
}

.styles_certContent__3Wi-_ {
  padding: 2em 0em;
  width: 100%;
  /* background-color: var(--color-background-secondary); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 62em) {
  .styles_certContent__3Wi-_ {
    padding: 2em 2em;
    width: 60%
  }
}

@media (min-width: 82em) {
  .styles_certContent__3Wi-_ {
    width: 57%;
  }
}

.styles_certImg__3qc0D {
  display: none;
  height: 100%;
  width: 43%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 0%;
}

@media (min-width: 82em) {
  .styles_certImg__3qc0D {
    display: inline-block;
  }
}

.styles_certDocumentLink__Il0vC {
  margin-bottom: 1em !important;
}

@media (min-width: 82em) {
  .styles_certDocumentLink__Il0vC {
    margin-bottom: 0em !important;
  }  
}

.styles_pdfIcon__3lqLx {
  font-size: 4.2rem !important;
}

/* 
  TRENDING PRODUCTS
*/

.styles_trendingProductsCon__21Rzo {
  margin-top: 1em;
  padding: 1em;
}

@media (min-width: 43em) {
  .styles_trendingProductsCon__21Rzo {
    padding: 0em;
    padding-right: 1em;
  }
}

@media (min-width: 62em) {
  .styles_trendingProductsCon__21Rzo {
    display: flex;
    padding-left: 7em;
    padding-right: 7em;
    justify-content: space-between;
  }
}

.styles_productCard__JIMow {
  margin-bottom: 1em;
}

@media (min-width: 43em) {
  .styles_productCard__JIMow {
    width: calc(50% - 1em) !important;
    margin-left: 1em;
    display: inline-block;
  }
}

@media (min-width: 62em) {
  .styles_productCard__JIMow {
    width: calc(25% - 0.5em) !important;
    margin-left: 0.5em;
  }
}
/* 
  Trending Header
*/
.styles_trendHeader__3NKvK {
  font-size: 1.8rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  margin-bottom: 2em !important;
  text-align: center;
  margin-top: 1em;
}

@media (min-width: 82em) {
  .styles_trendHeader__3NKvK{
    font-size: 2.5rem !important;
    text-align: left;
    margin-top: 2em !important;
  }
}

.styles_trendingShowcaseCon__rHdt_ {
  padding: 6em 1em;
}

@media (min-width: 62em) {
  .styles_trendingShowcaseCon__rHdt_ {
    padding: 6em 7em;
  }
}

.styles_trendCon__GxLDB {
  margin-top: 1em;
}

/*
 * Specials
 */

.styles_specialsCon__3mNU6 {
  width: 100%;
  padding: 4em 1em;
}

@media (min-width: 62em) {
  .styles_specialsCon__3mNU6 {
    padding: 4em 7em;
  }
}

/*
 * Map
 */

.styles_mapCon__1bKuo {
  width: 100%;
  margin-bottom: 2em;
  border: 0.2em solid var(--color-separator-light);;
}

.styles_specialsImg__8rQmx {
  width: 100%;
  margin-bottom: 20px;
}

.styles_iframeSpecial__3JTzU {
  width: 100%;
  height: 500px;
  border: none;
  margin-bottom: 20px;
}

:root {
  --color-background: #f5f5f5;
  --color-primary: #D8232A;
  --color-primary-hover: #fb040c;
  --color-secondary: #f0f0f0;
  --color-text: #333;
  --color-text-light: #666;
  --color-border: #e0e0e0;
}
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  background-color: var(--color-background);
  color: #333;
  color: var(--color-text);
  line-height: 1.6;
}
#reviews-root {
  width: 100% !important;
  min-height: 90vh !important;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.productOfferCon {
  padding: 6em 1em !important;
}
@media (min-width: 62em) {
  .productOfferCon {
    padding: 6em 7em !important;
  }
}
.header-section {
  margin-bottom: 3rem;
}
.main-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #D8232A;
  color: var(--color-primary);
  margin: 0;
  line-height: 1.2;
}
.subtitle {
  font-size: 1rem;
  color: #333;
  color: var(--color-text);
  margin: 0.5rem 0 2rem 0;
}
.active-positions {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.position-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  color: var(--color-text);
  line-height: 1;
}
.position-label {
  font-size: 0.75rem;
  color: #666;
  color: var(--color-text-light);
  letter-spacing: 0.05em;
}
.search-section {
  margin-top: 2rem;
}
.search-container {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  max-width: 100%;
  margin-bottom: 2rem;
  background: transparent;
}
.search-input {
  flex: 2 1;
  background: transparent !important;
}
.search-input.ant-input-affix-wrapper {
  height: 48px !important;
  border-radius: 8px !important;
  padding: 0 11px;
  background: white;
  border: 1px solid #e0e0e0;
  border: 1px solid var(--color-border);
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.search-input.ant-input-affix-wrapper:hover,
.search-input.ant-input-affix-wrapper:focus {
  border-color: #D8232A;
  border-color: var(--color-primary);
}
.search-input.ant-input-affix-wrapper .ant-input {
  height: 100%;
  background: transparent;
  font-size: 1rem;
  padding: 0;
}
.search-input.ant-input-affix-wrapper .ant-input::-webkit-input-placeholder {
  font-size: 1rem;
  color: #666;
}
.search-input.ant-input-affix-wrapper .ant-input:-ms-input-placeholder {
  font-size: 1rem;
  color: #666;
}
.search-input.ant-input-affix-wrapper .ant-input::placeholder {
  font-size: 1rem;
  color: #666;
}
.search-input.ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 10px;
  color: #666;
}
.province-select {
  flex: 1 1;
}
.province-select .ant-select-selector {
  height: 48px !important;
  border-radius: 8px !important;
  padding: 0 11px !important;
  background: white !important;
  border: 1px solid #e0e0e0;
  border: 1px solid var(--color-border);
  display: flex;
  align-items: center;
  font-size: 1rem;
}
.province-select .ant-select-selector:hover {
  border-color: #D8232A;
  border-color: var(--color-primary);
}
.province-select .ant-select-selection-placeholder,
.province-select .ant-select-selection-item {
  line-height: 48px;
  font-size: 1rem;
  color: #666;
}
.job-list {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.job-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px;
  transition: all 0.2s ease-in-out;
}
.job-card:hover {
  border-color: #D8232A;
  border-color: var(--color-primary);
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.job-content {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
}
.job-title {
  font-size: 20px;
  font-weight: 600;
  color: #D8232A;
  color: var(--color-primary);
  text-decoration: underline;
  cursor: pointer;
}
.job-title:hover {
  color: #fb040c;
  color: var(--color-primary-hover);
}
.job-summary {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
  color: var(--color-text-light);
  margin: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}
.more-link {
  color: #D8232A;
  color: var(--color-primary);
  text-decoration: none;
  margin-left: 5px;
  font-weight: 600;
  cursor: pointer;
}
.more-link:hover {
  text-decoration: underline;
}
.job-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  border-top: 1px solid #e0e0e0;
  border-top: 1px solid var(--color-border);
  margin-top: auto;
}
.job-location {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  color: #666;
  color: var(--color-text-light);
  font-size: 14px;
}
.job-location .anticon {
  color: #D8232A;
  color: var(--color-primary);
}
.job-date {
  color: #666;
  color: var(--color-text-light);
  font-size: 14px;
}
.empty-review {
  margin: 50px;
  text-align: center;
  color: #666;
  color: var(--color-text-light);
  font-size: 18px;
}
/* Responsive styles */
@media (max-width: 961px) {
  #reviews-root {
    padding-top: 50px;
  }
  .header-section {
    padding-left: 20px;
    padding-right: 20px;
  }
  .search-container {
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 20px;
  }
  .search-input,
  .province-select {
    width: 100%;
    flex: none;
  }
  .search-input.ant-input-affix-wrapper {
    height: 48px !important;
    border-radius: 8px !important;
    display: flex;
    align-items: center;
  }
  .province-select .ant-select-selector {
    height: 48px !important;
    border-radius: 8px !important;
  }
  .empty-review {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}
/* Spinner styles */
.ant-spin {
  color: #D8232A;
  color: var(--color-primary);
}
.ant-spin-text {
  color: #333;
  color: var(--color-text);
}
/* Additional utility classes */
.table-row-light {
  background-color: #f0f0f0;
  background-color: var(--color-secondary);
}
.table-row-dark {
  background-color: transparent;
}

.styles_spinnerCon__11R_u {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 65vh;
    width: 100%;
}


.styles_ordersCard__1rgru{
  display: inline-block;
  width: calc(100%) !important;
  background-color: #FFFFFE;
  margin-bottom: 2em;
  border-radius: 10px;
}
.styles_privacyDiv__31l7y {
    text-align: center;
    max-width: 1100px;
    margin: 0 auto; 
    line-height: 2;
}
 
  .styles_productResultCardCon__w7gl6 {
    text-align: left;
  }
  
  .styles_productResultCard__2jARk {
    display: inline-block;
    width: calc(50% - 0.5em) !important;
    margin-bottom: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productResultCard__2jARk {
      width: calc(33.33% - 1em) !important;
    }
  }
  
  .styles_productResultCard__2jARk:nth-child(2n) {
    margin-left: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productResultCard__2jARk:nth-child(2n) {
      margin-left: 0em;
    }
  
    .styles_productResultCard__2jARk {
      margin-right: 1em;
    }
  }
  
  /* .productResultCard:nth-child(3n) {
    margin-left: 1em;
  } */
  
  .styles_productResultPName__61BKy {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
  }
  
  @media (min-width: 43em) {
    .styles_productResultPName__61BKy {
      font-size: 1.6rem !important;
    }
  }
  
  .styles_productResultImg__voSsG {
    height: 12em !important;
  }
  
  @media (min-width: 62em) {
    .styles_productResultImg__voSsG {
      height: 19em !important;
    }
  }
  
  .styles_emptyList__p2c1d {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8em 0em;
  }
  .styles_productShowcaseCon__PUocM {
    padding: 6em 1em;
  }
  
  @media (min-width: 62em) {
    .styles_productShowcaseCon__PUocM {
      padding: 2em 7em;
    }
  }
  .styles_productHeader__KL8pZ {
    font-size: 1.8rem !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    margin-bottom: 2em !important;
    text-align: center;
    margin-top: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productHeader__KL8pZ{
      font-size: 2.5rem !important;
      text-align: left;
      margin-top: 2em !important;
    }
  }

.styles_searchSpinCon__2Zv3d {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

  
 
  .styles_con__1jXbi{
    width: 80% !important;
    margin: 0 auto;
    padding: 4em 1em;
    justify-content: center;
  }
  
  
  .styles_productResultCardCon__7wQgW {
    text-align: left;
  }
  
  .styles_productResultCard__3ecXi {
    display: inline-block;
    width: calc(50% - 0.5em) !important;
    margin-bottom: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productResultCard__3ecXi {
      width: calc(33.33% - 1em) !important;
    }
  }
  
  .styles_productResultCard__3ecXi:nth-child(2n) {
    margin-left: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productResultCard__3ecXi:nth-child(2n) {
      margin-left: 0em;
    }
  
    .styles_productResultCard__3ecXi {
      margin-right: 1em;
    }
  }
  
  /* .productResultCard:nth-child(3n) {
    margin-left: 1em;
  } */
  
  .styles_productResultPName__2ykC5 {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
  }
  
  @media (min-width: 43em) {
    .styles_productResultPName__2ykC5 {
      font-size: 1.6rem !important;
    }
  }
  
  .styles_productResultImg__2J0z6 {
    height: 12em !important;
  }
  
  @media (min-width: 62em) {
    .styles_productResultImg__2J0z6 {
      height: 19em !important;
    }
  }
  
  .styles_emptyList__2kDgB {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8em 0em;
  }
  .styles_productShowcaseCon__2oc3G {
    padding: 6em 1em;
  }
  
  @media (min-width: 62em) {
    .styles_productShowcaseCon__2oc3G {
      padding: 2em 7em;
    }
  }
  .styles_productHeader__BA7BT {
    font-size: 1.8rem !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    margin-bottom: 0.5em !important;
    text-align: center;
    margin-top: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productHeader__BA7BT{
      font-size: 2.5rem !important;
      text-align: center;
      margin-top: 2em !important;
    }
  }

.styles_searchSpinCon__31zg6 {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

  
 
  .styles_productResultCardCon__a4gca {
    text-align: left;
  }
  
  .styles_productResultCard__1PV1F {
    display: inline-block;
    width: calc(50% - 0.5em) !important;
    margin-bottom: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productResultCard__1PV1F {
      width: calc(33.33% - 1em) !important;
    }
  }
  
  .styles_productResultCard__1PV1F:nth-child(2n) {
    margin-left: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productResultCard__1PV1F:nth-child(2n) {
      margin-left: 0em;
    }
  
    .styles_productResultCard__1PV1F {
      margin-right: 1em;
    }
  }
  
  /* .productResultCard:nth-child(3n) {
    margin-left: 1em;
  } */
  
  .styles_productResultPName__BgYcm {
    font-size: 1.3rem !important;
    font-weight: 600 !important;
  }
  
  @media (min-width: 43em) {
    .styles_productResultPName__BgYcm {
      font-size: 1.6rem !important;
    }
  }
  
  .styles_productResultImg__kxg3b {
    height: 12em !important;
  }
  
  @media (min-width: 62em) {
    .styles_productResultImg__kxg3b {
      height: 19em !important;
    }
  }
  
  .styles_emptyList__35vU0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8em 0em;
  }
  .styles_productShowcaseCon__QOZFk {
    padding: 6em 1em;
  }
  
  @media (min-width: 62em) {
    .styles_productShowcaseCon__QOZFk {
      padding: 2em 7em;
    }
  }
  .styles_productHeader__lyRag {
    font-size: 1.8rem !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    margin-bottom: 2em !important;
    text-align: center;
    margin-top: 1em;
  }
  
  @media (min-width: 82em) {
    .styles_productHeader__lyRag{
      font-size: 2.5rem !important;
      text-align: left;
      margin-top: 2em !important;
    }
  }

.styles_searchSpinCon__20i1n {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

  
/*
 * Specials
 */

 .styles_specialsCon__2qbeS {
    width: 100%;
    padding: 4em 1em;
  }
  
  @media (min-width: 62em) {
    .styles_specialsCon__2qbeS {
      padding: 4em 7em;
    }
  }
  
  /*
   * Map
   */
  
  .styles_mapCon___pXmd {
    width: 100%;
    margin-bottom: 2em;
    border: 0.2em solid var(--color-separator-light);
  }
  
  
.styles_customPageContainer__28Zo7 {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .styles_spinContainer__2QSho {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .styles_errorContainer__JqouY {
    text-align: center;
    padding: 2rem;
  }
  .styles_contentContainer__2RDuM {
    line-height: 1.6;
    font-size: 16px;
  }

  .styles_pageTitle__2aJbb {
    text-transform: capitalize;
  }
  
  /* Styles for the HTML content */
  .styles_customPageContainer__28Zo7 h1,
  .styles_customPageContainer__28Zo7 h2,
  .styles_customPageContainer__28Zo7 h3,
  .styles_customPageContainer__28Zo7 h4,
  .styles_customPageContainer__28Zo7 h5,
  .styles_customPageContainer__28Zo7 h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .styles_customPageContainer__28Zo7 p {
    margin-bottom: 1em;
  }
  
  .styles_customPageContainer__28Zo7 ul,
  .styles_customPageContainer__28Zo7 ol {
    margin-bottom: 1em;
    padding-left: 2em;
  }
  
  .styles_customPageContainer__28Zo7 img {
    max-width: 100%;
    height: auto;
    margin: 1em 0;
  }
  
  .styles_customPageContainer__28Zo7 blockquote {
    border-left: 4px solid #ccc;
    margin: 1em 0;
    padding-left: 1em;
    font-style: italic;
  }
  
  .styles_customPageContainer__28Zo7 pre,
  .styles_customPageContainer__28Zo7 code {
    background-color: #f4f4f4;
    border-radius: 3px;
    padding: 0.2em 0.4em;
    font-family: monospace;
  }
  
  .styles_customPageContainer__28Zo7 table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
  }
  
  .styles_customPageContainer__28Zo7 th,
  .styles_customPageContainer__28Zo7 td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .styles_customPageContainer__28Zo7 th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

.review-drawer-root {
  display: flex;
  flex-direction: column;
}
.selected-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: #1D3948 2px solid;
  color: #fff !important;
  background: #1E7D79 !important;
}
.selected-rev-card-name {
  color: #f75d03 !important;
}
.selected-rev-card-role {
  color: #fff !important;
}
.review-card {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  background: #F8FAFB;
  padding: 15px;
  margin: 0 10px 0 0px;
  cursor: pointer;
}
.rev-card-top {
  display: flex;
  justify-content: flex-end;
}
.rev-card-bottom {
  display: flex;
  justify-content: flex-end;
}
.rev-card-row {
  display: flex;
  margin: auto;
  justify-content: flex-start;
  width: 90%;
  height: 191px;
  position: fixed;
  top: 0;
  z-index: 10;
}
.rev-card-tag {
  background-color: var(--color-primary);
  padding: 5px;
  color: #fff;
  font-size: 1rem;
  margin: 6px 0 0 0;
  min-width: 70px;
  text-align: center;
}
.rev-card-role {
  font-size: 1.1rem;
  color: var(--color-primary);
}
.rev-card-name {
  color: var(--color-primary);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rev-text-col {
  width: 70%;
}
.header {
  font-size: 1.6rem;
  color: #B14000 !important;
  font-weight: bold;
}
.header-res {
  font-size: 1.6rem;
  color: #B14000 !important;
  font-weight: bold;
  margin-top: 14px;
}
.rev-card-profile {
  display: flex;
  align-items: center;
}
.rev-card-avatar {
  width: 50px !important;
  height: 50px !important;
  margin: 0 12px 0 0 !important;
}
.rev-accordion {
  width: 85%;
  margin: 50px auto !important;
}
.next-survey-btn {
  background-color: var(--color-primary) !important;
  margin: 17px auto !important;
  color: #fff !important;
  width: 120px !important;
  border: none !important;
}
.rev-accordion .ant-collapse-header {
  background-color: #7C8DA9 !important;
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: bold;
}
.survey-response-area {
  margin: 60px auto;
  width: 84% !important;
}
.completed-check {
  font-size: 2rem !important;
  color: #304D12 !important;
}
.completed-text {
  color: #304D12 !important;
}
.non-complete {
  font-size: 1.8rem !important;
  font-weight: 700 !important;
  color: #1D3948 !important;
  font-weight: bolder;
}
.completed-rev {
  opacity: 0.7;
  cursor: not-allowed;
}
.survey-completed-view {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.no-del-view {
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 15%;
}
.completed-img {
  margin: 30px;
  width: 50%;
}
.no-del-img {
  margin: 30px;
  width: 20%;
}
.submit-rev-btn {
  background-color: #1E7D79 !important;
  color: #fff !important;
}
.thanks-text {
  font-weight: bold;
  font-size: 1.5rem;
  color: #1D3948;
}
.carousel {
  width: 85%;
  z-index: 2;
  position: fixed;
  left: 9%;
  top: 5%;
  top: 5;
  margin: auto;
  background-color: #fff;
}
.del-drawer {
  margin-top: 40px;
  margin-left: 0px !important;
}
.info {
  font-size: 1.6rem;
  margin-bottom: 20px;
  font-weight: bold;
}
.compare-table {
  margin-top: 24px !important;
}
@media (max-width: 992px) {
  .compare-table .carousel {
    width: 85%;
    margin: 80px auto 0px auto;
  }
}
.react-multi-carousel-item {
  margin-right: 10px;
}
@media (max-width: 992px) {
  .carousel {
    width: 85%;
    margin: 80px auto 0px auto;
  }
}
@media (max-width: 420px) {
  .review-card {
    width: 100%;
  }
}
@media (max-width: 961px) {
  .chart-report-tabs {
    margin-top: 80px !important;
  }
}
@media (max-width: 961px) {
  .rate-contain {
    flex-direction: column;
  }
}
.launch-date {
  font-weight: bold;
  color: #1D3948;
  font-size: 2rem;
}
.review-instructions {
  margin-top: 16px;
  margin-bottom: 16px;
}
.final-rating {
  margin-top: 10px;
}
.ant-tabs-tab .ant-tabs-tab-active {
  color: #000 !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000 !important;
  font-weight: bold !important;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  border-bottom: 2px solid #000;
}
.welcome-closing-messsage {
  background-color: #f8f9fa;
  padding: 15px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.opening-msg {
  font-weight: 700;
  font-size: 2.1rem;
  color: #1D3948;
}
.ant-btn > span {
  display: inline-block;
}
.survey-response-area {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.08);
}
.survey-response-area .question {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #e6e9ee;
  transition: all 0.2s ease-in-out;
}
.survey-response-area .question:hover {
  border-color: rgba(30, 125, 121, 0.3);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
}
.survey-response-area .question .question-top {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.survey-response-area .question .question-top .question-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1D3948;
  flex-grow: 1;
  position: relative;
  padding-left: 15px;
}
.survey-response-area .question .question-top .question-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 4px;
  height: 70%;
  background-color: var(--color-primary);
  border-radius: 2px;
}
.survey-response-area .question .question-element .ant-input,
.survey-response-area .question .question-element .ant-select,
.survey-response-area .question .question-element .ant-radio-group,
.survey-response-area .question .question-element .ant-upload {
  width: 100%;
  border-radius: 6px;
  transition: all 0.2s ease;
}
.survey-response-area .question .question-element .ant-input:focus,
.survey-response-area .question .question-element .ant-select-selector:focus,
.survey-response-area .question .question-element .ant-input:hover,
.survey-response-area .question .question-element .ant-select-selector:hover {
  border-color: var(--color-primary);
  box-shadow: 0 0 0 2px rgba(30, 125, 121, 0.1);
}
.survey-response-area .question .question-element .multi-choice {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.survey-response-area .question .question-element .multi-choice .ant-radio-wrapper {
  margin-right: 10px;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #e9ecef;
  transition: all 0.2s ease;
}
.survey-response-area .question .question-element .multi-choice .ant-radio-wrapper.ant-radio-wrapper-checked {
  border-color: var(--color-primary);
  background-color: rgba(30, 125, 121, 0.05);
}
.survey-response-area .welcome-closing-messsage {
  background-color: #f8f9fa;
  border-left: 5px solid var(--color-primary);
  padding: 15px 20px;
  margin: 25px 0;
  border-radius: 6px;
}
.survey-response-area .welcome-closing-messsage .opening-msg {
  color: #1D3948;
  font-weight: 500;
  line-height: 1.6;
}
.survey-response-area .next-survey-btn {
  display: block;
  width: 200px;
  margin: 30px auto;
  border-radius: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: var(--color-primary);
  color: white;
  border: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(30, 125, 121, 0.2);
}
.survey-response-area .next-survey-btn:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(30, 125, 121, 0.3);
}
.survey-response-area .next-survey-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  -webkit-transform: none;
          transform: none;
  box-shadow: none;
}
@media (max-width: 768px) {
  .survey-response-area {
    padding: 15px;
    margin: 20px 10px;
  }
  .survey-response-area .question {
    padding: 15px;
  }
  .survey-response-area .question .question-top .question-title {
    font-size: 1.1rem;
  }
}

.vacancy-info-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 768px) {
  .vacancy-info-container {
    padding: 1rem;
  }
}
.vacancy-info-container .vacancy-info-header {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
}
.vacancy-info-container .vacancy-title {
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
  color: #D8232A;
}
.vacancy-info-container .vacancy-meta-info {
  margin-bottom: 1.5rem;
}
.vacancy-info-container .meta-items {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
}
.vacancy-info-container .meta-item {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  color: #666;
  font-size: 1.4rem;
}
.vacancy-info-container .meta-item .anticon {
  color: #D8232A;
}
.vacancy-info-container .action-buttons {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}
@media (max-width: 768px) {
  .vacancy-info-container .action-buttons {
    flex-direction: column;
    align-items: stretch;
  }
}
.vacancy-info-container .apply-button {
  height: 48px;
  padding: 0 2rem;
  font-size: 1rem;
  font-weight: 600;
}
.vacancy-info-container .secondary-actions {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
@media (max-width: 768px) {
  .vacancy-info-container .secondary-actions {
    justify-content: center;
  }
}
.vacancy-info-container .vacancy-info-content {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  font-size: 1.5rem;
  line-height: 1.6;
  color: #333;
}
.vacancy-info-container .vacancy-info-content ul {
  list-style-type: disc;
  margin-left: 40px;
}
.vacancy-info-container .vacancy-info-content ol {
  list-style-type: decimal;
  margin-left: 40px;
}

:root {
  --color-background: #FFFFFE;
  --color-background-secondary: #F7F7F7;
  --color-background-footer: #000000;
  /* --color-primary-lighter: #d7c499; */
  /* --color-primary-light: #f9f8f2; */
  --color-primary: #d8232a;
  /* --color-primary-dark: #12284d83; */
  --color-secondary: #000000;
  /* --color-secondary-dark: #12284d; */
  --color-title-in-primary: #000;
  --color-title-nav-transparent: #FFFFFE;
  --color-text-in-primary: #000;
  --color-text-in-secondary: #fff;
  --color-separator-light: #C7C7C7;
  /* --color-text-title: #32264d; */
  /* --color-text-complement: #9c98a6; */
  /* --color-text-base: #6a6180; */
  /* --color-text-brown: #59394a; */
  /* --color-line-in-white: #e6e6f0; */
  /* --color-input-background: #f8f8fc; */
  /* --color-button-text: #ffffff; */
  /* --color-box-base: #0a0101; */
  /* --color-box-footer: #fafafc; */

  --size-navbar-padding: 6em;

  font-size: 62.5%; /* Set rem to 10px */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  background-color: #FFFFFE;
  background-color: var(--color-background);
}

body {
  color: #000;
  color: var(--color-text-in-primary);
}

/*
  NAVBAR
*/

/* TODO: sticky with color white */
.nav {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  background-color: #FFFFFE;
  background-color: var(--color-background);
  border-bottom:  0.1rem solid #C7C7C7;
  border-bottom:  0.1rem solid var(--color-separator-light);
  color: #000;
  color: var(--color-title-in-primary)
}

nav .navbar-icon {
  color: #000  !important;
  color: var(--color-title-in-primary)  !important
}
nav.transparent-navbar {
  background-color: rgba(0,0,0, 0.2) !important;
  border-bottom: none;
}

nav.transparent-navbar > * ,
nav.transparent-navbar .navbar-icon {
  color: #FFFFFE !important;
  color: var(--color-title-nav-transparent) !important;
}


@media (min-width: 62em) {
  nav.transparent-navbar {
     background-color: rgba(0,0,0, 0.2) !important;
  }
}

.navbar-padding {
  padding-top: 8rem;
}


/*
  TABS
*/

.ant-tabs-tab-active {
  border-bottom: 0.4em solid #d8232a !important;
  border-bottom: 0.4em solid var(--color-primary) !important;
}

.ant-tabs-nav {
  margin-bottom: 0 !important;
}

/*
  TABLE
*/

.ant-table-thead > tr > th  {
  background-color: #FFFFFE !important;
  background-color: var(--color-background) !important;
}

/*
  MODALS
*/

.ant-modal-footer {
  border: none !important;
}

.ant-modal-title {
  font-size: 2.5rem !important;
  font-weight: 600 !important;
}

.ant-modal-close-x {
  display: flex !important;
  justify-content: center;
  align-items: center;

  color: #000 !important;

  color: var(--color-title-in-primary) !important;
  font-size: 2.5rem !important;
}

.ant-modal-content {
  padding: 1em 1em;
}

nav.transparent-navbar{

}

/*
 * Google Recaptcha
 */

.grecaptcha-badge { visibility: hidden; }


